import styled from "styled-components"
import { motion } from "framer-motion"
import LeftImg from "./LeftSideImg.jpg"
import mLeftImg from "./leftM.jpg"

export const StyledLeftComponent = styled.div`
  padding: 4rem 15% 4rem 4rem;
  width: 42rem;
  display: table-cell;
  background-image: url(${LeftImg});
  background-size: auto 100%;
  justify-content: space-between;
  position: relative;
  background-position: center center;

  @media only screen and (max-width: 1300px) {
    background-size: auto 100%;
    background-position: center center;
  }

  @media only screen and (max-width: 1200px) {
    width: 35rem;
    padding: 3rem;
    background-size: auto 100%;
    background-position: center center;
    min-height: 50rem;
    display: block;
    background-image: url(${mLeftImg});
  }

  @media only screen and (max-width: 768px) {
    min-height: 100%;
    padding: 3rem 1.5rem;
    background-size: cover;
    width: 100%;
  }
`
export const Wrapper = styled.div`
  height: 85vh;
  display: table-cell;
  background: #fff;
  @media only screen and (max-width: 1200px) {
    // background: linear-gradient(225.16deg, #7232e1 0%, #60a3ee 100%);
    display: block;
    background-image: url(${mLeftImg});
    background-size: 100% 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const StyledRightComponent = styled.div`
  padding: 3rem;
  background-color: #fff;
  width: 35rem;
  display: block;
  margin: 0;

  @media only screen and (max-width: 1200px) {
    border-radius: 2rem 2rem 0 0;
    margin: 2rem 0 0 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 3rem 1.5rem;
    width: 100%;
  }
`
export const StyledBottom = styled.div`
  @media only screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1.25rem 0 0 0;
  }
`
export const StyledCard = styled.div`
  display: table;
  @media only screen and (max-width: 1500px) {
    scale: 0.9;
  }
  @media only screen and (max-width: 1200px) {
    scale: 1;
    display: block;
  }

  @media only screen and (max-width: 768px) {
    width: 95%;
  }

  @media only screen and (max-width: 540px) {
    width: 100%;
  }
`
export const Conatiner = styled.div`
  margin: 2.5rem 0 3rem 0;
`
export const Link = styled.div`
  text-decoration: underline #fff;
  cursor: pointer;
`
export const CustomText = styled.p`
  color: #656565;
  padding: 0 0 0 1rem;
  font-size: 16px;

  @media only screen and (max-width: 1200px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`
export const CustomFlex = styled.div`
  display: none;
  @media only screen and (max-width: 1200px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 12.5rem 0 0 0 !important;
  }
`
export const Logo = styled.div`
  width: 187.75px;
  height: 40px;
  @media only screen and (max-width: 1200px) {
    width: 235.24px;
    height: 51.71px;
    ${'' /* margin: 0 0 0 8%; */}
  }
  @media only screen and (max-width: 768px) {
    ${'' /* margin: 0 0 0 12%; */}
  }
`

export const LogoContainer = styled.div`
  display: block;
  @media only screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    ${'' /* margin: 3rem 0 1rem 0; */}
  }
`
export const MobileContent = styled.div`
  display: none;
  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2.25rem 1rem 0 1rem;
  }
`
export const SocialLink = styled(motion.a)`
  margin: 0.25rem 0 0 0;
`
export const StyledSocailContainer = styled.div`
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    margin: 0;
  }
`
export const Row = styled.div`
  margin: 2rem 0;
`

export const Margin = styled.div`
  height: 1rem;
`
export const Li = styled.li`
  font-size: 20px;
  color: rgb(0, 0, 0);
  text-align: left;
  letter-spacing: 0.16px;
  line-height: 27px;
  font-weight: 400;
  margin: 0 0 0.75rem 0;
  @media only screen and (max-width: 768px) {
    font-size: 17px;
  }
`
export const Th = styled.th`
  font-size: 20px;
  color: rgb(114, 50, 225);
  text-align: center;
  letter-spacing: 0.16px;
  line-height: 27px;
  font-weight: 600;
  margin: 0 0 0.75rem 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 3rem;
  ${'' /* padding: 0 0 0 1rem; */}
  text-align: center;

  @media only screen and (max-width: 1200px) {
    min-height: 3rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 17px;
  }
`
export const Td = styled.td`
  font-size: 18px;
  color: rgb(0, 0, 0);
  text-align: center;
  letter-spacing: 0.16px;
  line-height: 27px;
  font-weight: 400;
  margin: 0 0 0.75rem 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 3rem;
  ${'' /* padding: 0 0 0 1rem; */}

  @media only screen and (max-width: 1200px) {
    min-height: 3rem;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0 0 0.4rem;
    font-size: 16px;
  }
`
export const CaptchaContainer = styled.div`
  @media only screen and (max-width: 540px) {
    transform: scale(0.85);
    transform-origin: 0 0;
  }
`

export const CustomLeftText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 21.89px;
  @media only screen and (max-width: 1200px) {
    text-align: center;
  }
`

export const Inline = styled.div`
  width: 85%;
  display: flex;
  aling-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 4rem;
  right: 3rem;

  @media only screen and (max-width: 1200px) {
    position: static;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`
