import { Modal } from "antd"
import Heading from "../../../components/Heading/Heading"
import Text from "../../../components/Text/Text"
import TextSpan from "../../../components/Text/TextSpan"
import { Row, Margin, Li, Th, Td } from "./Style"

const TournamentModal = (props) => {
  const { isModalOpen, handleOk, handleCancel } = props

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Accept"
      centered
      width={1000}
      closable={false}
      bodyStyle={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 200px)",
        padding: "1.25rem 1rem 0 1rem",
      }}
    >
      <>
        <Heading
          Text="BETA-TESTING $10K USD GIVEAWAY"
          color="rgb(114, 50, 225)"
          fw="700"
          center
          fs="30px"
          lhXm="28px"
          lhMs="26px"
          lh="28.6px"
          ls="0.16px"
          m="1rem 0"
        />

        <Row style={{ margin: "1rem 0 2rem 0" }}>
          <Text
            Text="To celebrate the launch of a new crypto gaming platform, HoloBet will be organizing a tournament offering a guaranteed prize pool of $10,000 USD. Additionally, the value of the tournament will increase based on the total of deposits made throughout the duration of the tournament. A portion of all deposits made will be added as part of the tournament prize-pool."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <TextSpan
            Text="There are no "
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <TextSpan
            Text="entry requirements"
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="rgb(114, 50, 225)"
            fw="bold"
            mcenter
          />
          <TextSpan
            Text=" to be part of this giveaway."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row style={{ margin: "1rem 0 2rem 0" }}>
          <Text
            Text="All you need to do is play on HoloBet and you will automatically be entered to win our cash prizes!"
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>
        <Row style={{ margin: "1rem 0 2rem 0" }}>
          <Text
            Text="To win, a player’s points are calculated by adding the sum of multipliers accumulated through the eligible slot games over the tournament period. Scores will be tabulated and players are able to see their standings in real time. The top 100 positions will receive their prize at the end of the beta-testing period!"
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>
        <Row style={{ margin: "1rem 0 2rem 0" }}>
          <Text
            Text="Stakes played on each slots do not affect the final scores."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="rgb(114, 50, 225)"
            fw="bold"
            mcenter
          />
        </Row>
        <Row>
          <Text
            Text="The list of games for the tournament are:"
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <ol style={{ margin: "0 0 0 1.5rem" }}>
            <Li>Hand of Anubis</Li>
            <Li>Starlight Princess</Li>
            <Li>Money Train 3</Li>
            <Li>Gates of Olympus</Li>
            <Li>Dog house</Li>
            <Li>Stack'em</Li>
          </ol>
        </Row>

        <Row>
          <Text
            Text="Simply earn the highest cumulative multiplier on the games above for your chance to win!"
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>
        <Row>
          <Text
            Text="Top 100 positions will be qualified for the prize pool. The payout structure is as followed:"
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <Th>Ranking</Th>
                <Th>Percentage of Prize Pool</Th>
                <Th>Breakdown</Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Td >1</Td>
                <Td>25%</Td>
                <Td>Rank 1 will be awarded 25% of the total prize pool</Td>
              </tr>
              <tr>
                <Td>2</Td>
                <Td>18%</Td>
                <Td>Rank 2 will be awarded 18% of the total prize pool</Td>
              </tr>
              <tr>
                <Td>3</Td>
                <Td>12%</Td>
                <Td>Rank 3 will be awarded 12% of the total prize pool</Td>
              </tr>
              <tr>
                <Td>4-9</Td>
                <Td>15%</Td>
                <Td>Rank 4-9 will share 15% of the prize pool evenly</Td>
              </tr>
              <tr>
                <Td>10-25</Td>
                <Td>10%</Td>
                <Td>Rank 10-25 will share 10% of the prize pool evenly</Td>
              </tr>
              <tr>
                <Td>26-50</Td>
                <Td>10%</Td>
                <Td>Rank 26-50 will share 10% of the prize pool evenly</Td>
              </tr>
              <tr>
                <Td>51-100</Td>
                <Td>10%</Td>
                <Td>Rank 51-100 will share 10% of the prize pool evenly</Td>
              </tr>
            </tbody>
          </table>
        </Row>
      </>
    </Modal>
  )
}
export default TournamentModal
