import React, { useState } from "react"
import {
  StyledLeftComponent,
  StyledBottom,
  Conatiner,
  Link,
  CustomFlex,
  Logo,
  LogoContainer,
  SocialLink,
  StyledSocailContainer,
  CustomLeftText,
  Inline,
} from "./Style"
import Text from "../../../components/Text/Text"
import Heading from "../../../components/Heading/Heading"
import "./Style.css"
import Button from "../../../components/Button/Button"
import TournamentModal from "./TournamentModel"
import HBTLogo from "../../../components/HBTLogo"

const LeftComponent = ({onNextClick}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const modalProps = {
    isModalOpen,
    showModal,
    handleOk,
    handleCancel,
  }

  return (
    <StyledLeftComponent>
      <div>

        <HBTLogo/>
        <Conatiner>
          {/* <Text
            Text="WELCOME TO HOLOBET!"
            fs="17px"
            xmsize="15px"
            msize="13px"
            fw="400"
            lh="17px"
            ls="0.16px"
            mcenter
          /> */}
          <Heading
            Text="THE LATEST CRYPTO CASINO"
            fw="700"
            fs="40px"
            xmsize="32px"
            msize="24px"
            m="1rem 0 0 0"
            width="30rem"
            lh="45.92px"
            lhXm="42.92px"
            lhMs="38.92px"
            ms="28px"
            mcenter
          />
          <Heading
            Text={'SET TO OPEN IN'}
            fs="26px"
            xmsize="22px"
            msize="19px"
            fw="700"
            // m="0.25rem 0 1rem 0"
            mcenter
          />
           <Heading
            Text={'Q2 2023'}
            fs="26px"
            xmsize="22px"
            msize="19px"
            fw="700"
            m="0.25rem 0 1rem 0"
            mcenter
          />
          <CustomLeftText>
            Sign up to enter our early access multiplier tournament and get
            <span style={{ fontWeight: "700" }}>
              {" "}
              exclusive bonuses
            </span>{" "}
            as an early adopter!
          </CustomLeftText>
         
        </Conatiner>
      </div>

      <Inline>
        
        <TournamentModal {...modalProps} />
        <CustomFlex>
          <Button
            Text="Sign Up"
            bg="#FFF"
            color="#7232E1"
            onClick={() => {
              console.log("dssds ")
              onNextClick()
            }}
          />
        </CustomFlex>
        {/* <Link onClick={showModal}>
          <Text
            Text="Tournament Information"
            fw="700"
            fs="16px"
            xmsize="14px"
            msize="15px"
            lh="100%"
            ls="0.257028px"
            m="1rem 0 0 0"
          />
        </Link> */}
        <StyledBottom>
          <Text
            Text="Follow us on social media"
            fw="400"
            fs="16px"
            xmsize="14px"
            msize="13px"
          />
          <StyledSocailContainer>
            <SocialLink
              href="https://discord.com/invite/holobet"
              target="_blank"
              whileHover={{ scale: 1.2 }}
            >
              <svg
                width="21"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.8043 0C6.093 0 3.94849 0.895508 3.56375 1.07447C3.17901 1.25344 2.75196 1.81211 2.2674 2.73416C1.78217 3.6562 1.3928 4.73408 0.760163 6.31959C0.128183 7.9051 -0.0192351 10.9618 0.00191909 11.8947C0.0230733 12.8277 0.126861 13.5565 1.05566 14.1083C1.9838 14.6595 2.80484 15.2366 3.66886 15.5836C4.53354 15.9307 5.12321 16.1804 5.52382 15.8333C5.92442 15.4863 6.18819 14.8786 6.18819 14.8786C6.18819 14.8786 6.56764 14.3363 5.85104 14.011C5.13445 13.6851 4.77615 13.4571 4.80722 13.121C4.83895 12.7848 4.89117 12.6004 5.07032 12.6439C5.24947 12.6875 5.67189 13.468 7.29482 13.8259C8.91774 14.1839 10.4997 14.1301 10.4997 14.1301C10.4997 14.1301 12.0823 14.1845 13.7052 13.8259C15.3281 13.468 15.7499 12.6875 15.929 12.6439C16.1082 12.6004 16.1604 12.7848 16.1921 13.1203C16.2239 13.4571 15.8656 13.6851 15.149 14.011C14.4324 14.3363 14.8118 14.8786 14.8118 14.8786C14.8118 14.8786 15.0756 15.4856 15.4762 15.8333C15.8761 16.1804 16.4665 15.9307 17.3305 15.5836C18.1945 15.2366 19.0162 14.6602 19.9443 14.1083C20.8731 13.5565 20.9769 12.8277 20.9981 11.8947C21.0192 10.9625 20.8718 7.9051 20.2392 6.31959C19.6072 4.73408 19.2172 3.6562 18.7319 2.73416C18.248 1.81211 17.8203 1.25344 17.4363 1.07379C17.0515 0.895508 14.9063 0 14.195 0C13.4837 0 13.2735 0.510358 13.2735 0.510358L13.0256 1.07447C13.0256 1.07447 11.3597 0.748525 10.5109 0.747845C9.6621 0.747845 7.97439 1.07447 7.97439 1.07447L7.72649 0.509678C7.72649 0.509678 7.51627 0 6.8043 0ZM6.88165 6.76939H6.90809C7.94531 6.76939 8.78552 7.70368 8.78552 8.85573C8.78552 10.0085 7.94531 10.9421 6.90809 10.9421C5.87088 10.9421 5.03066 10.0085 5.03066 8.85573C5.03 7.71457 5.85501 6.78504 6.88165 6.76939ZM14.0919 6.76939H14.1184C15.145 6.78504 15.9693 7.71525 15.9693 8.85573C15.9693 10.0085 15.1291 10.9421 14.0919 10.9421C13.054 10.9421 12.2145 10.0085 12.2145 8.85573C12.2145 7.70368 13.054 6.76939 14.0919 6.76939Z"
                  fill="white"
                />
              </svg>
            </SocialLink>
            <SocialLink
              href="https://twitter.com/holobet"
              target="_blank"
              whileHover={{ scale: 1.2 }}
            >
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 1.7971C19.2483 2.09753 18.4534 2.29614 17.64 2.38674C18.4982 1.9144 19.1413 1.17133 19.45 0.295371C18.6436 0.737656 17.7608 1.04927 16.84 1.21668C16.2245 0.601772 15.405 0.192458 14.5098 0.0529306C13.6147 -0.0865966 12.6945 0.0515551 11.8938 0.445719C11.093 0.839882 10.4569 1.46776 10.0852 2.23089C9.71355 2.99401 9.62729 3.84921 9.84 4.66236C8.20943 4.58638 6.61444 4.19522 5.15865 3.51429C3.70287 2.83336 2.41885 1.87789 1.39 0.709959C1.02914 1.29054 0.839519 1.94803 0.84 2.61706C0.83872 3.23838 1.00422 3.85034 1.32176 4.39847C1.63929 4.9466 2.09902 5.41388 2.66 5.75872C2.00798 5.74237 1.36989 5.58118 0.8 5.28885V5.33492C0.804887 6.20545 1.13599 7.04771 1.73731 7.71923C2.33864 8.39074 3.17326 8.85029 4.1 9.02015C3.74326 9.12017 3.37287 9.1729 3 9.17677C2.74189 9.17399 2.48442 9.15242 2.23 9.11228C2.49391 9.86112 3.00462 10.5156 3.69107 10.9845C4.37753 11.4535 5.20558 11.7136 6.06 11.7288C4.6172 12.7747 2.83588 13.3455 1 13.3503C0.665735 13.3513 0.331736 13.3328 0 13.295C1.87443 14.41 4.05881 15.0019 6.29 14.9994C7.82969 15.0142 9.35714 14.7461 10.7831 14.2109C12.2091 13.6756 13.505 12.884 14.5952 11.8822C15.6854 10.8804 16.548 9.68845 17.1326 8.37606C17.7172 7.06368 18.012 5.65712 18 4.23856C18 4.08194 18 3.91611 18 3.75027C18.7847 3.21113 19.4615 2.5502 20 1.7971Z"
                  fill="white"
                />
              </svg>
            </SocialLink>
            <SocialLink
              href="https://blog.holobet.com"
              target="_blank"
              whileHover={{ scale: 1.2 }}
            >
              <svg
                width="21"
                height="15"
                viewBox="0 0 21 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.59775 5.78618H6.16315C6.41515 5.78618 6.61955 5.99605 6.61955 6.25479V10.7667C6.61955 11.0254 6.41515 11.2353 6.16315 11.2353H1.59775C1.34575 11.2353 1.14135 11.0254 1.14135 10.7667V6.25479C1.14135 5.99605 1.34575 5.78618 1.59775 5.78618ZM16.3482 6.43088H16.8046C16.9305 6.43088 17.0327 6.53618 17.0327 6.66519V7.60278C17.0327 7.73215 16.9305 7.83709 16.8046 7.83709H16.3482H8.6254H8.169C8.043 7.83709 7.9408 7.73215 7.9408 7.60278V6.66519C7.9408 6.53618 8.043 6.43088 8.169 6.43088H8.6254H16.3482ZM16.5609 9.82906H16.8913C17.0174 9.82906 17.1195 9.934 17.1195 10.0634V11.001C17.1195 11.1303 17.0174 11.2353 16.8913 11.2353H16.5609H10.6022H10.2718C10.1458 11.2353 10.0436 11.1303 10.0436 11.001V10.0634C10.0436 9.934 10.1458 9.82906 10.2718 9.82906H10.6022H16.5609ZM1.36955 0H16.8913C17.6474 0 18.2609 0.630331 18.2609 1.40621V12.6562C18.2609 12.9149 18.4653 13.1248 18.7173 13.1248C18.9693 13.1248 19.174 12.9149 19.174 12.6562V2.81277C19.174 2.29528 19.5832 1.87518 20.0868 1.87518C20.5908 1.87518 21 2.29528 21 2.81277V13.5938C21 14.3697 20.3864 15 19.6305 15H1.36955C0.61355 15 0 14.3697 0 13.5938V1.40621C0 0.630331 0.61355 0 1.36955 0ZM16.4349 3.03306H16.8913C17.0174 3.03306 17.1195 3.138 17.1195 3.26737V4.2046C17.1195 4.33397 17.0174 4.43927 16.8913 4.43927H16.4349H1.82595H1.36955C1.24355 4.43927 1.14135 4.33397 1.14135 4.2046V3.26737C1.14135 3.138 1.24355 3.03306 1.36955 3.03306H1.82595H16.4349Z"
                  fill="white"
                />
              </svg>
            </SocialLink>
          </StyledSocailContainer>
        </StyledBottom>
      </Inline>
    </StyledLeftComponent>
  )
}

export default LeftComponent
