import { Modal } from "antd"
import Heading from "../../../components/Heading/Heading"
import Text from "../../../components/Text/Text"
import { Row, Margin } from "./Style"
import Indtroduction from "../../../components/PrivacyPolicy/Indtroduction"

const ModalContent = (props) => {
  const { isModalOpen, handleOk, handleCancel } = props

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Accept"
      centered
      width={1000}
      closable={false}
      bodyStyle={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 200px)",
        padding: "1.25rem 1rem 0 1rem",
      }}
    >
      <>
        <Heading
          Text="TERMS & CONDITIONS"
          color="rgb(114, 50, 225)"
          fw="700"
          center
          fs="30px"
          lhXm="28px"
          lhMs="26px"
          lh="28.6px"
          ls="0.16px"
          m="1rem 0"
        />

        <Row>
          
          <Indtroduction/>
         
        </Row>
{/* 
        <Row>
          <Heading
            Text="ELIGIBILITY TO ENTER"
            color="rgb(114, 50, 225)"
            fw="700"
            mcenter
            fs="25px"
            lhXm="22px"
            lhMs="20px"
            lh="19px"
            ls="0.16px"
            m="0 0 1rem 0"
          />
          <Text
            Text="The competition is open to entrants over 18 years of age. HoloBet Inc. employees and associates are excluded from the draw."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="By entering the competition, you confirm that you are eligible to do so and that you are eligible to receive any prizes that may be awarded to you."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="There is a limit of one entry per person and the competition is completely free to enter. Any persons who is deemed to have used any bots for entry may have their eligibility voided pending HoloBet Incs. considerations."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <Heading
            Text="THE PRIZE"
            color="rgb(114, 50, 225)"
            fw="700"
            mcenter
            fs="25px"
            lhXm="22px"
            lhMs="20px"
            lh="19px"
            ls="0.16px"
            m="0 0 1rem 0"
          />
          <Text
            Text="The winning prize will be Cash Prize."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="The use of specific brands as prizes by HoloBet Inc. does not imply any affiliation with or endorsement of such brands."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="The prize is non-transferable and non-exchangeable, and no cash alternatives will be provided."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="We reserve the right to substitute prizes of equal or greater value if circumstances beyond our control require doing so."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="HoloBet Inc.'s decision on any aspect of the competition is final and binding, and no correspondence will be entered into about it."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <Heading
            Text="WINNER ANNOUNCEMENT"
            color="rgb(114, 50, 225)"
            fw="700"
            mcenter
            fs="25px"
            lhXm="22px"
            lhMs="20px"
            lh="19px"
            ls="0.16px"
            m="0 0 1rem 0"
          />
          <Text
            Text="The winner will be chosen at random and notified via the email address provided on casino launch."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="HoloBet Inc. will make two attempts to contact the winner via email."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="If the winner does not respond to the emails informing them of their win within 14 days of the second email, they forfeit their right to the prize, and HoloBet Inc. reserves the right to select and notify a new winner."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <Heading
            Text="DELIVERY OF THE PRIZE"
            color="rgb(114, 50, 225)"
            fw="700"
            mcenter
            fs="25px"
            lhXm="22px"
            lhMs="20px"
            lh="19px"
            ls="0.16px"
            m="0 0 1rem 0"
          />
          <Text
            Text="The winner will allow 14 days for the prize to be delivered, otherwise alternative collection or delivery arrangements can be made through mutual agreement."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <Heading
            Text="DATA PROTECTION AND PUBLICITY"
            color="rgb(114, 50, 225)"
            fw="700"
            mcenter
            fs="25px"
            lhXm="22px"
            lhMs="20px"
            lh="19px"
            ls="0.16px"
            m="0 0 1rem 0"
          />
          <Text
            Text="You agree that any personal information that you provide when entering the competition will be used by HoloBet Inc. for the purposes of administering the competition and for the other purposes as specified in our Privacy Policy."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="All entrants may request information on the winning participant by emailing support@holobet.com"
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="If requested by HoloBet Inc., the winner agrees to release their first name and place of employment to any other competition participants."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
          <Margin />
          <Text
            Text="The winner's first name and country of residence will be announced on HoloBet Inc.'s website and social media channels."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <Heading
            Text="LIMITATION OF LIABILITY"
            color="rgb(114, 50, 225)"
            fw="700"
            mcenter
            fs="25px"
            lhXm="22px"
            lhMs="20px"
            lh="19px"
            ls="0.16px"
            m="0 0 1rem 0"
          />
          <Text
            Text="HoloBet Inc. accepts no liability for any damage, loss, injury, or disappointment suffered by any entrants as a result of participating in the competition or being selected for a prize."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>

        <Row>
          <Heading
            Text="GENERAL"
            color="rgb(114, 50, 225)"
            fw="700"
            mcenter
            fs="25px"
            lhXm="22px"
            lhMs="20px"
            lh="19px"
            ls="0.16px"
            m="0 0 1rem 0"
          />
          <Text
            Text="HoloBet Inc. reserves the right, at any time and without prior notice, to cancel the competition or amend these terms and conditions."
            fs="20px"
            xmsize="18px"
            msize="16px"
            lh="27px"
            ls="0.16px"
            color="#000"
            fw="400"
            mcenter
          />
        </Row>
       */}
      </>
    </Modal>
  )
}
export default ModalContent
