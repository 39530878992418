import React from "react"
import Card from "./components/Card"
import PageLayout from "../../components/PageLayout/PageLayout"
import PageWidth from "../../components/Width/PageWidth"

const SignUp = () => {
  return (
    <PageLayout lp="0">
      <PageWidth sp="0">
        <Card />
      </PageWidth>
    </PageLayout>
  )
}

export default SignUp
