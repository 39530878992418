import './App.css';
import SignUp from './page/signUp'

function App() {
  return (
    <>
      <SignUp />
    </>
  );
}

export default App;
