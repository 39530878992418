import Heading from "../Heading/Heading"
import Text from "../Text/Text"


const Indtroduction = () => {
    
    return  <>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.0662384033203125pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
    
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.0662384033203125pt",
        marginTop: "9.55633544921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        TERMS AND CONDITIONS OF THE SERVICES&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "9.67633056640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        1. INTRODUCTION: KEY DEFINITIONS AND WHAT MAKES UP YOUR TERMS AND
        CONDITIONS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9384002685546875pt",
        marginTop: "9.55633544921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        1.1 The following definitions are used in these terms and
        conditions:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119781494140625",
        marginLeft: "0.44159698486328125pt",
        marginRight: "36.2655029296875pt",
        textIndent: "0.099365234375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Access Device"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means any electronic means of accessing the Services, including, but not
        limited to, &nbsp;computers, smartphone devices, feature phones, tablet
        devices, touch devices or any home &nbsp;entertainment system such as
        video games consoles and smart TVs (or by any other remote means);&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Bonus Terms"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means any terms and conditions and/or rules with regard to promotions,
        bonuses and &nbsp;special offers which may apply to any part of the
        Services from time to time;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.317409086227417",
        marginLeft: "0.5188827514648438pt",
        marginRight: "62.628936767578125pt",
        textIndent: "0.0220794677734375pt",
        marginTop: "0.60614013671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Supervision institution"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means the supervision institution of commercial gambling in Curacao –
        Curaçao Gaming Control Board;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.5409622192382812pt",
        marginTop: "0.43621826171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “General Terms"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means the terms and conditions set out in this document;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110689163208007",
        marginLeft: "0.3863983154296875pt",
        marginRight: "45.7972412109375pt",
        textIndent: "0.15456390380859375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Operator"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means Group of companies – SG International N.V., registration No. 137028,
        registered &nbsp;address is at Dr. M.J. Hugenholtzweg 25 Unit 11, Curacao,
        license no. № 8048/JAZ2015-035 (License &nbsp;Holder) and payment agent –
        SG International LP, registration no. SL023302, address 29d Bradshaw
        &nbsp;Street, Saltcoats, Scotland, United Kingdom, KA21 5HR, UK, being
        owned and fully controlled by the &nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3131814956665038",
        marginLeft: "0.19872283935546875pt",
        marginRight: "31.36663818359375pt",
        textIndent: "0.7286376953125pt",
        marginTop: "0.614471435546875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        License Holder and acting under license agreement concluded with SG
        International N.V.. The company &nbsp;SG International LP was founded in
        Scotland, UK, and laws of Scotland, UK apply to its activity;&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Payment Processor"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means SG International LP, a company registered in 29d Bradshaw Street,
        &nbsp;Saltcoats, Scotland, United Kingdom, KA21 5HR, UK with registered
        company number SL023302 acting &nbsp;as a payment agent of License Holder.
        Payment Processor is owned and fully controlled by the License
        &nbsp;Holder and acting under agreement concluded with Operator. The
        company SG International LP was &nbsp;founded in Scotland, UK, and laws of
        Scotland, UK apply to its activity;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.28704071044921875pt",
        marginRight: "33.71685791015625pt",
        textIndent: "0.2539215087890625pt",
        marginTop: "0.47503662109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Group"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        includes SG International LP, registration no. SL023302, address 29d
        Bradshaw Street, &nbsp;Saltcoats, Scotland, United Kingdom, KA21 5HR, UK
        and SG International N.V., registration No. 137028, &nbsp;registered
        address is at Dr. M.J. Hugenholtzweg 25 Unit 11, Curacao. The division of
        competence of &nbsp;group companies is detailed in this Terms and
        Conditions;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.5409622192382812pt",
        marginRight: "46.128662109375pt",
        textIndent: "-0.2539215087890625pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2539215087890625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Privacy Policy"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means the Operator’s privacy policy accessed via the&nbsp;
      </span>
        <a href="https://lolo.bet/en/contacts/privacy-policy"
          target="_blank"
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Privacy Policy&nbsp;
      </a>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        link, which is an &nbsp;unseparable part of Terms and Conditions;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.5409622192382812pt",
        marginRight: "53.02789306640625pt",
        textIndent: "-0.28704071044921875pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.28704071044921875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Rules"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means the Betting Rules and the Game Rules specifically applicable to the
        relevant type of &nbsp;betting and/or gaming, as identified in more detail
        in paragraph 1.3;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.5409622192382812pt",
        marginRight: "41.88702392578125pt",
        textIndent: "-0.2539215087890625pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2539215087890625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Refund"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means a reversal of a means as per request of a Player deposited in the
        Player Account not &nbsp;used for the Services.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311069917678833",
        marginLeft: "0.28704071044921875pt",
        marginRight: "26.75872802734375pt",
        textIndent: "0.2539215087890625pt",
        marginTop: "0.6111602783203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Responsible Gaming"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means the Operator’s recommendations and general information on
        responsible &nbsp;gaming accessed via the&nbsp;
      </span>
        <a
          target="_blank"

          href="https://lolo.bet/en/contacts/responsible-game"
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Responsible Gaming
      </a>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        &nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        link, which is an unseparable part of Terms and Conditions&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Services"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means, as appropriate, the services offered for the time being by the
        Operator through the &nbsp;Website and/or via any Access Device
        application;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092594623565674",
        marginLeft: "0.07727813720703125pt",
        marginRight: "42.648681640625pt",
        textIndent: "0.46368408203125pt",
        marginTop: "0.6144866943359375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Terms and Conditions"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means (a) the General Terms; (b) the Privacy Policy; (c) the Responsible
        &nbsp;Gaming, (d) where appropriate under paragraph 1.3, the relevant
        Rules, Bonus Terms and Additional &nbsp;Terms applicable to the Services
        that are being used by You;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314328908920288",
        marginLeft: "0.17664337158203125pt",
        marginRight: "26.89849853515625pt",
        textIndent: "0.36431884765625pt",
        marginTop: "0.6311416625976562pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        “Website"&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        means the website or any respective page, subpage, subdomain or section
        thereof from time &nbsp;to time, located at or accessible via the domain
        name: lolo.bet.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9384002685546875pt",
        marginTop: "0.5844955444335938pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        1.2 By using and/or visiting any section of the Website, or by opening an
        account with the Operator&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3125205993652342",
        marginLeft: "0.17664337158203125pt",
        marginRight: "24.9708251953125pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        through the Website, You agree to be bound by the Terms and Conditions and
        You accordingly: (a) agree &nbsp;to the use of electronic communications
        in order to enter into contracts; and (b) waive any applicable
        &nbsp;rights or requirements which require a signature by hand, to the
        extent permitted by any applicable law; &nbsp;(c) agree, that in order to
        use our Services you are requested to provide us with certain personal
        &nbsp;information which shall be processed on the basis of our Privacy
        Policy. The Terms and Conditions do &nbsp;not affect your statutory
        rights.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3103475093841552",
        marginLeft: "0.3863983154296875pt",
        marginRight: "38.81494140625pt",
        textIndent: "0.552001953125pt",
        marginTop: "0.6011962890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        1.3 In addition, where You play any game, or place a bet using the
        Services, or otherwise use the &nbsp;Services, You agree to be bound by
        Rules of any game You play (“Game Rules"), as set out under the
        &nbsp;relevant general Help section and any Rules tabs, in respect of any
        new games, the rules applicable to &nbsp;such game; any Bonus Terms; any
        terms and conditions relating to withdrawals and any other terms
        &nbsp;applicable to the Services and/or which You are required to confirm
        Your agreement to as part of the &nbsp;Services.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3115864753723143",
        marginLeft: "0.07727813720703125pt",
        marginRight: "27.167724609375pt",
        textIndent: "0.8611221313476562pt",
        marginTop: "0.651123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        1.4 The original text of the Terms and Conditions are in English and any
        interpretation of them will be &nbsp;based on the original English text.
        If the Terms and Conditions or any documents or notices related to
        &nbsp;them are translated into any other language, the original English
        version will prevail. 1.5 Please read the Terms and Conditions carefully
        before accepting them. Once You have accepted the &nbsp;Terms and
        Conditions, please download and save (or print) the Terms and Conditions
        and store them, &nbsp;along with all confirmation emails, additional
        terms, transaction data, Rules, fair deal rules and payment &nbsp;methods
        relevant to Your use of the Services and Website. Please note that the
        Terms and Conditions &nbsp;are subject to change, as set out in paragraph
        3 below.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128795146942138",
        marginLeft: "0.49680328369140625pt",
        marginRight: "30.9830322265625pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "0.609710693359375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        1.6 If You do not agree to accept and be bound by the Terms and Conditions
        please do not open an &nbsp;account, and/or continue to use Your Account.
        Your continued use of any of the Services will constitute &nbsp;acceptance
        of the Terms and Conditions which we have notified You are in force from
        time to time. 1.7 For the avoidance of doubt, each and all sections of the
        Website are governed by the Terms and Conditions, and You should ensure at
        all times that Your use of the Services and the Website is in
        &nbsp;accordance with the Terms and Conditions.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.39743804931640625pt",
        marginTop: "8.517822265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        GENERAL TERMS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        2. CONTRACTING PARTIES&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038245201110839",
        marginLeft: "0.6182403564453125pt",
        marginRight: "69.13092041015625pt",
        textIndent: "-0.2097625732421875pt",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2097625732421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        2.1 The Terms and Conditions shall be agreed between You and the Operator
        and the Payment &nbsp;processor.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3121718406677245",
        marginLeft: "0.18768310546875pt",
        marginRight: "26.48431396484375pt",
        textIndent: "-0.1987152099609375pt",
        marginTop: "0.681121826171875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.1987152099609375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        All information on the Website is provided by the provider of services on
        the Website, a company SG &nbsp;International N.V. (hereinafter lolo.bet),
        is a company operating lolo.bet. Services are provided to card
        &nbsp;holder by SG International LP located at 29d Bradshaw Street,
        Saltcoats, Scotland, United Kingdom, &nbsp;KA21 5HR, UK. In the event of
        any inquiries and complaints, please direct them to SG International LP.
        &nbsp;SG International N.V. is incorporated under the laws of Curacao,
        Netherlands Antilles and operates its &nbsp;regulated activities in terms
        of License No. 8048/JAZ2015-035. SG International N.V. and SG
        &nbsp;International LP, a limited partnership incorporated under the laws
        of Scotland, UK entered into a &nbsp;partnership agreement where the
        Parties agreed that SG International LP would act as an agent of SG
        &nbsp;International N.V. to promote products and services offered by SG
        International N.V. SG International LP &nbsp;is fully liable for any acts
        of their employees, agents or affiliated entities. SG International LP as
        Payment &nbsp;processor and SG International N.V. as Operator in these
        Terms and Conditions is referred to as LoloBet, &nbsp;lolo.bet, “We",
        “Us", “Our", “Management", “Site" or “Company" that you enter contract
        with (in the &nbsp;case of terms and conditions relating to monies held in
        Your Account from time to time, this also applies&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.17664337158203125pt",
        marginRight: "28.767578125pt",
        textIndent: "-0.320159912109375pt",
        marginTop: "0pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.320159912109375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        to any company which holds such money and shall (where appropriate) be
        deemed to include our &nbsp;agents, partners, and suppliers). The Player
        and registered Account Holder shall be referred to as “You",
        &nbsp;“Yours", “Customer" or “The Player".&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        3. CHANGES TO THE TERMS AND CONDITIONS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119781494140625",
        marginLeft: "0.07727813720703125pt",
        marginRight: "27.59820556640625pt",
        textIndent: "0.47472381591796875pt",
        marginTop: "9.55633544921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        3.1 We may need to change the Terms and Conditions from time to time for a
        number of reasons, &nbsp;including (without limitation) for commercial
        reasons, to comply with law or regulations, to comply with
        &nbsp;instructions, guidance or recommendations from a regulatory body, or
        for customer service reasons. &nbsp;The most up-to-date Terms and
        Conditions can be accessed from the Terms and Conditions link in the
        &nbsp;footer section of the Website.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3126550674438475",
        marginLeft: "0.49680328369140625pt",
        marginRight: "29.91668701171875pt",
        textIndent: "0.05519866943359375pt",
        marginTop: "0.60614013671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        3.2 Where we wish to make substantial changes to the Terms and Conditions,
        we will give You as much &nbsp;prior notice of such changes as is
        reasonably practicable via one of the methods set out in paragraph
        &nbsp;3.3. For minor or insubstantial changes, we may not give You any
        notice of such changes, so You are &nbsp;advised to review the Terms and
        Conditions through the Terms and Conditions link on the Website on a
        &nbsp;regular basis.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.552001953125pt",
        marginRight: "32.0360107421875pt",
        textIndent: "-0.2760009765625pt",
        marginTop: "0.599884033203125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2760009765625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        3.3 Where we make changes to the Terms and Conditions which we wish to
        notify You of, we will do so &nbsp;by such method of notification as we
        may, in our discretion, deem appropriate.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.19872283935546875pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4. OPENING YOUR ACCOUNT&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038245201110839",
        marginLeft: "0.2539215087890625pt",
        marginRight: "32.78271484375pt",
        textIndent: "-0.2760009765625pt",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2760009765625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.1 In order to place a bet or play a game using the Services, You will
        need to open an account with the &nbsp;Operator (“Your Account" or
        “Account“).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.2539215087890625pt",
        marginTop: "0.68115234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.2 In order to open Your Account for use with the Services, You
        can:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3101632595062256",
        marginLeft: "0.2539215087890625pt",
        marginRight: "66.15924072265625pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.2.1 click on Join Now on the Website and follow the on-screen
        instructions ;or 4.2.2 open by such other Account opening method as shall,
        from time to time be offered by the &nbsp;Operator;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3125174522399903",
        marginLeft: "0.1656036376953125pt",
        marginRight: "36.7061767578125pt",
        textIndent: "0.08831787109375pt",
        marginTop: "0.622802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.3 When You open Your Account You will be asked to provide us with
        personal information, including &nbsp;Your first and last name and date of
        birth and appropriate contact details, including an address,
        &nbsp;telephone number and e-mail address (“Your Contact Details“). You
        may update Your Contact Details &nbsp;from time to time by contacting
        Customer Services; or through the My Account management page on &nbsp;the
        Website; or by such other method as shall, from time to time, be offered
        by the Operator. 4.4 In opening Your Account You warrant that:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038286685943603",
        marginLeft: "0.2539215087890625pt",
        marginRight: "25.4454345703125pt",
        textIndent: "-0.5740814208984375pt",
        marginTop: "0.60113525390625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.5740814208984375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.4.1 You understand and accept the risk that, by using the Services, You
        may, as well as winning money, &nbsp;lose money;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146911144256592",
        marginLeft: "0.2539215087890625pt",
        marginRight: "30.552001953125pt",
        textIndent: "-0.5740814208984375pt",
        marginTop: "0.6811065673828125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.5740814208984375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.4.2 You are: (a) over 18 years of age; and (b) above the age at which
        gambling or gaming activities are &nbsp;legal under the law or
        jurisdiction that applies to You (the “Relevant Age“);&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.2539215087890625pt",
        marginTop: "0.611175537109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.4.3 gambling is not illegal in the territory where You reside;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.2539215087890625pt",
        marginTop: "1.516326904296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.4.4 You are legally able to enter into contracts;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.2539215087890625pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.4.5 You have not been excluded or self-excluded from gambling; and&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.2539215087890625pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.4.6 You have not already had an Account closed by us for any
        reason.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3118844509124756",
        marginLeft: "0.2539215087890625pt",
        marginRight: "36.86083984375pt",
        textIndent: "-0.18767547607421875pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.18767547607421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.5 Your Account must be registered by Youself in Your own, correct, name
        and personal details and it &nbsp;shall only be issued once for You and
        not duplicated through any other person, family, household, &nbsp;address
        (postal or IP), email address, Access Device or any environment where
        Access Devices are &nbsp;shared (e.g. schools, workplaces, public
        libraries etc) and/or account in respect of the Services. Any &nbsp;other
        accounts which You open with us, or which are beneficially owned by You in
        relation to the&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.3863983154296875pt",
        marginRight: "29.287353515625pt",
        textIndent: "-0.13248443603515625pt",
        marginTop: "0pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.13248443603515625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Services shall be “Duplicate Accounts“. We may close any Duplicate Account
        (but shall not be obliged to &nbsp;do so). If we close a Duplicate
        Account:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.2539215087890625pt",
        marginRight: "34.9951171875pt",
        textIndent: "-0.6734390258789062pt",
        marginTop: "0.58123779296875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.6734390258789062pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.5.1 all bonuses, free bets and winnings accrued from such bonuses and
        free bets obtained using that &nbsp;Duplicate Account will be void and
        forfeited byUs;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119781494140625",
        marginLeft: "0.17664337158203125pt",
        marginRight: "25.94256591796875pt",
        textIndent: "0.07727813720703125pt",
        marginTop: "0.68109130859375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.5.2 we may, at our entire discretion, void all winnings and refund all
        deposits (less amounts in respect &nbsp;of void winnings) made in respect
        of that Duplicate Account and, to the extent not recovered by us from
        &nbsp;the relevant Duplicate Account, any amounts to be refunded to us by
        You in respect of a Duplicate &nbsp;Account may be recovered by us
        directly from any other of Your Accounts (including any other Duplicate
        &nbsp;Account); or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.2539215087890625pt",
        marginRight: "25.51171875pt",
        textIndent: "-0.231842041015625pt",
        marginTop: "0.60614013671875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.231842041015625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        4.5.3 we may, at our entire discretion, allow usage of the Duplicate
        Account to be deemed valid in which &nbsp;case all losses and stakes
        placed by or for You through the Duplicate Account shall be retained by
        us.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.5188827514648438pt",
        marginTop: "8.53125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5. VERIFICATION OF YOUR IDENTITY; ANTI-MONEY LAUNDERING REQUIREMENTS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.5409622192382812pt",
        marginTop: "9.55633544921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.1 You warrant that:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.18768310546875pt",
        marginRight: "33.60406494140625pt",
        textIndent: "0.35327911376953125pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.1.1 the name and address and any other requested information You supply
        to Us when opening Your &nbsp;Account are accurate and truthful; and&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110686302185057",
        marginLeft: "0.17664337158203125pt",
        marginRight: "29.27630615234375pt",
        textIndent: "0.36431884765625pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.1.2 You are the rightful owner of the money which You at any time
        deposit in Your Account. 5.2 By agreeing to the Terms and Conditions You
        authorise Us to undertake any such verification checks &nbsp;from time to
        time as we may require ourselves or may be required by third parties
        (including, but not &nbsp;limited to, regulatory bodies and payment
        processors) to confirm these facts (the “Checks“). You agree &nbsp;that
        from time to time, upon our request (including requests from Payment
        processor), You may be &nbsp;required to provide additional details in
        respect of any of such information You have provided us, &nbsp;including
        in relation to any deposits which You have made into Your Account.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.312200880050659",
        marginLeft: "0.17664337158203125pt",
        marginRight: "26.29986572265625pt",
        textIndent: "0.36431884765625pt",
        marginTop: "0.634490966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.3 Whilst we are undertaking any Checks from time to time, we may
        restrict You from withdrawing &nbsp;funds from Your Account and/or prevent
        access to all or certain parts of the Website. Please note that &nbsp;we
        may from time to time re-perform the Checks for regulatory, security or
        other business reasons. If &nbsp;any such restrictions cause You a
        problem, please contact us – <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a>. 5.4 In certain
        circumstances we may have to contact You and ask You to provide further
        information to &nbsp;us directly in order to complete the Checks. For this
        purpose, we will be entitled, at our sole discretion, &nbsp;to require
        that You provide us with a notarised ID or any equivalent certified ID
        according to the &nbsp;applicable law of Your jurisdiction or otherwise,
        proof of address, utility bills, bank details, bank &nbsp;statements and
        bank references and any documentation that validates your source of funds.
        Until such &nbsp;information has been supplied to our satisfaction we may
        prevent any activity to be undertaken by You &nbsp;in relation to the
        Account or we may, where we reasonably believe that deliberately incorrect
        or &nbsp;misleading information has been provided by You, keep any amount
        deposited on the Account following &nbsp;the closure of the Account by
        us.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311975574493408",
        marginLeft: "0.17664337158203125pt",
        marginRight: "29.62969970703125pt",
        textIndent: "0.36431884765625pt",
        marginTop: "0.6040802001953125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.5 It may be an offence for persons under the Relevant Age to make use of
        the Website. If we are &nbsp;unable to confirm that You are the Relevant
        Age then we may suspend Your Account until such time &nbsp;that we are
        able to confirm that You are the Relevant Age. If You are subsequently
        proven to have been &nbsp;under the Relevant Age at the time You made any
        gambling or gaming transactions with us, then: 5.5.1 Your Account will be
        closed;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.309077501296997",
        marginLeft: "0.5409622192382812pt",
        marginRight: "28.51458740234375pt",
        textIndent: "-0.28704071044921875pt",
        marginTop: "0.6061477661132812pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.28704071044921875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.5.2 all transactions made whilst You were underage will be made void,
        and all related funds deposited &nbsp;by You will be returned by the
        payment method used for the deposit of such funds, wherever
        &nbsp;practicable;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311976718902588",
        marginLeft: "0.1656036376953125pt",
        marginRight: "30.656494140625pt",
        textIndent: "0.37535858154296875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.5.3 any deposits made whilst You were under the Relevant Age will be
        returned to You; and 5.5.4 any winnings which You have accrued during such
        time when You were under the Relevant Age &nbsp;will be forfeited by You
        (and shall be deducted from the amount of any deposit returned under
        &nbsp;paragraph 5.5.3) and You will return to us on demand any such funds
        which have been withdrawn from &nbsp;Your Account.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119781494140625",
        marginLeft: "0.4857635498046875pt",
        marginRight: "24.6832275390625pt",
        textIndent: "0.05519866943359375pt",
        marginTop: "0.606201171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        5.6 All your documents as per list above need to be provided by You to us
        in 7 (seven) days after &nbsp;opening of Your account or if requested to
        You by support – in 7 (seven) days after date of respective &nbsp;request.
        We will review Your document(s) in 7 (seven) day time after receipt of the
        respective &nbsp;document(s). In case You fail to provide necessary
        document(s) in due time We may suspend use of Your &nbsp;account or
        decline Your withdrawal request (as applicable).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.45263671875pt",
        marginTop: "8.526123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        6. KNOW YOUR CLIENT POLICY&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.07727813720703125pt",
        marginRight: "35.922607421875pt",
        textIndent: "-0.8390426635742188pt",
        marginTop: "9.706298828125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.8390426635742188pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        To make the Verification process as easy as possible, here are a few
        recommendations when You send &nbsp;Us information and documents:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.68109130859375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Each document must be sent as a separate image&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.33119964599609375pt",
        marginRight: "38.36236572265625pt",
        textIndent: "0.7065582275390625pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Photo Captured images are preferred, but if you’d rather scan your
        documents, send them as a .jpeg When you take your picture:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.44159698486328125pt",
        marginRight: "52.32623291015625pt",
        textIndent: "0.596160888671875pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Be sure that the first 6 and the last 4 digits of your credit card
        number should be visible, and CVV &nbsp;should be hidden;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Your picture must be in focus and all the text easy to read;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038245201110839",
        marginLeft: "0.19872283935546875pt",
        marginRight: "24.75pt",
        textIndent: "0.8390350341796875pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Your full passport photo page or ID must be in shot, don’t leave any
        bits out or cover with your hand or &nbsp;fingers;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.681121826171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Your lighting must be good, to stop any glare don’t use flash.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038245201110839",
        marginLeft: "0.33119964599609375pt",
        marginRight: "62.445343017578125pt",
        textIndent: "-0.24288177490234375pt",
        marginTop: "9.696319580078125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.24288177490234375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        We need all of this because it is part of gambling responsibly – and the
        applicable law requires it. 6.1. Why do I need to verify my account?&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.33119964599609375pt",
        marginRight: "31.46490478515625pt",
        textIndent: "-0.1103973388671875pt",
        marginTop: "0.681121826171875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.1103973388671875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        When you sign up for a new account we check that you are over 18 and we
        verify that you are who you &nbsp;say you are, (a process called Know Your
        Customer ‘KYC’).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.5740814208984375pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        6.2. What do I need to provide?&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.07727813720703125pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        To verify your age we will accept one of the following documents:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Passport (recommended)&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Driving license&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • National ID Card&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.516326904296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Birth Certificate (verification takes up to 24 hours)&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3174086570739745",
        marginLeft: "0.5188827514648438pt",
        marginRight: "74.79428100585938pt",
        textIndent: "0.5188751220703125pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Payment card (note that first 6 and last 4 digits should be visible and
        CVV should be hidden). Card data shall not be collected and stored by Us
        without PCI DSS.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.303826379776001",
        marginLeft: "0.07727813720703125pt",
        marginRight: "93.69189453125pt",
        textIndent: "-0.960479736328125pt",
        marginTop: "0.5561676025390625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.960479736328125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        To verify your identity (KYC) we may need additional documents which could
        also include: • Signed Credit Agreement;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.681121826171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Utility Bill (less than 6 months old);&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Bank Statement (less than 6 months old);&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Make sure your documents are in acceptable condition.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.5740814208984375pt",
        marginTop: "1.516326904296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        6.3. How can I send you my documents?&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.07727813720703125pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        The quickest and easiest way to verify your account is online.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.1656036376953125pt",
        marginTop: "1.6323165893554688pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        You can also send the documents via email.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Here are a few tips on how to get it right first time:
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Sending your documents via email&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "1.0377578735351562pt",
        marginRight: "57.627166748046875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Take a photo of each of your documents and save the photos (or, scan
        them and save as a .jpeg) • Open the email account that you used to
        register for our Website&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Open a new email, addressed to <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a>&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Attach the photos&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "1.0377578735351562pt",
        marginRight: "109.9014892578125pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Enter your account number or username in the ‘Subject’ bar at the top of
        your email • Press ‘Send&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.33119964599609375pt",
        marginRight: "39.35833740234375pt",
        textIndent: "-0.3863983154296875pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.3863983154296875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        When you register for an account, on the account registration page, we’ll
        need you to provide certain &nbsp;information for security reasons:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.49680328369140625pt",
        marginRight: "54.789794921875pt",
        textIndent: "0.54095458984375pt",
        marginTop: "0.58123779296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Your name and contact details, including a valid email address which
        needs to be unique to your &nbsp;account&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.46124267578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Your date of birth – you must be at least 18 years old&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.44159698486328125pt",
        marginRight: "57.218597412109375pt",
        textIndent: "0.596160888671875pt",
        marginTop: "1.66632080078125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • A unique username (your username may contain only letters, numbers,
        underscore and hyphen &nbsp;symbols) and a password which means only you
        can access your account&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.58111572265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Your betting currency and a deposit limit&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • A security question and the answer&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.44159698486328125pt",
        marginRight: "35.39251708984375pt",
        textIndent: "0.08832550048828125pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Our registration page checks your details as you enter them and will let
        you know if there’s a problem, &nbsp;so you can make any changes you need
        to before you click on the ‘Create My Account’ button. For legal reasons
        when travelling abroad you may not be able to access your account.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.19872283935546875pt",
        marginRight: "53.16668701171875pt",
        textIndent: "0.132476806640625pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        We are unable to accept accounts or offer any of our products or services
        to clients resident in the &nbsp;following countries:
      </span>
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "31.219970703125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Game provider&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted territories
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "89.03994750976562pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.2584075927734375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  1×2 Network&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from UK, Italy, Sweden, &nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.311067771911621",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "11.90252685546875pt",
                  textIndent: "-0.3311920166015625pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.3311920166015625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia,France,Israel, United States of America, Afghanistan,
                  Algeria, &nbsp;Cuba, Curacao, Egypt,Haiti, Iran, Iraq, Laos,
                  Libya, North Korea, Pakistan, &nbsp;Palestinian territory
                  occupied, Philippines, Somalia, Syria, Belarus, Russia,
                  &nbsp;Netherlands(regulated)
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "60.12001037597656pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076904296875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Ainsworth&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146922588348389",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "6.6202392578125pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Australia, Canada, China,
                  The United &nbsp;States of America (and any other countries to
                  which US-origin software &nbsp;may not be exported or
                  re-exported) Iran, North Korea, Cuba, Sudan, Syria.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "147.14606475830078pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076904296875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Amatic&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3119303703308105",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "2.005615234375pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Albania,
                  Algeria, Angola, &nbsp;Antigua and Barbuda, Armenia, Australia,
                  Austria, Bosnia, Cambodia, China, &nbsp;Cuba, Curacao, Cyprus,
                  Ecuador, Estonia, France and Territories, French
                  &nbsp;Polynesia, Georgia, Guyana, Hong Kong, Indonesia, Iran,
                  Iraq, Israel, &nbsp;Kuwait, Laos, Libya, Liechtenstein, Macao,
                  Mexico, Myanmar, Namibia, &nbsp;Netherlands Antilles, Nicaragua,
                  North Korea, Pakistan, Panama, Papua &nbsp;New Guinea, Peru,
                  Philippines, Singapore, Slovakia, South Africa, South
                  &nbsp;Korea, Sudan, Switzerland, Syria, Taiwan, Tunisia, Uganda,
                  United States of &nbsp;America and Territories, Vietnam, Yemen,
                  Zimbabwe
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "118.10003662109375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076904296875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Apollo&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.312882947921753",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "2.97705078125pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Algeria, Afghanistan,
                  Armenia, &nbsp;Antigua &amp; Barbuda, Bahrain, Bangladesh,
                  Bhutan, Brunei, Cambodia, China, &nbsp;Cuba, Curacao, Czech
                  Republic, Georgia, Guyana, Iran, Iraq, Israel, &nbsp;Kahnawake,
                  Kazakhstan, Kuwait, Libya, Malaysia, Myanmar, North Korea,
                  &nbsp;Pakistan, Panama, Papua New Guinea, Philippines, Poland,
                  Qatar, Saudi &nbsp;Arabia, Sudan, Syria, Thailand, United States
                  of America and its Territories, &nbsp;Vietnam, Yemen, Zimbabwe
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.5999755859375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076904296875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Asia Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "3.39654541015625pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Taiwan, Hong Kong, Macau,
                  America, &nbsp;Philippine,Singapore and Bulgaria, USA, Curacao.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "436.99993896484375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076904296875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Authentic Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146929740905762",
                  marginLeft: "4.3089599609375pt",
                  marginRight: "5.449951171875pt",
                  textIndent: "-0.1987152099609375pt",
                  textAlign: "justify",
                  marginTop: "1.6363525390625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.1987152099609375pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Afghanistan, Jordan, Thailand, Algeria, Kazakhstan, Timor-Leste,
                  American &nbsp;Samoa, Kuwait,Trinidad &amp; Tobago, Andorra,
                  Kyrgystan, Tunisia, Argentina, &nbsp;Laos, Turkey, Australia,
                  Lebanon, Turkmenistan, Azerbaijan Lesotho United &nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.312322187423706",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "12.8577880859375pt",
                  textIndent: "0.1103973388671875pt",
                  marginTop: "0.461151123046875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Arab Emirates, Bahrain, Libya, *United States &amp;
                  Dependent/Outlying &nbsp;Territories, Bangladesh, Liechtenstein,
                  Uruguay, Barbados, Macau, &nbsp;Uzbekistan, Bhutan, Macedonia,
                  Venezuela, Bolivia, Malaysia, Vietnam, &nbsp;Botswana, Mali,
                  Virgin Islands, British Brunei, Darussalam, Mauritius,
                  &nbsp;Yemen, Burundi, Moldova, Cambodia, Monaco, China Morocco,
                  Chile, &nbsp;Myanmar, Christmas Island, Nauru, Cocos (Keeling),
                  Islands Nepal, Cook &nbsp;Islands, Niue, Congo (Democratic
                  Republic), Northern Mariana Islands, &nbsp;Cuba, North Korea,
                  Cyprus, Oman, Ecuador, Pakistan, El Salvador, &nbsp;Palestinian
                  Territory, Eritrea, Paraguay, Fiji, Qatar, France, Russian
                  &nbsp;Federation, French Polynesia, Saudi Arabia, Guam
                  Singapore, Guatemala, &nbsp;Somalia, Haiti, South Africa, Hong
                  Kong (Special Administrative Region of &nbsp;China), South
                  Korea, Iceland, South Sudan, Indonesia, Sudan, Iran,
                  &nbsp;Suriname, Iraq, Syria, Israel, Taiwan, Japan, Tajikistan,
                  Curacao. Regulated:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.312790250778198",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "3.60626220703125pt",
                  marginTop: "0.60296630859375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Aland, Canada, Poland, Albania, Alderney, Antigua and Barbuda,
                  Argentina, &nbsp;Armenia, Bahamas, Belarus, Belgium, Belize,
                  Bosnia Herzegovina, Bulgaria, &nbsp;Colombia, Comoros, Costa
                  Rica, Croatia, Curacao, Czech Republic, &nbsp;Denmark, Dominica,
                  Dominican Republic, Estonia, French Guiana, French
                  &nbsp;Southern Territories, Georgia, Ghana, Gibraltar, Greece,
                  Grenada, Hungary, &nbsp;Isle of Man, Italy, Jamaica, Jersey,
                  Latvia, Lithuania, Malta, Mexico, &nbsp;Montenegro, Netherlands,
                  Nicaragua, Panama, Papua New Guinea, &nbsp;Philippines,
                  Portugal, Romania, Saint Kitts &amp; Nevis, San Marino (Italy),
                  &nbsp;Serbia, Seychelles, Slovakia, Slovenia, Spain, Swaziland,
                  Sweden, &nbsp;Switzerland, Tanzania, Uganda, Ukraine, United
                  Kingdom, United States, &nbsp;Vanuatu.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "60.1400146484375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076904296875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Atmosfera&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146929740905762",
                  marginLeft: "4.3089599609375pt",
                  marginRight: "16.31329345703125pt",
                  textIndent: "-0.1987152099609375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.1987152099609375pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Antigua and Barbuda, Armenia (only AtmosferaLive), Afghanistan,
                  Israel, &nbsp;Iraq, Iran, Canada, Cuba, Curacao, Libya, Macau,
                  Netherlands Antilles, &nbsp;Syria, Republic of Serbia, USA,
                  Sudan, Kahnawake
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "103.55999755859375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  BeeFee&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3125216007232665",
                  marginLeft: "4.4966278076171875pt",
                  marginRight: "9.02734375pt",
                  textIndent: "0.7507171630859375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Algeria,
                  Andorra, &nbsp;Antigua and Barbuda, Australia, Bulgaria, Cuba,
                  Denmark, France and its &nbsp;territories, Hong Kong, Indonesia,
                  Iran, Iraq, Israel, Kahnawake, Lebanon, &nbsp;Libya, Luxembourg,
                  Macau, Montenegro, Myanmar, Netherlands, North &nbsp;Korea,
                  Pakistan, Palestinian Territory, Portugal, San Marino,
                  Singapore, Sri &nbsp;Lanka, Sudan, Syria, The USA and its
                  territories, Tunisia, Yemen, Zimbabwe
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.22998046875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Belatra&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Curacao, USA
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.600006103515625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  BF Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146902561187743",
                  marginLeft: "5.2363128662109375pt",
                  marginRight: "16.46795654296875pt",
                  textIndent: "0.0110321044921875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Algeria,
                  Curacao, The &nbsp;USA and its territories, Tunisia, United
                  Kingdom, Yemen, Zimbabwe
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.199981689453125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  BetGamesTV&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from France, USA, Poland, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.60003662109375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  BetSoft&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3038245201110839",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "39.11492919921875pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from United States of America and
                  &nbsp;Territories, Curacao.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "161.5399932861328pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Big Time Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3128795146942138",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "2.988037109375pt",
                  textIndent: "0.1103973388671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Afghanistan, Australia, Algeria, Angola, Antigua &amp; Barbuda,
                  Cambodia, &nbsp;Canada, China, Cuba, Curacao, Cyprus, Guyana,
                  Hong Kong, Indonesia, Iran, &nbsp;Iraq, Israel, Kahnawake,
                  Kuwait, Libya, Macau, Myanmar, Namibia, &nbsp;Netherlands
                  Antilles, North Korea, Pakistan, Papua New Guinea, Sudan,
                  &nbsp;Syria, The Philippines, Turkey, Uganda, United States of
                  America and its &nbsp;Territories, Yemen&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.47784423828125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Need local online gaming license:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146906852722167",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "31.41046142578125pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Belgium,Denmark, Estonia, France and its Territories, Germany,
                  Italy, &nbsp;Latvia, Republic of Serbia, Romania, Spain, Sweden,
                  United States of &nbsp;America:- New Jersey- Pennsylvania,
                  United Kingdom
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "103.58999633789062pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blueprint Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3130624771118165",
                  marginLeft: "4.805755615234375pt",
                  marginRight: "16.51202392578125pt",
                  textIndent: "0.44158935546875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Australia, USA and
                  territories, &nbsp;Germany, Iran, Syria, Norh Korea, Cuba,
                  Curacao, Sudan, South Sudan, &nbsp;Myanmar. Must be licensed to
                  operate: Austria, Belgium, Estonia, Spain, &nbsp;Italy,
                  Portugal, Czech Republic, Switzerland, Lithuania, UK, Hungary,
                  &nbsp;Denmark, France, Sweden, Latvia, Romania, Croatia,
                  Bulgaria, Canada &nbsp;(State lotteries only).
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "52.196006774902344pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Booming Games&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.303826093673706",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "29.60540771484375pt",
                  textIndent: "0.132476806640625pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Cayman Islands, Iran, Iraq, Israel, North Korea, Curacao, Saudi
                  Arabia, &nbsp;Singapore,&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "center",
                  marginTop: "0.6771240234375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  United States of America, and all territories and possessions,
                  being the U.S.&nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "89.17999267578125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146902561187743",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "18.3299560546875pt",
                  textIndent: "-0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.7396697998046875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Virgin Islands, U.S. Minor Outlying Islands, Guam, Puerto Rico,
                  Marianas &nbsp;Islands and American Samoa, Vatican City&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.84991455078125pt",
                  marginTop: "0.58123779296875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Other than with license:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.8388671875pt",
                  marginRight: "33.23760986328125pt",
                  textIndent: "0.3974456787109375pt",
                  marginTop: "1.516357421875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  United Kingdom, Spain, Slovakia, Portugal, Romania, Czech
                  Republic, &nbsp;Columbia, Denmark, Estonia, Italy, Latvia,
                  Lithuania, Belgium
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.5999755859375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Booongo&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3038309574127196",
                  marginLeft: "4.805755615234375pt",
                  marginRight: "17.13568115234375pt",
                  textIndent: "0.44158935546875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Netherlands, Israel,
                  Curacao, &nbsp;Georgia, Ukraine
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.08001708984375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.838890075683594pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Concept Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from UK, USA, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "60.149993896484375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.838890075683594pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  CT Gamings&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "8.706787109375pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from United States of America and
                  &nbsp;Territories, China, Curacao, Kazakhstan, North Korea,
                  Cyprus, Vietnam, &nbsp;Thailand, Japan, Singapore, Hong Kong,
                  Canada, Malaysia, Indonesia, Israel
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "74.6400146484375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.838890075683594pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  CQ9&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Block Taiwan’s IP, Curacao&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.805755615234375pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Game Code starts with AS: Block Taiwan and the United &nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.706390380859375pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  States/Israel/Bulgaria&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.805755615234375pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Game Code starts with AK: Block Taiwan and the United States
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "132.62002563476562pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Edict&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.311844825744629",
                  marginLeft: "4.6401519775390625pt",
                  marginRight: "16.78814697265625pt",
                  textIndent: "0.6071929931640625pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Argentina, Canada,
                  Democratic &nbsp;People´s Republic of Korea, Bahamas, Botswana,
                  Democratic Repulic &nbsp;Kongo, Curacao, Chile, Denmark,
                  Ethopia, Germany, Ghana, Iran, &nbsp;Kazakhstan, Lybia, Myanmar,
                  Mexico, Netherlands, Norway and Finland, &nbsp;Pakistan, Russia,
                  Sri Lanka, Sudan, South Sudan, South Africa, Syria,
                  &nbsp;Sweden, Trindad und Tobago, Tunisia, United States of
                  America, United &nbsp;States Minor Outlying Islands, Venezuela,
                  Virgin Islands (US), Yemen, &nbsp;Zimbabwe
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "197.0659942626953pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Evolution Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3038259506225585",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "24.56011962890625pt",
                  textIndent: "0.1103973388671875pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Cuba, Iran, North Korea, South Sudan, Sudan, Syria,
                  Sweden, &nbsp;Taiwan, Ukraine, Crimea, Venezuela, USA,
                  Curacao.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.6811370849609375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.311975860595703",
                  marginLeft: "4.3751983642578125pt",
                  marginRight: "2.6619873046875pt",
                  textIndent: "-0.132476806640625pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.132476806640625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Afghanistan, Albania, Barbados, Burkina Faso, Bulgaria,
                  Cambodia, Cayman &nbsp;Islands, Gibraltar, Haiti, Jamaica,
                  Jordan, Mali, Morocco, Myanmar, &nbsp;Nicaragua, Pakistan,
                  Panama, Philippines, Senegal, Turkey, Uganda, Yemen,
                  &nbsp;Zimbabwe.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.6061553955078125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Regulated Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3109493255615234",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "14.97857666015625pt",
                  textIndent: "-0.2980804443359375pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.2980804443359375pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Alderney, Belgium, CAN – Alberta, CAN- Atlantic Provinces (Nova
                  Scotia), &nbsp;CAN- Ontario, CAN- British Columbia, CAN- Quebec,
                  Colombia, Croatia, &nbsp;Czech Republic, Denmark, Estonia,
                  France, Georgia, Germany, Gibraltar, &nbsp;Greece, Isle of Man,
                  Italy, Latvia, Lithuania, Malta, Mexico, Netherlands,&nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "45.62005615234375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146902561187743",
                  marginLeft: "5.2363128662109375pt",
                  marginRight: "13.03436279296875pt",
                  textIndent: "0.0110321044921875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Philippines, Portugal, Romania, South Africa, Spain, Sweden,
                  Switzerland, &nbsp;United Kingdom.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "147.14990234375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  EVOplay&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.312317180633545",
                  marginLeft: "4.353118896484375pt",
                  marginRight: "5.14080810546875pt",
                  textIndent: "0.89422607421875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Algeria,
                  Australia, &nbsp;Aruba, Barbados, Burma, Bonaire, Botswana,
                  Cuba, Democratic People’s &nbsp;Republic of Korea (DPRK),
                  Democratic Republic of Congo, Eritrea, Ethiopia, &nbsp;France
                  and its dependent territories, Ghana, Iran, Iraq, Israel,
                  Jamaica, &nbsp;Jordan, Liberia, Libya, Lithuania, Mali,
                  Pakistan, Panama, Philippines, &nbsp;Rwanda, Saba, Singapore,
                  Somalia, State of Palestine, Statia, St. Maarten, &nbsp;St.
                  Eustatius, Sudan, South Sudan, Syria, The Netherlands, Tunisia,
                  Uganda, &nbsp;United States of America and its dependent
                  territories, United Kingdom, &nbsp;Venezuela, Yemen, Zimbabwe,
                  Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "204.98004150390625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Eyecon&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092574596405029",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "20.99420166015625pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Curacao, Australia,
                  Belarus, &nbsp;Israel, Philippines, Romania, Singapore, Turkey,
                  Ukraine, Italy, Portugal, &nbsp;France, Taiwan, Hong Kong.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.631134033203125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Real Dealer Studio:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "4.02740478515625pt",
                  textIndent: "-0.7286376953125pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.7286376953125pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Curacao, Germany, France, Singapore, South Africa,
                  Netherlands, &nbsp;USA, Taiwan, Hong Kong.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146906852722167",
                  marginLeft: "4.6953582763671875pt",
                  marginRight: "31.61474609375pt",
                  textIndent: "-0.1103973388671875pt",
                  marginTop: "0.461151123046875pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.1103973388671875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  + any regulated market an operator is not licensed in Gameplay
                  from &nbsp;Canada additionally restricted for Croc Chase
                  (supplier Lightning Box &nbsp;Games)&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.581146240234375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Regulated territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146906852722167",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "24.38360595703125pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "1.53631591796875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Italy, United Kingdom, Bulgaria, Colombia, Croatia, Denmark,
                  Estonia, &nbsp;Latvia, Lithuania, Ontario (Canada), Portugal,
                  Romania, Spain, Sweden, &nbsp;South Africa, Germany,
                  Switzerland, Taiwan, Hong Kong
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "226.10601043701172pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Ezugi&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3125788211822509",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "4.49322509765625pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Albania, Australia,
                  Barbados, &nbsp;Botswana, Burkina Faso, Cambodia, Canada (CAN-
                  Atlantic Provinces (Nova &nbsp;Scotia), CAN- Ontario,
                  CAN-British Colombia, CAN-Lotto Quebec), Cayman &nbsp;Islands,
                  Crimea, Cuba, Ghana, Haiti, Iran, Jamaica, Jordan, Mali, Malta,
                  &nbsp;Mauritius, Morocco, Myanmar, Nicaragua, North Korea,
                  Pakistan, Panama, &nbsp;Philippines, Senegal, South Sudan,
                  Syria, Taiwan, Uganda, Yemen, &nbsp;Zimbabwe, Belgium, Colombia,
                  Croatia, Czech Republic, Denmark, Estonia, &nbsp;France,
                  Georgia, Germany, Greece, Italy, Latvia, Lithuania, Mexico,
                  &nbsp;Netherlands, Portugal, Romania, Spain, Sudan, Sweden,
                  Switzerland, &nbsp;Venezuela, Ukraine.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.311070489883423",
                  marginLeft: "5.1479949951171875pt",
                  marginRight: "4.00372314453125pt",
                  textIndent: "0.0993499755859375pt",
                  marginTop: "0.6005859375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked to operators unless they comply with local regulations:
                  United &nbsp;Kingdom, Netherlands, Romania, South Africa,
                  Bulgaria, Hungary, Latvia for &nbsp;local players, Costa Rica
                  for local players, Spain, Denmark, Colombia, Italy,
                  &nbsp;Lithuania&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.6144790649414062pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Permanently blocked:
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "45.62005615234375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146902561187743",
                  marginLeft: "4.8388671875pt",
                  marginRight: "39.50836181640625pt",
                  textIndent: "0.408477783203125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Israel, Australia, France, Dutch West Indies, Curacao, United
                  states, &nbsp;Canada.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.199951171875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Fugaso&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, UK, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "190.58999633789062pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  GameART&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092604637145995",
                  marginLeft: "4.6401519775390625pt",
                  marginRight: "11.676513671875pt",
                  textIndent: "0.6071929931640625pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from France, Portugal, Belgium,
                  Sweden, &nbsp;Denmark, Bulgaria, Estonia, Cyprus, Israel, UK,
                  Hong Kong, Australia, New &nbsp;Zealand, United States of
                  America, Netherland&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.311976718902588",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "14.88916015625pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "0.63116455078125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  For games Dragon King, Wild Dolphin, Venetia, Lady Luck, Wolf
                  Quest, &nbsp;Explosive Reels, Gold Of Ra, Dancing Lions, Phoenix
                  Princess, Fortune &nbsp;Panda, Magic Unicorn, Ancient Gong,
                  Power Dragon, Jumpin Pot, African &nbsp;Sunset:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3119748592376708",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "6.03509521484375pt",
                  textIndent: "0.8390350341796875pt",
                  marginTop: "0.606201171875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  United States of America and its territories, American Samoa,
                  Australia, &nbsp;Brunei, Cambodia, China, Hong Kong, India,
                  Indonesia, Japan, North Korea, &nbsp;South Korea, Laos, Macau,
                  Malaysia, Mongolia, Burma, New Zealand, &nbsp;Paupa New Guinea,
                  Philippines, Singapore, Sri Lanka, Taiwan, Thailand,
                  &nbsp;Timor, Vietnam
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "60.1199951171875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Ganapati&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092583179473876",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "5.69281005859375pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from France Taiwan, United
                  Kingdom, The &nbsp;Netherlands, Aruba, Bonaire, Curaçao, Saba,
                  St. Eustatius, St. Maarten, &nbsp;Statia, USA.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "60.139984130859375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Genii&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092583179473876",
                  marginLeft: "4.8388671875pt",
                  marginRight: "34.41851806640625pt",
                  textIndent: "0.408477783203125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Australia, Bulgaria,
                  Denmark, &nbsp;Curacao, Estonia, Spain, United Kingdom, Italy,
                  Netherlands, Poland, &nbsp;Portugal, Romania, United States,
                  South Africa
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "103.56002807617188pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  GG Network&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3103439331054687",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "4.0228271484375pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afganistan, Albania.
                  Netherlands &nbsp;Antilles, Andora, American Samoa, Australia,
                  Aland Islands, Bahrain, &nbsp;Bonaire – Sant Eustatius and Saba,
                  Belize,Cuba, Curacao, Algeria, Ecuador, &nbsp;Eritrea, Spain,
                  Ephiopia, France, South Georgia and the South Sandwich
                  &nbsp;Islands, Ireland, Israel, India, Iraq, Italy, Jordan,
                  Korea – Democratic, United &nbsp;States of America and
                  Territories,
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "103.58993530273438pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  GoldenHero&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3130621910095215",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "7.16326904296875pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from The United Arab Emirates,
                  Armenia, &nbsp;Azerbaijan, Bangladesh, Brunei Darussalam, China,
                  Indonesia, Japan, &nbsp;Kyrgyzstan, Cambodia, South Korea,
                  Kazakhstan, Lao People’s Democratic &nbsp;Republic, Myanmar,
                  Mongolia, Malaysia, Nepal, New Zealand, Pakistan, &nbsp;Qatar,
                  Russia, Thailand, Tajikistan, Timor-Leste, Turkmenistan,
                  Uzbekistan, &nbsp;Viet Nam .
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "52.196067810058594pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  GoldenRace&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3145105838775635",
                  marginLeft: "4.8388671875pt",
                  marginRight: "4.0089111328125pt",
                  textIndent: "0.408477783203125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Albania,
                  Antigua and &nbsp;Barbuda, Austria Belgium, Bulgaria, Burundi,
                  Canada, Colombia, Costa Rica, &nbsp;Cuba, Cyprus, Denmark,
                  Eritrea, France and its territories, Germany,&nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "89.17999267578125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3119781494140625",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "18.30548095703125pt",
                  textIndent: "0.2980804443359375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Georgia, Greece, Guinea Bisseau, Hong Kong, Italy, Iran,Iraq,
                  Israel, &nbsp;Kahnawake, Libya, Lithuania, Macau, Macedonia,
                  Malta, Netherlands &nbsp;Antilles,Nigeria, Portugal, Romania,
                  Serbia, Somalia, South Africa, Spain, &nbsp;Sudan, Sweden,
                  Syria, the Philippines, Turkey, the United Kingdom, the
                  &nbsp;USA and its territories
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "147.030029296875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8057708740234375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Green Jade Games&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3123170375823974",
                  marginLeft: "4.3089599609375pt",
                  marginRight: "4.23553466796875pt",
                  textIndent: "-0.1987152099609375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.1987152099609375pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Albania, Argentina, Armenia, Australia, Austria, Belarus,
                  Belgium, Bulgaria, &nbsp;Canada, China, Colombia, Croatia
                  (Hrvatska), Czech Republic, Curacao, &nbsp;Denmark, Estonia,
                  France, French Guiana, Georgia, Germany, Greece,
                  &nbsp;Guadeloupe, Hong Kong S.A.R., Hungary, Iran, Ireland,
                  Italy, Kosovo, Latvia, &nbsp;Lithuania, Luxembourg, Macau
                  S.A.R., Malta, Martinique, Mexico, &nbsp;Moldova, Montenegro,
                  Netherlands, New Zealand, North Korea, Paraguay, &nbsp;Poland,
                  Portugal, Romania, Rwanda, Serbia, Singapore, Slovakia,
                  Slovenia, &nbsp;South Africa, Spain, Sweden, Switzerland,
                  Thailand, Turkey, United &nbsp;Kingdom &amp; Northern Ireland,
                  USA
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "205.10000610351562pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Habanero&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146921157836913",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "14.74749755859375pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Australia, Cyprus, Curacao,
                  France, &nbsp;Singapore, Taiwan, United States&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.581146240234375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted territories unless licensed:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146902561187743",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "32.99481201171875pt",
                  marginTop: "1.51629638671875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Belarus, Georgia, Gibraltar, Isle of Man, Malta Italy, United
                  Kingdom, &nbsp;Bulgaria, Colombia, Croatia, Denmark, Estonia,
                  Latvia, Lithuania, &nbsp;Philippines, Portugal, Romania, Spain,
                  Sweden, South Africa, The &nbsp;Netherlands,Panama&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.481170654296875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  MGA:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146921157836913",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "25.26678466796875pt",
                  textIndent: "-0.7286376953125pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.7286376953125pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Cyprus, France, Philippines, Singapore, South Africa,
                  Taiwan, &nbsp;United Kingdom, United States.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.309256887435913",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "24.3438720703125pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "0.581146240234375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted unless licensed: Italy, Bulgaria, Colombia, Croatia,
                  Denmark, &nbsp;Estonia, Latvia, Lithuania,The Netherlands,
                  Portugal, Romania, Spain, &nbsp;Sweden
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "197.06591796875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  High 5 Games&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.303827381134033",
                  marginLeft: "4.353118896484375pt",
                  marginRight: "19.49285888671875pt",
                  textIndent: "-0.89422607421875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.89422607421875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Jurisdictions where Licensed Customers must not allow
                  participation on &nbsp;Relax’s own&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3133339881896973",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "9.88031005859375pt",
                  textIndent: "0.408477783203125pt",
                  marginTop: "0.6811065673828125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  content, “Powered By Relax" and/or “SilverBullet" content for
                  real money &nbsp;due to FATF AML restrictions: Afghanistan,
                  Algeria, Angola, Antigua &amp; &nbsp;Barbuda, Cambodia, China,
                  Cuba, Cyprus, Guyana, Hong Kong, Indonesia, &nbsp;Iran, Iraq,
                  Israel, Kahnawake, Kuwait, Libya, Macau, Myanmar, Namibia,
                  &nbsp;Netherlands Antilles, North Korea, Pakistan, Papua New
                  Guinea, Sudan, &nbsp;Syria, The Philippines, Turkey, Uganda,
                  United States of America and its &nbsp;Territories, Yemen&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3109490394592285",
                  marginLeft: "4.353118896484375pt",
                  marginRight: "3.21990966796875pt",
                  textIndent: "-0.45263671875pt",
                  marginTop: "0.593658447265625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.45263671875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Jurisdictions where Relax’s Licensed Customers must not offer
                  Relax’s own &nbsp;content, “Powered By Relax" and/or
                  “SilverBullet" content for real money &nbsp;unless such Licensed
                  Customer holds a local online gaming license from the
                  &nbsp;competent licensing authority of the specific
                  jurisdiction: Belgium,&nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "60.1400146484375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146929740905762",
                  marginLeft: "5.03759765625pt",
                  marginRight: "27.72479248046875pt",
                  textIndent: "0.209747314453125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Denmark, Estonia, France and its Territories, Germany, Italy,
                  Latvia, &nbsp;Republic of Serbia, Romania, Spain, Sweden, United
                  States of America &nbsp;(New Jersey, Pennsylvania), UK
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.5999755859375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  HoGaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "11.17987060546875pt",
                  textIndent: "-0.132476806640625pt",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.132476806640625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  South Korea, North Korea, Hong Kong, Macau, Philippines, U.S.A,
                  Canada, &nbsp;Curacao, United Kingdom &amp; France.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.20001220703125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Igrosoft&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "132.62997436523438pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  ISoftBet&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.311976146697998",
                  marginLeft: "4.485595703125pt",
                  marginRight: "7.26043701171875pt",
                  textIndent: "0.761749267578125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Antigua and
                  Barbuda, &nbsp;Belgium (Market exclusivity), Cuba, Curacao,
                  Cyprus, Great Britain (unless &nbsp;holding valid UK license),
                  Greece (unless holding valid license), Hong Kong, &nbsp;Iran,
                  Iraq, Israel, Jordan, North Korea, Libya, Macau, Malta (unless
                  holding &nbsp;MGA license), Netherlands Antilles, Ontario
                  (Canada) (unless holding valid &nbsp;gaming license), The
                  Philippines (unless holding valid license), Romania
                  &nbsp;(unless holding valid license), Sudan, Singapore, Syria,
                  United States, &nbsp;Yemen.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.60003662109375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Kiron&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3038245201110839",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "6.7637939453125pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, China, Italy,
                  Australia, &nbsp;Singapore, Sri Lanka, South Africa, Dubai,
                  Iraq, Saudi Arabia, Yemen, Oman.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.100006103515625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Leander&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, China, Curacao, France,
                  Israel
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "89.16000366210938pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  LiveGames&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3119738578796387",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "3.14263916015625pt",
                  textIndent: "0.54095458984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA and its territories and
                  &nbsp;possessions, including the U.S. Virgin Islands, the U.S.
                  Minor Outlying &nbsp;Islands, Guam, Puerto Rico, Mariana
                  Islands, American Samoa, Aruba, &nbsp;Georgia, United Kingdom,
                  Bonaire, Curacao, France, the Netherlands, Saba,
                  &nbsp;Singapore, Statia, St. Maarten, Ukraine and Iran.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.079986572265625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Lucky Streak&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Israel, Latvia, USA, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.74993896484375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Markor&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146925449371338",
                  marginLeft: "5.2363128662109375pt",
                  marginRight: "15.14312744140625pt",
                  textIndent: "0.0110321044921875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Israel, North Korea, Iran,
                  Australia, &nbsp;USA, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.080062866210938pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Medialive&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "center",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, France, Denmark,
                  Curacao, Italy
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "95.63600158691406pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  MicroGaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076751708984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  All Content:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146925449371338",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "26.6246337890625pt",
                  textIndent: "0.1103973388671875pt",
                  marginTop: "1.6363372802734375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Curacao, France, Singapore, South Africa,
                  Netherlands, USA, &nbsp;Taiwan, Hong Kong.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.6953582763671875pt",
                  marginTop: "0.4611358642578125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  + any regulated market an operator is not licensed in&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Progressives:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076751708984375pt",
                  marginTop: "1.6323165893554688pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Curacao, France, Israel, Singapore, South Africa,
                  Turkey, USA,
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "335.4499816894531pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.39727783203125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Taiwan, Hong Kong.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.6953582763671875pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  + any regulated market an operator is not licensed in&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "1.6363525390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Branded:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "21.83319091796875pt",
                  textIndent: "0.1103973388671875pt",
                  marginTop: "1.516357421875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Curacao, France, Israel, Singapore, South Africa,
                  Turkey, USA, &nbsp;Taiwan, Hong Kong.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.6953582763671875pt",
                  marginTop: "0.58111572265625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  + any regulated market an operator is not licensed in&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "1.6363525390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  BattleStar Galactica:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "24.59332275390625pt",
                  textIndent: "-0.18768310546875pt",
                  marginTop: "1.51629638671875pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.18768310546875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Burma, Curacao, France, Iran, Israel, Libya, North
                  Korea, &nbsp;Singapore, South Africa, Sudan, Syria, Turkey, USA,
                  Taiwan, Hong Kong. + any regulated market an operator is not
                  licensed in&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.5811767578125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  PlayboyGames:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "6.07342529296875pt",
                  textIndent: "-0.18768310546875pt",
                  marginTop: "1.5462646484375pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.18768310546875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Burma, Canada, Curacao, France, Iran, Israel, Libya,
                  North Korea, &nbsp;Singapore, South Africa, Sudan, Syria,
                  Turkey, USA, Taiwan, Hong Kong. + any regulated market an
                  operator is not licensed in&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.58111572265625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Rabcat Games&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076751708984375pt",
                  marginTop: "1.51629638671875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Austria, Curacao, USA&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.6953582763671875pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  + any regulated market an operator is not licensed in&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  BTG:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8388671875pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Canada, Curacao, USA, Singapore, Taiwan, Hong Kong.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.6953582763671875pt",
                  marginTop: "1.51629638671875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  + any regulated market an operator is not licensed in&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146921157836913",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "19.22784423828125pt",
                  textIndent: "-0.7396697998046875pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.7396697998046875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Israel, Philippines, Romania, Singapore, Turkey,
                  Ukraine, Italy, &nbsp;Portugal, France.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.740020751953125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.14801025390625pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  mr.Slotty&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "40.34014892578125pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from united States of America and
                  &nbsp;Territories, Lithuanian markets, Curacao.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "234.0300064086914pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  NetEnt&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "24.56011962890625pt",
                  textIndent: "0.1103973388671875pt",
                  marginTop: "1.51629638671875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Cuba, Iran, North Korea, South Sudan, Sudan, Syria,
                  Sweden, &nbsp;Taiwan, Ukraine, Crimea, Venezuela, USA,
                  Curacao.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.5811767578125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3110703468322753",
                  marginLeft: "4.3751983642578125pt",
                  marginRight: "2.75628662109375pt",
                  textIndent: "-0.132476806640625pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.132476806640625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Afghanistan, Albania, Barbados, Burkina Faso, Bulgaria,
                  Cambodia, Cayman &nbsp;Islands, Gibraltar, Haiti, Jamaica,
                  Jordan, Mali, Morocco, Myanmar, &nbsp;Nicaragua, Pakistan,
                  Panama, Philippines, Senegal, Turkey, Uganda, Yemen,
                  &nbsp;Zimbabwe.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.6444854736328125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Regulated Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3103457927703857",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "13.03436279296875pt",
                  textIndent: "-0.2980804443359375pt",
                  marginTop: "1.6363372802734375pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.2980804443359375pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Alderney, Belgium, CAN – Alberta, CAN- Atlantic Provinces (Nova
                  Scotia), &nbsp;CAN- Ontario, CAN- British Columbia, CAN- Quebec,
                  Colombia, Croatia, &nbsp;Czech Republic, Denmark, Estonia,
                  France, Georgia, Germany, Gibraltar, &nbsp;Greece, Isle of Man,
                  Italy, Latvia, Lithuania, Malta, Mexico, Netherlands,
                  &nbsp;Philippines, Portugal, Romania, South Africa, Spain,
                  Sweden, Switzerland, &nbsp;United Kingdom.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "517.4200134277344pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  NoLimit city&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "11.87530517578125pt",
                  textIndent: "-0.7396697998046875pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.7396697998046875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Afghanistan, Albania, Algeria, Australia, The Bahamas, Bahrain,
                  Barbados, &nbsp;Bolivia, Botswana, Brunei,&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.312316608428955",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "4.35687255859375pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0.461181640625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Burkina Faso, Burundi, Cambodia, Cayman Islands, Democratic
                  Republic of &nbsp;Congo, Cuba, Curacao, Cyprus, Egypt, Fiji,
                  Gambia, Ghana, Guatemala, &nbsp;Haiti, Iran, Iraq, Israel,
                  Jamaica, Jordan, Kazakhstan, North Korea, Kuwait, &nbsp;Lebanon,
                  Libya, Malaysia, Maldives, Mali, Mauritania, Mauritius,
                  Mongolia, &nbsp;Morocco, Myanmar, Nepal, Nicaragua, Oman,
                  Pakistan, Panama, Papua &nbsp;New Guinea, Puerto Rico, Qatar,
                  Russia, Saudi Arabia, Senegal, Somalia, &nbsp;Sudan, South
                  Sudan, Syria, Taiwan, Turkmenistan, Tuvalu, Uganda, United
                  &nbsp;Arab Emirates, US Minor Outlying Islands, US Virgin
                  Islands, Vatican, &nbsp;Venezuela, Yemen, Zimbabwe.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.60302734375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Local license needed:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3115856170654296",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "2.62384033203125pt",
                  marginTop: "1.6363525390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Antigua &amp; Barbuda, Armenia, Austria, Belgium, Bulgaria,
                  Canada, Colombia, &nbsp;Curacao, Czech Republic, Denmark,
                  Estonia, Ethiopia, Falkland Islands, &nbsp;Finland, France,
                  Georgia, Germany, Greece, Guadeloupe, Hungary, Isle of
                  &nbsp;Man, Italy, Latvia, Lithuania, Macau, Malta, Martinique,
                  Mayotte, Mexico, &nbsp;Netherlands, New Caledonia, Nigeria,
                  Papua New Guinea, Reunion Island, &nbsp;Romania, Saint
                  Barthelemy, Saint Martin, Serbia, Slovakia, Spain, Sri Lanka,
                  &nbsp;Sweden, Switzerland, Ukraine, United Kingdom, United
                  States of America, &nbsp;Vanuatu, Wallis and Futuna.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "4.8167877197265625pt",
                  marginRight: "3.27496337890625pt",
                  textIndent: "0.0220794677734375pt",
                  marginTop: "0.6097412109375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Countries that have no restrictions in place, but certain
                  territories will need &nbsp;a local licence:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8388671875pt",
                  marginTop: "0.481170654296875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Canada-Ontario&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "4.8167877197265625pt",
                  marginRight: "18.22247314453125pt",
                  textIndent: "0.0220794677734375pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Countries which have full restrictions in place, but certain
                  territories are &nbsp;allowed with local licence:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.303827381134033",
                  marginLeft: "5.2363128662109375pt",
                  marginRight: "16.68865966796875pt",
                  textIndent: "-0.0110321044921875pt",
                  marginTop: "0.5811767578125pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.0110321044921875pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  United States of America: Connecticut, Delaware, New Jersey,
                  Michigan, &nbsp;Nevada, Pennsylvania, West Virginia&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076751708984375pt",
                  marginTop: "8.7210693359375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Additional restrictions for Malta:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "4.706390380859375pt",
                  marginRight: "40.02789306640625pt",
                  textIndent: "0.132476806640625pt",
                  marginTop: "1.516326904296875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  China, Hong Kong, Indonesia, South Korea, The Philippines,
                  Poland, &nbsp;Singapore, South Africa, Turkey,&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076751708984375pt",
                  marginTop: "0.5811920166015625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Additional local license (Malta):&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8388671875pt",
                  marginTop: "1.6363372802734375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Canada-Ontario
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.199996948242188pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.8499298095703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Oriental&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "95.51600646972656pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Pariplay&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3102731227874755",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "2.41400146484375pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Albania,
                  Algeria, Angola, &nbsp;Australia, The Bahamas, Barbados,
                  Botswana, Cambodia, Congo, Curacao, &nbsp;Cyprus, Ecuador,
                  France and its territories, Ghana, Guyana, Hong Kong,
                  &nbsp;Iran, Iraq, Israel, Jamaica, Latvia, Lithuania, Mauritius,
                  Mongolia, Myanmar, &nbsp;Nicaragua, North Korea, Panama,
                  Philippines, Saudi Arabia, Singapore, &nbsp;South Africa, South
                  Korea, Sudan, Taiwan, United States of America,&nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "74.6600341796875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076751708984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Vatican City, Zimbabwe&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092633247375487",
                  marginLeft: "5.2363128662109375pt",
                  marginRight: "10.8095703125pt",
                  textIndent: "0.0110321044921875pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted Jurisdictions: Belgium, Bulgaria, Czech Republic,
                  Colombia, &nbsp;Denmark, Estonia, Italy, Malta, Portugal,
                  Romania, Russia, Spain, Sweden, &nbsp;United Kingdom, Pakistan,
                  Poland, South, Sudan, Syria, Uganda, Yemen
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.5999755859375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Patagonia&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "23.11395263671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Aruba, Bonaire, Curacao,
                  France, &nbsp;Netherlands, Saba, Singapore, Statia, St Maarten,
                  USA
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.5999755859375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  PG soft&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "35.9534912109375pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Malaysia, Curacao,
                  Singapore, &nbsp;Taiwan and USA
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.750030517578125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Platipus&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "8.09954833984375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, it’s territories and
                  possessions, &nbsp;Israel and Iran, Curacao.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.600006103515625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Playson + Direct&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3038245201110839",
                  marginLeft: "4.805755615234375pt",
                  marginRight: "32.11077880859375pt",
                  textIndent: "0.44158935546875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA; Australia; Israel,
                  Curacao, &nbsp;Georgia, Ukraine, Lithuania, United Kingdom.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "161.53997802734375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  PragmaticPlay&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked countries:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3038245201110839",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "7.76837158203125pt",
                  textIndent: "-0.3311920166015625pt",
                  marginTop: "1.636322021484375pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.3311920166015625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, France, Israel, North Korea, Philippines, Singapore,
                  Taiwan, USA, &nbsp;Curacao, Iran.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146921157836913",
                  marginLeft: "5.1479949951171875pt",
                  marginRight: "16.37469482421875pt",
                  textIndent: "0.0993499755859375pt",
                  marginTop: "0.681121826171875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted countries (need to be sure that the operator holds
                  the license &nbsp;before enabling traffic):&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092583179473876",
                  marginLeft: "4.84991455078125pt",
                  marginRight: "12.11822509765625pt",
                  textIndent: "0.397430419921875pt",
                  marginTop: "0.60113525390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Bahamas, Bulgaria, Denmark, Gibraltar, Great Britain, Italy,
                  Lithuania, &nbsp;Ontario (Canada) Portugal, Romania, Serbia,
                  South Africa, Spain, Sweden, &nbsp;Ukraine.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146921157836913",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "10.25421142578125pt",
                  marginTop: "0.631134033203125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked countries for Peaky Blinders: UK, USA, Hong Kong,
                  Turkey, France &nbsp;Belgium, Spain, Singapore, Iran, Syria,
                  North Korea, Sudan
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "147.15000915527344pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Push Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3123148918151855",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "16.15313720703125pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Marshall
                  Islands, &nbsp;American Samoa, Myanmar, Australia, Macao,
                  Nigeria, Cuba, Curacao, &nbsp;North Korea, Poland, Egypt, Puerto
                  Rico, Eritrea, Qatar, French Southern &nbsp;Territories, France,
                  French Polynesia, French Guiana, Singapore, South &nbsp;Africa,
                  Guadaloupe, Guam, Sudan, Holy See (Vatican City), Hong Kong,
                  &nbsp;Taiwan, Iran, Iraq, United States of America, Israel, US
                  Minor Outlying &nbsp;Islands, US Virgin Islands, Kuwait, Yemen,
                  Malaysia, Zimbabwe. Restricted unless under a local license:
                  Belgium, Denmark, Estonia, &nbsp;Greenland, Sweden, Spain,
                  United Kingdom, Italy
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.080001831054688pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Realtime Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Curacao
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "60.1400146484375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Red Rake Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146929740905762",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "11.17059326171875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from US, Curacao, Israel,
                  Portugal, Spain, &nbsp;France, Croatia, Denmark, Romania,
                  Belgium, Australia, Estonia, Latvia, &nbsp;Lithuania,
                  Netherlands, Poland and Czech Republic and UK
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "234.02999877929688pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Red Tiger Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Blocked Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146956920623778",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "24.5611572265625pt",
                  textIndent: "0.1103973388671875pt",
                  marginTop: "1.6363525390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Australia, Cuba, Iran, North Korea, South Sudan, Sudan, Syria,
                  Sweden, &nbsp;Taiwan, Ukraine, Crimea, Venezuela, USA,
                  Curacao.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.461181640625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3119785785675049",
                  marginLeft: "4.3751983642578125pt",
                  marginRight: "2.75628662109375pt",
                  textIndent: "-0.132476806640625pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.132476806640625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Afghanistan, Albania, Barbados, Burkina Faso, Bulgaria,
                  Cambodia, Cayman &nbsp;Islands, Gibraltar, Haiti, Jamaica,
                  Jordan, Mali, Morocco, Myanmar, &nbsp;Nicaragua, Pakistan,
                  Panama, Philippines, Senegal, Turkey, Uganda, Yemen,
                  &nbsp;Zimbabwe.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.6060791015625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Regulated Territories:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.312517738342285",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "13.03436279296875pt",
                  textIndent: "-0.2980804443359375pt",
                  marginTop: "1.6363525390625pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.2980804443359375pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Alderney, Belgium, CAN – Alberta, CAN- Atlantic Provinces (Nova
                  Scotia), &nbsp;CAN- Ontario, CAN- British Columbia, CAN- Quebec,
                  Colombia, Croatia, &nbsp;Czech Republic, Denmark, Estonia,
                  France, Georgia, Germany, Gibraltar, &nbsp;Greece, Isle of Man,
                  Italy, Latvia, Lithuania, Malta, Mexico, Netherlands,
                  &nbsp;Philippines, Portugal, Romania, South Africa, Spain,
                  Sweden, Switzerland, &nbsp;United Kingdom.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "342.0459976196289pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Relax Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3122000217437744",
                  marginLeft: "4.353118896484375pt",
                  marginRight: "1.69647216796875pt",
                  textIndent: "0.89422607421875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Algeria,
                  Pakistan, &nbsp;Antigua &amp; Barbuda, Panama, Australia, Papua
                  New Guinea, Bahamas, Sudan, Bahrain, Qatar, Curacao, Barbados,
                  Syria, Botswana, &nbsp;Taiwan, Cambodia, The Philippines, China,
                  Turkey, Cuba, Denmark, France, &nbsp;Kahnawake, Uganda, Cyprus,
                  United Arab Emirates, Egypt, Vanuatu, Ghana, &nbsp;Vatican, Hong
                  Kong, Yemen, Indonesia, Zimbabwe, Iran, Iraq, Israel,
                  &nbsp;Jamaica, Jordan, Kuwait, Lebanon, Libya, Macau, Malaysia,
                  Maldives, &nbsp;Mauritius, Mauritania, Mongolia, Myanmar,
                  Nicaragua, Nigeria, North &nbsp;Korea, Oman, USA.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0.604095458984375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Regulated:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092591762542725",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "13.25518798828125pt",
                  marginTop: "1.63629150390625pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Belgium, Denmark, Estonia, France and its Territories, Georgia,
                  Germany, &nbsp;Italy, Latvia, Lithuania, Republic of Serbia,
                  Romania, Spain, Sweden, The &nbsp;Netherlands, United Kingdom,
                  Canada and Oriental&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.706390380859375pt",
                  marginTop: "0.6311492919921875pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Supplier Silverback:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3092594623565674",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "2.00543212890625pt",
                  textIndent: "-0.3311920166015625pt",
                  marginTop: "1.6663360595703125pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.3311920166015625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Albania, Angola, Azerbaijan, Bangladesh, Bahrain, Barbados,
                  Belarus, Benin, &nbsp;Brunei Darussalam, Bulgaria, Burkina Faso,
                  Burundi, Cambodia, Cameroon, &nbsp;Canada, Cabo Verde, Central
                  African Republic, Comoros, Cook Islands, Côte &nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3124460697174072",
                  marginLeft: "4.8388671875pt",
                  marginRight: "10.33294677734375pt",
                  textIndent: "-0.408477783203125pt",
                  marginTop: "0.6311492919921875pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.408477783203125pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  d’Ivoire, Curacao, Cyprus, Czechia, Djibouti, Egypt, Eritrea,
                  Ethiopia, Faroe &nbsp;Islands, Finland, Gambia, Ghana, Greece,
                  Greenland, Grenada , India &nbsp;Indonesia, Kazakhstan, Kenya,
                  Kyrgyzstan, Lao People’s Democratic &nbsp;Republic, Lebanon,
                  Liberia, Libya, Macao, Malaysia, Mali,Marshall Islands,
                  &nbsp;Mauritania, Moldova, Monaco, Morocco, Mongolia, Namibia,
                  Nauru, &nbsp;Nicaragua, Niger (the), Nigeria, Norway, Oman,
                  Palau, Palestine, State of,&nbsp;
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "118.10003662109375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <br />
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.312882947921753",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "2.44708251953125pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Poland, Portugal, Puerto Rico, Qatar, Russian Federation,
                  Rwanda, Samoa, &nbsp;Sao Tome and Principe,Saudi Arabia, Sierra
                  Leone, Solomon Islands, &nbsp;Somalia, South Africa, Korea, Sri
                  Lanka, Suriname, Switzerland, Tajikistan, &nbsp;Thailand, Togo,
                  Tonga, Trinidad and Tobago, Tunisia, Turkey, Turkmenistan,
                  &nbsp;Uganda, Ukraine, United Arab Emirates, Taiwan, Tanzania,
                  United Republic &nbsp;of, United States of America, Virgin
                  Islands, Zambia, Viet Nam, Vanuatu, &nbsp;Uzbekistan.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "103.58993530273438pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247367858886719pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Revolver Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3130643367767334",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "2.41400146484375pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Albania,
                  Algeria, Angola, &nbsp;Australia, Belgium, Bulgaria, Czech
                  Republic,Colombia, Curacao, Denmark, Ecuador, France and its
                  territories, Guyana, Hong Kong, Iran, Iraq, Israel, &nbsp;Italy,
                  Lithuania, North Korea, Philippines, Poland, Portugal, Puerto
                  Ricco, &nbsp;Romania,Saudi Arabia, Singapore, Spain, United
                  States of America, Vatican &nbsp;City
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.72003173828125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.7064056396484375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  SA Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "4.805755615234375pt",
                  marginRight: "71.40676879882812pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Games Access (Restricted): Bulgaria, Curacao, Israel and USA
                  Game Access (Blocked): China, Hong Kong, Macau, Korea
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.079986572265625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.7064056396484375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Spadegaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Taiwan, Philippines, USA,
                  Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.199981689453125pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.7064056396484375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Spigo&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.100006103515625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.7064056396484375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Spinmatic&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from France, Netherlands, USA,
                  Curacao
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.600006103515625pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.7064056396484375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Spinomenal&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.314689254760742",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "23.53350830078125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, ISR, Australia, UK,
                  Armenia, &nbsp;Ecuador and France, Curacao.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "176.0700225830078pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.7064056396484375pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Stakelogic&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.311974573135376",
                  marginLeft: "4.8388671875pt",
                  marginRight: "24.129638671875pt",
                  textIndent: "0.408477783203125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Algeria, Libya, Afghanistan,
                  &nbsp;Myanmar, Antigua &amp; Barbuda, North Korea, Australia,
                  Pakistan, Cuba, &nbsp;Curacao, Panama, Guyana, Papua New Guinea,
                  Iran, Sudan, Iraq, Syria, &nbsp;Israel, United States of America
                  and its Territories, Kahnawake,Yemen, &nbsp;Kuwait,
                  Zimbabwe.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3160498142242432",
                  marginLeft: "4.353118896484375pt",
                  marginRight: "4.8294677734375pt",
                  textIndent: "-0.16558837890625pt",
                  marginTop: "0.606170654296875pt",
                  marginBottom: "0pt",
                  padding: "0pt 0pt 0pt 0.16558837890625pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Jurisdictions where Licensed Clients must not offer Stakelogic’s
                  own games &nbsp;for real money play without holding the relevant
                  Gaming Licence from he &nbsp;competent licensing authority for
                  the specific jurisdiction:&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146925449371338",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "12.13446044921875pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0.4486846923828125pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Belgium, Portugal, Denmark, Romania, Estonia, Spain, France and
                  its &nbsp;Territories, Sweden, Italy, Switzerland, Latvia,
                  Lithuania, United Kingdom, &nbsp;Malta, Ukraine, the
                  Netherlands.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.71600341796875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.397285461425781pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  TomHorn&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.303826093673706",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "39.17718505859375pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from United States of America and
                  &nbsp;Territories, and Israel, Curacao.
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <div dir="ltr" style={{ marginLeft: "0pt" }} align="left">
      <table style={{ border: "none", borderCollapse: "collapse" }}>
        <tbody>
          <tr style={{ height: "45.62005615234375pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5076904296875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Vivo&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3146902561187743",
                  marginLeft: "4.8388671875pt",
                  marginRight: "2.4140625pt",
                  textIndent: "0.408477783203125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Israel, Costa Rica and
                  Argentina, &nbsp;Curacao, Bulgaria, Iran, Colombia : COP in Cali
                  tables.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "31.199951171875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.651206970214844pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  WAC&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "5.247344970703125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from USA, Curacao, The
                  Netherlands
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "103.59002685546875pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.651206970214844pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Wazdan&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3125205993652342",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "9.30303955078125pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afganistan, Antigua and
                  Barbuda, &nbsp;Armenia, Australia, Belgium, Bulgaria, China,
                  Cuba, Cyprus, Denmark, &nbsp;Greece, France and its territories,
                  Hong Kong, Italy, Iran, Iraq, Kahnawake, &nbsp;Libya, Macau,
                  Malasia, the Netherlands, the former Netherlands Antilles,
                  &nbsp;Poland, Portugal, Republic of Serbia, Singapore, Spain,
                  Sudan, Syria, the &nbsp;Philippines, Turkey, the USA and its
                  territories, UK, Curacao, Switzerland.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "103.55996704101562pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.651206970214844pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Worldmatch&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3125184535980223",
                  marginLeft: "4.5076751708984375pt",
                  marginRight: "2.41400146484375pt",
                  textIndent: "0.7396697998046875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Afghanistan, Albania,
                  Algeria, Angola, &nbsp;Australia, Congo, Cyprus, Ecuador, France
                  and its territories, Guyana, Hong &nbsp;Kong, Iran, Iraq,
                  Israel, Latvia, Lithuania, North Korea, Philippines, Poland,
                  &nbsp;Saudi Arabia, Singapore, South Africa, South Korea, Sudan,
                  Taiwan, United &nbsp;States of America, Vatican City, Spain,
                  Sweden, United Kingdom, Italy, &nbsp;Germany
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "234.05001831054688pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.4856109619140625pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Yggdrasil&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3124769687652587",
                  marginLeft: "4.39727783203125pt",
                  marginRight: "6.84112548828125pt",
                  textIndent: "0.850067138671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted: Afghanistan, American Samoa, Bahamas, Botswana,
                  Cambodia, &nbsp;Congo, Democratic People’s Republic of Korea
                  (DPRK), Egypt, Eritrea, &nbsp;Ethiopia, French Guyana, Ghana,
                  Guam, Guinea Bissau, Holy See (Vatican &nbsp;City), Iran, Iraq,
                  Kuwait, Lebanon, Libya, Malaysia, Marshall Islands,
                  &nbsp;Martinique (.FR), Nicaragua, North Korea, Occupied
                  Palestinian Territory, &nbsp;Pakistan, Panama, Poland, Puerto
                  Rico, Qatar, Reunion (.FR), Saint &nbsp;Barthelemy (.FR), Saint
                  Martin (.FR), Saint Pierre and Miquelon (.FR), &nbsp;Singapore,
                  Somalia, South Africa, South Sudan, Sri Lanka, Sudan, Syria,
                  &nbsp;Taiwan, Trinidad &amp; Tobago, Tunisia, USA, US Minor
                  Outlying Islands, &nbsp;Venezuela, Virgin Islands (U.S.), Yemen,
                  Zimbabwe.&nbsp;
                </span>
              </p>
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3119752883911133",
                  marginLeft: "4.353118896484375pt",
                  marginRight: "21.62359619140625pt",
                  textIndent: "0.89422607421875pt",
                  marginTop: "0.60150146484375pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Restricted unless under a local licence: Alderney, Australia,
                  Belgium, &nbsp;Bulgaria, Czech Republic, Croatia, Cyprus,
                  Denmark, Estonia, France, &nbsp;Gibraltar, Greenland (.DK),
                  Greece, Guadaloupe, Isle of Man (.FR), Italy, &nbsp;Jersey,
                  Lithuania, Malta, Portugal, Romania, Slovakia, Spain, Sweden,
                  &nbsp;Switzerland, The Netherlands, United Kingdom.
                </span>
              </p>
            </td>
          </tr>
          <tr style={{ height: "45.71998596191406pt" }}>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginLeft: "4.5739288330078125pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  XPro Gaming&nbsp;
                </span>
              </p>
            </td>
            <td
              style={{
                borderLeft: "solid #000000 1pt",
                borderRight: "solid #000000 1pt",
                borderBottom: "solid #000000 1pt",
                borderTop: "solid #000000 1pt",
                verticalAlign: "top",
                padding: "5pt 5pt 5pt 5pt",
                overflow: "hidden",
                overflowWrap: "break-word"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.303826379776001",
                  marginLeft: "5.247344970703125pt",
                  marginRight: "22.35223388671875pt",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11.039999961853027pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  No Customers shall be accepted from Israel, Macedonia, USA,
                  Bulgaria, &nbsp;North Macedonia, South Korea
                </span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.07727813720703125pt",
        marginRight: "33.283935546875pt",
        textIndent: "-0.099365234375pt",
        marginTop: "0pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.099365234375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        The list of game providers and territories, where from the Customers are
        not accepts may be amended &nbsp;time from time.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "8.501152038574219pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        7. USERNAME, PASSWORD, PIN and CUSTOMER INFORMATION
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.312882947921753",
        marginLeft: "0.19872283935546875pt",
        marginRight: "26.9248046875pt",
        textIndent: "0.33119964599609375pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        7.1 After opening Your Account, You must take all reasonable steps to
        avoid disclosing (whether &nbsp;deliberately or accidentally) Your
        username, password and/or account number to anyone else, including
        &nbsp;(where practicable) ensuring that up-to-date security software is
        downloaded onto Your Access Device. 7.2 All transactions made where Your
        username and password and/or account number have been &nbsp;entered
        correctly will be regarded as valid, whether or not authorised by You, and
        we shall not be liable &nbsp;for any claims in the event that You disclose
        Your username, password or account number to anyone &nbsp;else (whether
        deliberately or accidentally).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.44159698486328125pt",
        marginRight: "37.0927734375pt",
        textIndent: "0.08832550048828125pt",
        marginTop: "0.47784423828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        7.3 If You have lost or forgotten Your Account details, or have a reason
        to believe that such details are &nbsp;known to an unauthorised third
        party, please contact us immediately for a replacement through
        &nbsp;<a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a>, details of which can be found in the
        Contact Us.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.33119964599609375pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8. DEPOSITS, WITHDRAWALS AND REFUNDS FROM YOUR ACCOUNT&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.310344934463501",
        marginLeft: "0.38639068603515625pt",
        marginRight: "31.6942138671875pt",
        textIndent: "-0.055206298828125pt",
        marginTop: "9.706298828125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.055206298828125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.1 If You wish to participate in the Services, You must deposit monies
        into Your Account from an &nbsp;account or source of which You are the
        account holder. Such monies may (subject to paragraph 5) then &nbsp;be
        used by You to place bets or play games. If You use a payment method in
        respect of which You are &nbsp;not the account holder, we reserve the
        right to treat any deposit into the Account as being invalid (and
        &nbsp;any winnings arising from such deposit as void) pending the
        satisfactory completion of all relevant &nbsp;Checks.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119738578796387",
        marginLeft: "0.28704071044921875pt",
        marginRight: "32.1993408203125pt",
        textIndent: "0.044158935546875pt",
        marginTop: "0.62115478515625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Withdrawals (including – Refunds) shall be provided within 7 (seven) days
        time after completion of KYC &nbsp;and approval of Your Account. Please
        note, that Refunds are performed only if there was no game &nbsp;activity
        on Your account after deposit of respective amount of monies that are
        reclaimed by you as &nbsp;Refund. All other withdrawals from Your Account
        will be considered as pay-out of your winnings &nbsp;generated as a result
        of use of the Services.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119738578796387",
        marginLeft: "0.44159698486328125pt",
        marginRight: "26.09674072265625pt",
        textIndent: "0.4857635498046875pt",
        marginTop: "0.62615966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        In order to request a Refund or any other withdrawal, please contact our
        support by email to &nbsp;<a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a> and provide all
        documents as requested by the support in order to &nbsp;complete Your KYC.
        Should You require Our contact details, please open section “Contact Us".
        List of the &nbsp;documents required to complete Your KYC procedure shall
        be denoted in the email sent to You by our &nbsp;support.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092583179473876",
        marginLeft: "0.28704071044921875pt",
        marginRight: "32.967529296875pt",
        textIndent: "0.64031982421875pt",
        marginTop: "0.60614013671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Please note, that Refunds may be requested not later than 7 (seven) days
        after deposit of respective &nbsp;means to Your Account. After this term
        all withdrawals shall be considered as pay-out of your winnings
        &nbsp;generated a result of use of the Services.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3123154640197754",
        marginLeft: "0.1656036376953125pt",
        marginRight: "28.5809326171875pt",
        textIndent: "0.27599334716796875pt",
        marginTop: "0.631134033203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.2 Withdrawals shall be provided within 7 (seven) days time after
        completion of KYC and approval of &nbsp;Your Account. For the avoidance of
        doubt Your Account shall not be used by You as a bank account and,
        &nbsp;should we become aware of deposits into and withdrawals (including
        charge-back) from Your Account &nbsp;without commensurate betting or
        gaming activity, we reserve the right to deduct an administration
        &nbsp;charge (whether or not we close or suspend the account). Monies
        deposited with us in Your Account &nbsp;shall not attract interest. The
        time period, when you can expect your withdrawal at your personal bank
        &nbsp;account, depends on operation of respective payment service provider
        or bank, and is beyond of our &nbsp;control. For avoidance of doubt upon
        your request we can confirm pay-out of the monies requested by &nbsp;You,
        should that be necessary.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.310948896408081",
        marginLeft: "0.1656036376953125pt",
        marginRight: "28.45928955078125pt",
        textIndent: "0.27599334716796875pt",
        marginTop: "0.60302734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.3 To the extent required by Your local law or tax or other authorities
        You are responsible for reporting &nbsp;Your winnings and losses arising
        from the Services as well as payment of any and all relevant taxes. 8.4
        You can set a deposit limit on Your Account in any one day. For details of
        how to set up a deposit &nbsp;limit please contact
        <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a>. Any confirmed reductions to your deposit limit
        will&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.8280029296875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        be of immediate effect.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092633247375487",
        marginLeft: "0.17664337158203125pt",
        marginRight: "27.5211181640625pt",
        textIndent: "0.26495361328125pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.5 Subject to terms of Closure of Your Account or any other terms set out
        in these Terms and &nbsp;Conditions or any amendments thereof, You may
        request withdrawal of funds from Your Account at any &nbsp;time provided
        that:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.44159698486328125pt",
        marginRight: "61.70831298828125pt",
        textIndent: "-0.04416656494140625pt",
        marginTop: "0.631103515625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.04416656494140625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.5.1 all payments made into Your Account have been confirmed as cleared
        and none have been &nbsp;charged-back, reversed or otherwise
        cancelled;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.44159698486328125pt",
        marginRight: "36.82763671875pt",
        textIndent: "-0.04416656494140625pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.04416656494140625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.5.2 any Checks referred to Verification of Your Identity; Money
        Laundering Requirements have been &nbsp;completed by us to our
        satisfaction; and&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.44159698486328125pt",
        marginRight: "29.00238037109375pt",
        textIndent: "-0.055206298828125pt",
        marginTop: "0.68109130859375pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.055206298828125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.5.3 You have complied with any other relevant withdrawal conditions
        affecting Your Account (e.g. any &nbsp;applicable Bonus Terms).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119785785675049",
        marginLeft: "0.19872283935546875pt",
        marginRight: "26.1409912109375pt",
        textIndent: "0.2428741455078125pt",
        marginTop: "0.58111572265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.6 On any withdrawal approved by us, provided that You give us sufficient
        information as to how the &nbsp;funds should be transferred to You, we
        will return the relevant funds to You (less charges incurred or &nbsp;any
        other amount required to be deducted from Your withdrawal in order to
        comply with any applicable &nbsp;law).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311585760116577",
        marginLeft: "0.28704071044921875pt",
        marginRight: "25.500732421875pt",
        textIndent: "0.1545562744140625pt",
        marginTop: "0.6060791015625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.7 We will attempt to accommodate Your request regarding the payment
        method and currency of &nbsp;payment of Your withdrawal. This, however,
        cannot be guaranteed. Therefore, we may process and pay &nbsp;withdrawals
        in a different payment method than the one requested by You, such as
        through different &nbsp;payment providers, a bank draft or wire transfer
        (any charges associated with relevant payment &nbsp;methods are set out in
        the Website). Similarly, in certain cases, the currency of Your withdrawal
        may not &nbsp;be the currency in which Your deposit was made or that was
        otherwise requested by You and, in &nbsp;circumstances where we are
        required to convert Your deposits between different currencies, the
        &nbsp;conversion rate used by us will set upon our discretion.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.33119964599609375pt",
        marginTop: "8.52972412109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.8 LIMITATIONS:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.28704071044921875pt",
        marginRight: "38.50836181640625pt",
        textIndent: "0.64031982421875pt",
        marginTop: "1.656280517578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Please note that the minimum amount of deposit and minimum amount of
        withdrawal to/from the &nbsp;gaming account per transaction is 10 EUR. The
        maximum amount of deposit depends on the payment &nbsp;method You decide
        to use and will appear when choosing the payment method.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.44159698486328125pt",
        marginRight: "40.841064453125pt",
        textIndent: "0.4857635498046875pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Maximum withdrawal amount processed to any player is equivalent to 2.000
        per day since the last &nbsp;successful cashout, 5.000 per week, 10.000
        per month, 50.000 per year unless otherwise specified by &nbsp;Us.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092591762542725",
        marginLeft: "0.18768310546875pt",
        marginRight: "29.10211181640625pt",
        textIndent: "-0.30912017822265625pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.30912017822265625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        All payout requests are processed in the order of the queue within 72
        hours if the requested amount &nbsp;does not exceed 1000 EUR or the
        equivalent in another currency and up to 7 days in case the requested
        &nbsp;amount exceeds 1000 EUR.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3174086570739745",
        marginLeft: "0.33119964599609375pt",
        marginRight: "26.4390869140625pt",
        textIndent: "-0.49680328369140625pt",
        marginTop: "0.6311492919921875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.49680328369140625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Withdrawals are processed without commission. Commission for the deposit
        depends only on the user’s &nbsp;payment system.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.33119964599609375pt",
        marginTop: "8.476150512695312pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9 PAYMENT SYSTEM RESTRICTED COUNTRIES&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.1 Visa / Mastercard&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3124460697174072",
        marginLeft: "0.07727813720703125pt",
        marginRight: "26.7203369140625pt",
        textIndent: "0.2539215087890625pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        We support gaming/gambling payouts via OCT (Original Credit Transfer) from
        Visa or also via Payment &nbsp;Transfer (Mastercard). Prerequisite for
        paying out are that an initial pay-in was done and for Mastercard
        &nbsp;this pay-in needs to have been made via a 3-D Secure MID. Additional
        requirements are that the &nbsp;respective credit card is not a corporate
        credit card and the respective card is issued in a country that is
        &nbsp;supported by the respective card scheme (see below). The per
        transaction limits are 60000,00 EUR for &nbsp;Visa and 5000,00 EUR for
        Mastercard.
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.2 Visa&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.91632080078125pt",
        marginRight: "54.58197021484375pt",
        textIndent: "0.01103973388671875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        For Visa (OCT) there is a list of countries not supported. List of
        non-participating countries for OCT: USA, Australia, Hongkong, India,
        Indonesia, Japan, Korea, Malaysia, Singapore&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.3 Mastercard&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Mastercard PT list of countries supported. List of participating countries
        for PT:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110722064971922",
        marginLeft: "0.18768310546875pt",
        marginRight: "31.4697265625pt",
        textIndent: "-0.25391387939453125pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.25391387939453125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Andorra, Austria, Belgium, Cyprus, Czech republic, Denmark, Estonia,
        France, Germany, Gibraltar, &nbsp;Greece, Hungary, Iceland, Ireland,
        Italy, Latvia, Luxembourg, Malta, Monaco, Netherlands, Norway, San
        &nbsp;marino, Slovakia, Slovenia, Spain, Sweden, Switzerland, Turkey,
        United kingdom 8.9.4 Skrill&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.37535858154296875pt",
        marginRight: "44.3089599609375pt",
        textIndent: "-0.11040496826171875pt",
        marginTop: "0.614501953125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.11040496826171875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        -Countries restricted for registration. Residents of this country category
        can access the websites, but &nbsp;cannot register/sign up an
        account:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3133334159851073",
        marginLeft: "0.07727813720703125pt",
        marginRight: "26.3397216796875pt",
        textIndent: "0.11040496826171875pt",
        marginTop: "0.46124267578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Afghanistan, Angola, Barbados, Benin, Bonaire, Burkina Faso, Cape Verde,
        Cuba, Comoros, Djibouti, East &nbsp;Timor, Eritrea, Faroe Islands, French
        Polynesia, Gambia, Greenland, Grenada, Guadeloupe, Guyana, Iran,
        &nbsp;Iraq, Japan, Kyrgyzstan, Lao People’s Democratic Republic, Libya,
        Macao, Martinique, Namibia, Nauru, &nbsp;New Caledonia, Niger, North
        Korea, Palau, Reunion, Saint Barthelemy, Saint Martin (Sint Maarten),
        &nbsp;Samoa, South Sudan, Sudan, Suriname, Syria, Tajikistan, Togo,
        Turkmenistan, US Minor Outlying Islands -Banned countries. Residents of
        this country category cannot register an account, nor access the Skrill
        &nbsp;domains:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038245201110839",
        marginLeft: "0.18768310546875pt",
        marginRight: "38.98052978515625pt",
        textIndent: "-0.18767547607421875pt",
        marginTop: "0.5936279296875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.18767547607421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Afghanistan, Cuba, Eritrea, Iran, Iraq, Japan, Kyrgyzstan, Libya, North
        Korea, South Sudan, Sudan, Syria -Restricted for Gaming and Binary:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3116728782653808",
        marginLeft: "0.18768310546875pt",
        marginRight: "24.7249755859375pt",
        textIndent: "-0.3422393798828125pt",
        marginTop: "0.681121826171875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.3422393798828125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        American Samoa (relevant state licenses required), Brazil, Canada, China
        (Pr), Guam (relevant state &nbsp;licenses required), Hong Kong, Israel,
        Macao, Puerto Rico (relevant state licenses required), Northern
        &nbsp;Mariana Island (relevant state licenses required), Singapore,
        Turkey, United Arab Emirates, United States &nbsp;Of America, US Minor
        Outlying Islands, US Virgin Islands&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.608917236328125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.5 Neteller&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.37535858154296875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        -Does not provide service to the following countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119738578796387",
        marginLeft: "0.18768310546875pt",
        marginRight: "38.11932373046875pt",
        textIndent: "-0.33119964599609375pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.33119964599609375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Afghanistan, Benin, Bonaire, Burundi, Central African Republic, Chad,
        China, Congo Republic, Crimea, &nbsp;Cuba, Djibouti, Equatorial Guinea,
        Eritrea, Gabon, Gambia, Guinea, Guinea-Bissau, Guyana, Iran, Iraq,
        &nbsp;Kazakhstan, Kyrgyzstan, Lao People’s Democratic Republic, Liberia,
        Libya, Madagascar, Malawi, Mali, &nbsp;Mauritania, Mongolia, Montserrat,
        Myanmar, Nauru, Niger, Niue, Norfolk Island, North Korea, Palau,
        &nbsp;Papua New Guinea, Saint Barthelemy, Saint Kitts and Nevis, Saint
        Martin (Sint Maarten), Sierra Leone, &nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.33119964599609375pt",
        marginRight: "49.526123046875pt",
        textIndent: "0.05519866943359375pt",
        marginTop: "0.486175537109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Sudan (North and South), Suriname, Syria, Tajikistan, Timor-Leste, Togo,
        Turkmenistan, Uzbekistan, &nbsp;Western Sahara, Yemen&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.37535858154296875pt",
        marginTop: "0.5811920166015625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        -Requires local license for specific countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.18768310546875pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Australia, Belgium, France, Germany, Japan, Spain, Turkey, UK&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "1.5463409423828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.5 CardPay (Unlimit)&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Deposits only:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311069917678833",
        marginLeft: "0.3863983154296875pt",
        marginRight: "30.7825927734375pt",
        textIndent: "0.5409622192382812pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Nigeria, Congo, Cote d’Ivoire, Kenya, Zimbabve, Mozambique, Zambia,
        Botswana, Namibia, Kazakhstan, &nbsp;Mongolia, Uzbekistan, Papua New
        Guinea, Fiji, Bulgaria, Estonia, Slovakia, Slovenia, Andorra, Moldova,
        &nbsp;San Marino, Ukraine, Belarus, Salvador, Guatemala, Bolivia, Ecuador,
        Peru, Trinidad and Tobago Deposits+Withdrawals:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314328908920288",
        marginLeft: "0.9273605346679688pt",
        marginRight: "29.83807373046875pt",
        marginTop: "0.6144790649414062pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Kazakhstan, Mongolia, Uzbekistan, Bulgaria, Estonia, Slovakia, Slovenia,
        Moldova, Belarus, Andorra, San &nbsp;Marino, Ukraine
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.6 Directa&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.9273605346679688pt",
        marginRight: "50.94793701171875pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        LATAM, SE Asia (China, Philippines, Thailand, Vietnam, Indonesia,
        Singapore), India, Canada, Japan, &nbsp;Kenya, Nigeria, Peru&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.68109130859375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.10.7 Volt&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092604637145995",
        marginLeft: "0.5188827514648438pt",
        marginRight: "64.11297607421875pt",
        textIndent: "-0.408477783203125pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.408477783203125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        CURACAO – Germany, Netherlands, France, Austria, Ireland, Italy, Finland
        and Spain MGA – Austria, Belgium, Germany, Spain, Finland, France, Great
        Britain, Ireland, Italy, Lithuania, &nbsp;Netherlands, Poland,
        Portugal&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.63116455078125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.8 Jeton&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.516357421875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted for Withdrawal&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.5188827514648438pt",
        marginRight: "35.60235595703125pt",
        textIndent: "-0.408477783203125pt",
        marginTop: "1.66632080078125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.408477783203125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Cape Verde, Central African Republic, Ethiopia, Libya, Somalia, Sudan,
        Afghanistan, Burma (Myanmar), &nbsp;Iran, Iraq, Israel, Russian
        Federation, North Korea, Syria, Yemen, Georgia, Latvia, Cuba, United
        States, &nbsp;Kosovo&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted for Deposit&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.3863983154296875pt",
        marginRight: "27.531982421875pt",
        textIndent: "0.13248443603515625pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Cape Verde, Central African Republic, Ethiopia, Libya, Somalia, Sudan,
        Afghanistan, Burma (Myanmar), &nbsp;Iran, Iraq, Israel, Russian
        Federation, North Korea, Syria, Yemen, Georgia, Latvia, Lithuania, Cuba,
        United &nbsp;States, Kosovo&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.9 AccentPay (Monetix)&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128795146942138",
        marginLeft: "0.18768310546875pt",
        marginRight: "27.388427734375pt",
        textIndent: "-0.33119964599609375pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.33119964599609375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Afghanistan, Australia, Algeria, American Samoa, Barbados, Cambodia, Guam,
        Guatemala, Tuvalu, &nbsp;Uzbekistan, Russia, Singapore, Saudi Arabia,
        Ecuador, China, Indonesia, Iceland, Taiwan, Philippines, The &nbsp;Central
        African Republic, The Democratic People’s Republic of Korea, DR Congo, The
        Republic of the &nbsp;Congo, Guinea-Bissau, Iraq, Somalia, Mali, Libya,
        South Sudan, Sudan, Yemen, Belarus, Burundi, Cuba, &nbsp;Iran, Lebanon,
        Nicaragua, Syria, Venezuela, Zimbabwe, China, USA, UK (by agreement),
        Moldova, &nbsp;Canada (by agreement)&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.477813720703125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.10 InPay.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.33119964599609375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Works only with withdrawals.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311069631576538",
        marginLeft: "0.07727813720703125pt",
        marginRight: "42.94659423828125pt",
        textIndent: "0.8500823974609375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Botswana, Burundi, Ethiopia, Ghana, Ivory Coast, Libya, Nigeria, Tunisia,
        Zimbabwe, Afghanistan, &nbsp;Cambodia, Iran, Iraq, North Korea, Russian
        Federation, Saudi Arabia, Syria, Yemen, Denmark, France, &nbsp;Lithuania,
        Norway, Switzerland, Ukraine, United Kingdom, Bahamas, Cuba, Panama,
        Trinidad and &nbsp;Tobago, United States, Samoa, Puerto Rico, Kosovo,
        Venezuela.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.614471435546875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.11 EcoPayz&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.5463409423828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Blacklisted Countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128810882568358",
        marginLeft: "0.18768310546875pt",
        marginRight: "24.9818115234375pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Afghanistan, Angola, American Samoa, Azerbaijan, Brunei Darussalam,
        Bhutan, Botswana, Belarus, Cuba, &nbsp;Djibouti, Algeria, Western Sahara,
        Eritrea, Ethiopia, Micronesia, Federated States of Guatemala, Guam,
        &nbsp;Haiti, Iran, Kyrgyzstan, Cambodia, Kiribati, Korea (DPRK),
        Kazakhstan, Lao People’s Democratic Republic, &nbsp;Lebanon, Liberia,
        Lesotho, Libyan Arab Jamahiriya, Madagascar, Myanmar, Northern Mariana
        Islands, &nbsp;Nauru, Niue, Puerto Rico, Occupied Palestinian Territory,
        Sudan, the Federal Republic of Somalia, Syrian &nbsp;Arab Republic,
        Eswatini, Tajikistan, Tokelau, United States Minor Outlying Islands,
        United States, Virgin &nbsp;Islands, U.S., Viet Nam, Vanuatu, Yemen,
        Turkey.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.5188827514648438pt",
        marginTop: "0.59381103515625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Closed without a local license:
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.18768310546875pt",
        marginRight: "34.65283203125pt",
        textIndent: "0.7286376953125pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        United Kingdom, Guadeloupe, Israel, Mayotte, Matrtinique, Singapore,
        United States, Sweden, Latvia, &nbsp;Australia, France + French colonies,
        Netherlands, Norway, Germany, Poland, Denmark, Bulgaria, Spain,
        &nbsp;Italy, Belgium, Portugal, Greece&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.12 Settlepay&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.91632080078125pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Ukraine channel – only UAH, only MC&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.9273605346679688pt",
        marginRight: "97.86843872070312pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Kazakhstan, Belarus, Uzbekistan, Kyrgyzstan, Tajikistan, Armenia, Ukraine,
        Turkmenistan. Kazakhstan channel – only KZT&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3130643367767334",
        marginLeft: "-0.0110321044921875pt",
        marginRight: "25.1363525390625pt",
        textIndent: "-0.1987152099609375pt",
        marginTop: "0.68109130859375pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.1987152099609375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Armenia, Azerbaijan, Belarus, Uzbekistan, Kyrgyzstan, Moldova, Tajikistan,
        Kazakhstan, Australia, &nbsp;Belgium, Germany, Ireland, Liechtenstein,
        Lithuania, Luxembourg, Monaco, Netherlands, Switzerland, &nbsp;France,
        Bulgaria, Hungary, Poland, Romania, Slovakia, Iceland, Denmark, Ukraine,
        Czech Republic, Latvia, &nbsp;Norway, Estonia, Finland, Sweden, Albania,
        Andorra, Bosnia and Herzegovina, Vatican City, Greece, Italy, &nbsp;Spain,
        Macedonia, Malta, San Marino, Portugal, Serbia, Slovenia, Croatia,
        Montenegro, Georgia 8.9.13 Piastrix&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "0.59613037109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119738578796387",
        marginLeft: "-0.0110321044921875pt",
        marginRight: "28.36322021484375pt",
        textIndent: "-0.1987152099609375pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.1987152099609375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Albania, Andorra, Antigua and Barbuda, Austria, Belarus, Belgium, Bosnia
        and Herzegovina, Bulgaria, &nbsp;Croatia, Cyprus, Czech Republic, Denmark,
        Estonia, Finland, France, Germany, Greece, Hungary, Iceland,
        &nbsp;Ireland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg,
        Macedonia, Malta, Moldova, Monaco, &nbsp;Montenegro, Netherlands, Norway,
        Poland, Portugal, Romania, San Marino, Serbia, Slovakia, Slovenia,
        &nbsp;Spain, Sweden, Switzerland, Ukraine, United Kingdom, Vatican
        City&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.60614013671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.14 Xpate&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3118443965911866",
        marginLeft: "0.91632080078125pt",
        marginRight: "28.10595703125pt",
        textIndent: "0.01103973388671875pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        EUR (WL+FTD) – Restricted for Burundi, Central African Republic, Cuba,
        Iran, Iraq, Libya, Lebanon, Noth &nbsp;Korea, Somalia, Sudan, Syria,
        Venezuela, Yemen, China, USA, Azerbaijan, Moldova, Nicaragua, Ukraine. USD
        (WL only) – Restricted for Andorra, Anguilla, Antigua and Barbuda, Aruba,
        Antilles (Netherlands), &nbsp;Bahamas, Bahrain , Bermuda Islands, British
        Virgin Islands, Belize, Barbados, Gibraltar, Jersey, Dominica, &nbsp;Hong
        Kong (China), Cayman Islands, Cook Islands, Costa Rica, Guernsey, Grenada,
        Liberia, Liechtenstein, &nbsp;Maldives, Men Islands, Marshall Islands,
        Montserrat, Monaco, Macau (China), Nauru, Niue, Panama, &nbsp;Palau,
        Seychelles, St. Kitts and Nevis, Samoa, St. Vincent and the Grenadines,
        St. Lucia, Turks and Caicos &nbsp;Islands, Vanuatu, Virgin Islands (USA),
        Russia, Armenia, Azerbaijan&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "0.60736083984375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        KZT (WL only) – Open for Kazakhstan only&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        RUB (WL only) – Open for Russia only (for payins Tinkoff cards are
        unavailable)&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "1.516326904296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.15 Sirenpay&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.33119964599609375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Works only with deposits.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted countries: Turkey, Iraq, Iran, Russia, Azerbaijan, Kazakhstan,
        Norway&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.16 Pradexx&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.5463409423828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Sofort Open: Austria, Belgium, Germany, Italy, Spain&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119760036468506",
        marginLeft: "0.4857635498046875pt",
        marginRight: "25.3472900390625pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Neosurf Open: Australia, New Zeeland, Canada, Benin, Burkina Faso,
        Burundi, Cameroun, Central African &nbsp;Republic, Chad, Congo
        Brazzaville, Democratic Republic of Congo, Equatorial Guinea, Gabon,
        Gambia, &nbsp;Ghana, Guinea Bissau, Guinea Conakry, Ivory Coast, Kenya,
        Malawi, Mali, Mauritania, Morocco, &nbsp;Mozambique, Niger, Nigeria,
        Rwanda, Sao Tome et Principe, Senegal, Sierra Leone, Tanzania, Togo,
        &nbsp;Uganda, Zambia and Zimbabwe&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.60614013671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.17 Exactly&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.18768310546875pt",
        marginTop: "1.6323165893554688pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Afghanistan, Armenia, Azerbaijan, Belize, Congo, Cote D’Ivoire, Czech
        Republic, Estonia, Guyana, Iran,&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.18768310546875pt",
        marginRight: "30.17864990234375pt",
        textIndent: "0.7396774291992188pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Iraq, South Korea, North Korea, Latvia, Liberia, Lithuania, Myanmar,
        Puerto Rico, Somalia, Sudan, Syrian &nbsp;Arab Republic, Thailand, United
        Kingdom, United States, Viet Nam&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.44159698486328125pt",
        marginRight: "57.704498291015625pt",
        textIndent: "0.08832550048828125pt",
        marginTop: "0.58123779296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Optional (can be opened upon request): Australia, Canada, New Zealand,
        Japan. Only MC, no VISA 8.9.18 Astropay&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.33119964599609375pt",
        marginTop: "0.68109130859375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Worldwide available, with the exception of USA &amp; Israel&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.19 Mifinity&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported for eWallet Deposits/Withdrawal:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3133354187011719",
        marginLeft: "0.18768310546875pt",
        marginRight: "25.5772705078125pt",
        textIndent: "-0.33119964599609375pt",
        marginTop: "1.516357421875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.33119964599609375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Albania, Andorra, Armenia, Austria, Azerbaijan, Belgium, Bosnia and
        Herzegovina, Bulgaria, Croatia, &nbsp;Cyprus, Czech Republic, Denmark,
        Estonia, Finland, France, Georgia, Germany, Greece, Hungary, Iceland,
        &nbsp;Ireland, Italy, Kazakhstan, Kosovo, Latvia, Liechtenstein,
        Lithuania, Luxembourg, Malta, Moldova, &nbsp;Monaco, Montenegro,
        Netherlands, Macedonia, Norway, Poland, Portugal, Romania, Russian
        &nbsp;Federation, San Marino, Serbia, Slovakia, Slovenia, Spain, Sweden,
        Switzerland, Ukraine, United &nbsp;Kingdom, Canada, Mexico, Argentina,
        Brazil, Chile, Paraguay, Peru, Uruguay, Australia, New Zealand,
        &nbsp;China, India, Japan, Ghana, Kenya, Nigeria, South Africa&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "0.5936279296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported for Bank Transfer Withdrawals:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128795146942138",
        marginLeft: "0.03311920166015625pt",
        marginRight: "30.63421630859375pt",
        textIndent: "0.15456390380859375pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil,
        Bulgaria, Canada, Chile, China, &nbsp;Colombia, Costa Rica, Croatia,
        Cyprus, Czech Republic, Denmark, Ecuador, Estonia, EthIopia, Finland,
        &nbsp;France, Germany, Ghana, Greece, Hong Kong, Hungary, Iceland, India,
        Indonesia, Ireland, Italy, Japan, &nbsp;Jersey, Kenya, Latvia,
        Liechtenstein, Lithuania, Luxembourg, Malaysia, Malta, Mexico, Monaco,
        Nepal, &nbsp;Netherlands, Nigeria, Norway, Pakistan, Peru, Philippines,
        Poland, Portugal, Romania, Rwanda, San &nbsp;Marino, Singapore, Slovakia,
        Slovenia, South Africa, South Korea, Spain, Sri Lanka, Sweden,
        Switzerland, &nbsp;Tanzania, Thailand, Turkey, United Kingdom, Uruguay,
        Vietnam&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.597808837890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.20 Pay4Fun&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.53631591796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported only in Brasil&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.21 CoinsPaid&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.44159698486328125pt",
        marginRight: "41.7196044921875pt",
        textIndent: "0.4857635498046875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        No restriction. Recommendation – not to work with countries where
        cryptocurrencies are prohibited. 8.9.22 eZeeWallet (EmerchantPay)&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.18768310546875pt",
        marginRight: "25.7545166015625pt",
        textIndent: "-0.1987152099609375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.1987152099609375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Australia, Denmark, Finland, Norway, Sweden, Austria, Belgium, Cyprus,
        Czech Republic, Estonia, France, &nbsp;Germany, Greece, Ireland, Italy,
        Latvia, Lithuania, Netherlands, Poland, Portugal, Slovakia, Spain,
        &nbsp;Switzerland, United Kingdom&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9273605346679688pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Restricted for deposits:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119757175445557",
        marginLeft: "0.03311920166015625pt",
        marginRight: "27.6865234375pt",
        textIndent: "0.8942413330078125pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Israel, Saudi Arabia, Indonesia, Portugal, Botswana, Slovakia, Spain,
        Iceland, Poland, Greece, Lithuania, &nbsp;Jamaica, Nepal, Singapore, Czech
        Republic, Barbados, Vietnam, Zimbabwe, Hungary, Sao Tome and
        &nbsp;Principe, Trinidad and Tobago, Russian Federation, Mauritius,
        Uganda, China, Senegal, Guinea Bissau, &nbsp;Cuba, Bangladesh,
        Netherlands, Burma (Myanmar), Cambodia, Iraq, Belgium, Ukraine, Jordan,
        Slovenia, &nbsp;Turkey, Romania, France, Norway, Sweden, Yemen, Hong Kong,
        Canada, Australia, Italy, Iran, Burkina &nbsp;Faso, Croatia, United
        States, North Korea, United Arab Emirates, Germany, Ghana, Azerbaijan,
        Malaysia, &nbsp;Albania, Vanuatu, Sudan, Bahamas, India, Pakistan, Japan,
        Bulgaria, Oman, Nicaragua, Venezuela, Syria, &nbsp;Panama, Afghanistan,
        Thailand, Morocco, Latvia, United Kingdom, Malta&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.6061553955078125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        8.9.23 ZotaPay&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Supported countries:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "2.031433296203613",
        marginLeft: "0.18768310546875pt",
        marginRight: "107.406005859375pt",
        textIndent: "-0.16559600830078125pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.16559600830078125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Vietnam, Indonesia, Cambodia, Myanmar, Thailand, Japan, China, Laos,
        India, Malaysia&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        9. BONUSES
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.38639068603515625pt",
        marginRight: "33.4273681640625pt",
        textIndent: "-0.055206298828125pt",
        marginTop: "0pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.055206298828125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        9.1. LoloBet reserves the right to remove, add or change any bonus that is
        available in the account of &nbsp;any Customer and has not been claimed.
        Any claimed and active bonus will not be removed by LoloBet &nbsp;under
        regular circumstances.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3122021675109863",
        marginLeft: "0.28704071044921875pt",
        marginRight: "24.98187255859375pt",
        textIndent: "0.1545562744140625pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        9.2. Bonuses might have wagering requirements attached to them. These
        requirements will be &nbsp;appropriately displayed before the Customer
        decides to use a bonus when wagering requirements are &nbsp;applicable.
        Withdrawal amounts will be only possible for the real money balance the
        bonus money &nbsp;balance upon withdrawal will be lost. LoloBet has the
        right to choose which game and service &nbsp;contributes what amount to
        the wagering requirements. The wagering requirements have a minimum of
        &nbsp;25 times the amount of bonus and the real money sum contributing to
        the bonus figure. 9.3. Bonuses can be received once per person (Customer),
        per household, per address, per mobile &nbsp;phone, per shared computer
        and per shared IP address. Risk-free bets on any games or services do not
        &nbsp;count toward the wagering requirements. Winnings from free spins are
        added to the bonus money &nbsp;balance unless otherwise provided in the
        specific bonus terms attributable to each bonus. 9.4. The maximum amount
        of money to be won from a no deposit free spin bonus and gameplay with
        &nbsp;mentioned bonus funds is 50 EUR or any other currency equivalent.
        Any winnings exceeding 50 EUR &nbsp;without making a real money deposit
        and fulfilment of wagering requirements will be void and will be
        &nbsp;kept by LoloBet. Example, a customer receives a no deposit free
        spins bonus. The free spins get played &nbsp;out and the customer wins 10
        EUR. The customer proceeds to play other games with the money,
        &nbsp;previously won from free spins, and without wagering real money as
        per wagering requirements, and &nbsp;wins 90 EUR, totalling 100 EUR in the
        LoloBet cashier. In this case the customer has exceeded the cap of
        &nbsp;50 EUR winnings, 50 EUR will be available for withdrawing and 50 EUR
        will be kept byLoloBet. 9.5. The maximum bet when having an active bonus
        is EUR 5 per spin or EUR 0.5 per bet line. LoloBet &nbsp;reserves the
        right to void bets and wager turnovers resulting from larger bets made on
        games and/or &nbsp;services of LoloBet.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110686302185057",
        marginLeft: "0.44159698486328125pt",
        marginRight: "38.82269287109375pt",
        marginTop: "0.604034423828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        9.6. LoloBet reserves the right to impose various restrictions to
        different countries where Customers &nbsp;may reside regarding bonuses for
        reasons we deem appropriate. We further reserve the right to offer
        &nbsp;specific bonuses to individual customers or customer groups and
        their termination, change or other &nbsp;alteration without explanation
        and/or compensations in any form.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.28704071044921875pt",
        marginRight: "35.78033447265625pt",
        textIndent: "0.1545562744140625pt",
        marginTop: "0.614471435546875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        9.7. If a player or a group of players are abusing casino bonuses LoloBet
        has the right to confiscate any &nbsp;wins thereof and to close the
        players account(s). Abusive behaviour towards bonuses and/or bonus
        &nbsp;abuse means:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.18768310546875pt",
        marginRight: "33.08642578125pt",
        textIndent: "0.5299148559570312pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        i) A Player places bets and/or wagers amounting to the maximum allowed per
        bonus terms to raise the &nbsp;variance in order increase the
        balance&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146911144256592",
        marginLeft: "0.18768310546875pt",
        marginRight: "45.3507080078125pt",
        textIndent: "0.5299148559570312pt",
        marginTop: "0.5811920166015625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        ii) Player lowers the bet and/or wager size after a big win and changes
        the game to one with a lower &nbsp;volatility&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146911144256592",
        marginLeft: "0.5409622192382812pt",
        marginRight: "39.1348876953125pt",
        textIndent: "0.1766357421875pt",
        marginTop: "0.491180419921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        iii) Player makes deposits to cap out the bonus amount but not more.
        Applicable to match bonuses of &nbsp;50% and above&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.18768310546875pt",
        marginRight: "43.99261474609375pt",
        textIndent: "-0.64031982421875pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.64031982421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        After the account has been flagged as bonus abusing it is forbidden to use
        any other bonuses and/or &nbsp;participate in promotions at LoloBet.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        10. LEGAL USE OF THE WEBSITE&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.309077501296997",
        marginLeft: "0.49680328369140625pt",
        marginRight: "28.60406494140625pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "9.676322937011719pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        10.1 Access to or use of the Website or any of the Services via the
        Website may not be legal for some or &nbsp;all residents of or persons in
        certain countries. We do not intend that the Website should be used for
        &nbsp;betting, gaming or any other purposes by persons in countries in
        which such activities are illegal, which&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119774341583252",
        marginLeft: "0.18768310546875pt",
        marginRight: "25.7657470703125pt",
        textIndent: "0.5299148559570312pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        includes the USA, France, The Netherlands, the Dutch Caribbean Islands
        (Aruba, Curaçao and Sint &nbsp;Maarten), Bonaire, St Eustatius, Saba,
        Malta, United Kingdom, Latvia, Lithuania, Estonia, Germany, &nbsp;Austria,
        Czech Republic, Hungary, Serbia, Portugal, Spain, Ontario (Canada),
        Cyprus, Slovenia, Slovakia &nbsp;and those territories listed in the
        Website. The fact that the Website is accessible in any such country, or
        &nbsp;appears in the official language of any such country shall not be
        construed as a representation or &nbsp;warranty with respect to the
        legality or otherwise of the access to and use of the Website, and the
        &nbsp;making of deposits or receipt of any winnings from Your Account. The
        availability of the Website does &nbsp;not constitute an offer,
        solicitation or invitation by us for the use of or subscription to
        betting, gaming or &nbsp;other services in any jurisdiction in which such
        activities are prohibited by law.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092604637145995",
        marginLeft: "0.1656036376953125pt",
        marginRight: "34.50982666015625pt",
        textIndent: "0.772796630859375pt",
        marginTop: "0.606201171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        10.2 It is Your responsibility to determine the law that applies in the
        location in which You are present. &nbsp;You should ensure that You will
        be acting legally in Your jurisdiction in opening Your Account and/or
        &nbsp;using the Website and You represent, warrant and agree that You will
        do so.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110700607299803",
        marginLeft: "0.44159698486328125pt",
        marginRight: "39.36627197265625pt",
        textIndent: "0.49680328369140625pt",
        textAlign: "justify",
        marginTop: "0.6611328125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        10.3 If it becomes apparent to us that You are resident in a country in
        which the use of the Website is &nbsp;not legal or You are using the
        Website from a country in which the use of the Website is not legal, we
        &nbsp;shall be entitled immediately to close Your Account, in which case
        any balance on the Account on the &nbsp;date of such closure will be
        refunded to You as soon as it is practicable for us to do so.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.65447998046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        11. PLACING YOUR BET AND/OR GAMING USING THE SERVICES&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.8280029296875pt",
        marginRight: "60.420135498046875pt",
        textIndent: "0.1103973388671875pt",
        marginTop: "9.556304931640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        11.1 In order to place a bet or access a Service you should follow the
        instructions provided at the &nbsp;respective section of the
        Website.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3124769687652587",
        marginLeft: "0.44159698486328125pt",
        marginRight: "26.001953125pt",
        textIndent: "0.49680328369140625pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        11.2 It is Your responsibility to ensure that the details of any bet,
        stake or similar transaction that you &nbsp;place using the Services (a
        “Transaction“) are correct when using the Website (either directly,
        through &nbsp;an application or otherwise) in accordance with the relevant
        Rules, as appropriate. 11.3 Your Transaction history can be accessed by
        you by clicking My Account on the Website, or through &nbsp;our Customer
        Services team (including by opting to receive a written statement). 11.4
        We reserve the right to refuse the whole or part of any Transaction
        requested by You at any time in &nbsp;our sole discretion, or where You
        have breached the Terms and Conditions. No Transaction is accepted
        &nbsp;by us until You have given the appropriate confirmation (or it has
        otherwise been accepted by us) in &nbsp;accordance with paragraph 11.2. If
        You are in any doubt as to whether a Transaction has been accepted
        &nbsp;successfully, You should contact <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a>.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.4857635498046875pt",
        marginRight: "40.10736083984375pt",
        textIndent: "0.45263671875pt",
        marginTop: "0.48150634765625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        11.5 Once a Transaction has been accepted by us, You cannot cancel the
        Transaction unless we agree &nbsp;otherwise.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.8280029296875pt",
        marginRight: "32.78717041015625pt",
        textIndent: "0.1103973388671875pt",
        marginTop: "0.581207275390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        11.6 In respect of gaming, the relevant Game Rules shall set out the point
        at which no further stakes or &nbsp;bets will be accepted by Us.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3174086570739745",
        marginLeft: "0.9273605346679688pt",
        marginRight: "46.39361572265625pt",
        textIndent: "0.01103973388671875pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        11.7 We may cancel or amend a Transaction due to Collusion, Cheating,
        Fraud and Criminal Activity, &nbsp;Errors or Omissions, as well because
        terms set out for Verification of Your Identity.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.476165771484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        12. REMOTE GAMING OR BETTING&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.49680328369140625pt",
        marginRight: "53.4649658203125pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "9.67633056640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        12.1 Where You are accessing the Services via an electronic form of
        communication You should be &nbsp;aware that:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3145705223083495",
        marginLeft: "0.4857635498046875pt",
        marginRight: "33.38323974609375pt",
        textIndent: "0.45263671875pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        12.1.1 in relation to Your use of the Website for the placing of bets or
        playing of games: 12.1.1.1 You may be using a connection or equipment
        which is slower than such equipment used by &nbsp;others and this may
        affect Your performance in time critical events offered via the Website;
        12.1.1.2 You may encounter system flaws, faults, errors or service
        interruption which will be dealt with&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7175979614257812pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        in accordance with IT Failure as below;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.4857635498046875pt",
        marginRight: "73.47982788085938pt",
        textIndent: "0.45263671875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        12.1.1.3 the Rules for each event or game offered via the Website are
        available and should be &nbsp;considered by You prior to Your use of the
        Services offered via the Website.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13. COLLUSION, CHEATING, FRAUD AND CRIMINAL ACTIVITY&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9384002685546875pt",
        marginTop: "9.55633544921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.1 The following practices (or any of them) in relation to the
        Services:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • abuse of bonuses or other promotions; and/or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "1.0377578735351562pt",
        marginRight: "123.56903076171875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • using unfair external factors or influences (commonly known as
        cheating); and/or • taking unfair advantage (as defined below);&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • opening any Duplicate Accounts; and/or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • undertaking fraudulent practice or criminal activity (as defined
        below),&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3126549243927002",
        marginLeft: "0.07727813720703125pt",
        marginRight: "28.9561767578125pt",
        textIndent: "0.40848541259765625pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        constitute “Prohibited Practices" and are not permitted and will
        constitute a material breach of the &nbsp;Terms and Conditions. We will
        take all reasonable steps to prevent and detect such practices and to
        &nbsp;identify the relevant players concerned if they do occur. Subject to
        the above, however, we will not be &nbsp;liable for any loss or damage
        which You may incur as a result of any Prohibited Practices, and any
        action &nbsp;we take in respect of the same will be at our sole
        discretion.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110686302185057",
        marginLeft: "0.4857635498046875pt",
        marginRight: "32.66558837890625pt",
        textIndent: "0.45263671875pt",
        marginTop: "0.599884033203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.2 If You suspect a person is engaged in any Prohibited Practice, You
        shall as soon as reasonably &nbsp;practicable report it to us by e-mailing
        us or telephoning (if available) Customer Services. 13.3 You agree that
        You shall not participate in or be connected with any form of Prohibited
        Practice in &nbsp;connection with Your access to or use of the
        Services.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9384002685546875pt",
        marginTop: "0.614501953125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.4 Where:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3152935028076171",
        marginLeft: "0.28704071044921875pt",
        marginRight: "30.20367431640625pt",
        textIndent: "0.6513595581054688pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.4.1 we have reasonable grounds to believe that You have participated in
        or have been connected &nbsp;with any form of Prohibited Practice (and the
        basis of our belief shall include the use by us (and by our &nbsp;gaming
        partners and our other suppliers) of any fraud, cheating and collusion
        detection practices which &nbsp;are used in the gambling and gaming
        industry at the relevant time); or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.44159698486328125pt",
        marginRight: "41.32086181640625pt",
        textIndent: "0.49680328369140625pt",
        marginTop: "0.455596923828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.4.2 You have placed bets and/or played online games with any other
        online provider of gambling &nbsp;services and are suspected (as a result
        of such play) of any Prohibited Practice or otherwise improper
        &nbsp;activity; or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.1656036376953125pt",
        marginRight: "31.3629150390625pt",
        textIndent: "0.772796630859375pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.4.3 we become aware that You have “charged back" or denied any of the
        purchases or deposits that &nbsp;You made to Your Account; or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3125791072845459",
        marginLeft: "0.07727813720703125pt",
        marginRight: "25.25775146484375pt",
        textIndent: "0.8611221313476562pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.4.4 in our reasonable opinion your continued use of the Services may be
        detrimental to our regulated &nbsp;status, including our continued ability
        to be licensed by the Licensing Authority; or 13.4.5 You become bankrupt
        or suffer analogous proceedings anywhere in the world, then, (including in
        &nbsp;connection with any suspension and/or termination of Your Account)
        we shall have the right, in respect &nbsp;of Your Account (and/or any
        other account held by You with an Operator) to withhold the whole or part
        &nbsp;of the balance and/or recover from the account the amount of any
        deposits, pay-outs, bonuses or &nbsp;winnings which have been affected by
        or are in any way attributable to any of the event(s) &nbsp;contemplated
        in this paragraph 13.4. The rights set out in this paragraph 13.4 are
        without prejudice to &nbsp;any other rights (including any common law
        rights) that we may have against You, whether under the &nbsp;Terms and
        Conditions or otherwise.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9384002685546875pt",
        marginTop: "0.6006011962890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.5 For the purposes of this paragraph 12:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.309077501296997",
        marginLeft: "0.1656036376953125pt",
        marginRight: "27.3883056640625pt",
        textIndent: "0.772796630859375pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.5.1 “fraudulent practice" means any fraudulent activity engaged in by
        You or by any person acting on &nbsp;Your behalf or in collusion with You,
        and shall include, without limitation: (a) fraudulent charge-backs
        &nbsp;and rake-back activity; (b) the use by You or any other person who
        was participating in the same game&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.312882947921753",
        marginLeft: "0.19872283935546875pt",
        marginRight: "27.43280029296875pt",
        textIndent: "0.2980804443359375pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        as You at any time, of a stolen, cloned or otherwise unauthorised credit
        or debit card, as a source of &nbsp;funds; (c) the collusion by You with
        others in order to gain an unfair advantage (including through bonus
        &nbsp;schemes or similar incentives offered by us); (d) any attempt to
        register false or misleading account &nbsp;information; and (e) any actual
        or attempted act by You which is reasonably deemed by us to be illegal
        &nbsp;in any applicable jurisdiction, made in bad faith, or intended to
        defraud us and/or circumvent any &nbsp;contractual or legal restrictions,
        regardless of whether such act or attempted act actually causes us any
        &nbsp;damage or harm;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.8280029296875pt",
        marginRight: "29.23089599609375pt",
        textIndent: "0.1103973388671875pt",
        textAlign: "justify",
        marginTop: "0.47784423828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.5.2 “criminal activity" shall include, without limitation, money
        laundering and any offence under any &nbsp;law or regulation in Your
        country, where you are playing from or where your IP has been registered;
        and 13.5.3 “unfair advantage" shall include, without limitation:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.4857635498046875pt",
        marginRight: "32.06488037109375pt",
        textIndent: "0.45263671875pt",
        marginTop: "0.58111572265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.5.3.1 the exploitation of a fault, loophole or error in our or any
        third party’s software used by You in &nbsp;connection with the Services
        (including in respect of any game);&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9384002685546875pt",
        marginTop: "0.71112060546875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.5.3.2 the use of third party software or analysis systems; or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.49680328369140625pt",
        marginRight: "50.64984130859375pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.5.3.3 the exploitation by You, of an Error as defined below, in any
        case either to Your advantage &nbsp;and/or to the disadvantage of us or
        others.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128795146942138",
        marginLeft: "0.19872283935546875pt",
        marginRight: "24.716796875pt",
        textIndent: "0.7396774291992188pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        13.6 In exercising any of our rights under paragraph 13.4 in relation to a
        Prohibited Practice, we shall use &nbsp;all reasonable endeavours to
        ensure that, while complying with our regulatory and other legal
        &nbsp;obligations, we exercise such rights in a manner which is fair to
        You and to our other customers. 13.7 We reserve the right to inform
        relevant authorities, other online gaming or gambling operators,
        &nbsp;other online service providers and banks, credit card companies,
        electronic payment providers or other &nbsp;financial institutions of Your
        identity and of any suspected Prohibited Practice by You, and You shall
        &nbsp;cooperate fully with us to investigate any such activity.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "2.0444689750671388",
        marginLeft: "0.4305572509765625pt",
        marginRight: "118.66729736328125pt",
        textIndent: "0.36432647705078125pt",
        marginTop: "8.517822265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14. CLOSURE OF YOUR ACCOUNT; TERMINATION OF THE TERMS AND CONDITIONS
        CLOSURE AND TERMINATION BY YOU&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.1656036376953125pt",
        marginRight: "34.25537109375pt",
        textIndent: "0.772796630859375pt",
        marginTop: "1.787200927734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.1 Provided that Your Account does not show that a balance is due to us,
        You are entitled to close &nbsp;Your Account and terminate the Terms and
        Conditions on not less than twenty four hours’ notice to us &nbsp;at any
        time, by contacting us through <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a>:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.9384002685546875pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.1.1 indicating Your wish to close Your Account; and&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.4857635498046875pt",
        marginRight: "30.72259521484375pt",
        textIndent: "0.45263671875pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.1.2 stating the reasons why You wish to close Your Account, in
        particular if You are doing so because &nbsp;of concerns about Responsible
        Gaming.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311975860595703",
        marginLeft: "0.28704071044921875pt",
        marginRight: "25.45947265625pt",
        textIndent: "0.044158935546875pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        We will respond to Your request, confirming closure of Your Account and
        the date on which such closure &nbsp;will be effective, within a
        reasonable time, provided that You continue to assume responsibility for
        all &nbsp;activity on Your Account until such closure has been carried out
        by us (at which point the Terms and &nbsp;Conditions shall
        terminate).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.303826379776001",
        marginLeft: "0.9384002685546875pt",
        marginRight: "47.2825927734375pt",
        marginTop: "0.6061553955078125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.2 When You request closure of Your Account under paragraph 14.1 we
        will, subject to paragraph &nbsp;13.3, return any outstanding balance in
        Your Account to You.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119757175445557",
        marginLeft: "0.4857635498046875pt",
        marginRight: "26.0416259765625pt",
        textIndent: "0.45263671875pt",
        marginTop: "0.681121826171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.3 Upon any termination of Your Account under this paragraph 14 we shall
        be entitled (without &nbsp;limiting our rights under paragraph 14.6) to
        withhold, from the repayment of the outstanding balance &nbsp;on Your
        Account, any monies: (a) pursuant to Collusion, Cheating, Fraud and
        Criminal Activity); (b) &nbsp;pursuant to Breach of the Terms and
        Conditions; (c) as otherwise provided by the Terms and Conditions;
        &nbsp;or (d) as required by law or regulation.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314691686630249",
        marginLeft: "0.8280029296875pt",
        marginRight: "35.01202392578125pt",
        textIndent: "0.1103973388671875pt",
        marginTop: "0.602142333984375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.4 When repaying the outstanding balance on Your Account, we shall use
        the same method of &nbsp;payment which You provided upon registration of
        Your Account, or such other payment method as we&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.8280029296875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        may reasonably select.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119781494140625",
        marginLeft: "0.1656036376953125pt",
        marginRight: "25.45654296875pt",
        textIndent: "0.772796630859375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.5 Where You have closed Your Account, we may in certain circumstances
        be able to re-open Your &nbsp;Account with the same account details as
        before if You request us to do so. In such circumstances, while &nbsp;Your
        Account will have the same account details as before, it will be subject
        to the Terms and Conditions &nbsp;which are in force at the date of any
        such re-opening and any prior entitlements (including, but without
        limitation, to bonuses or contingent winnings) will no longer be
        valid.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.4305572509765625pt",
        marginTop: "0.60614013671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        CLOSURE AND TERMINATION BY US&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311976718902588",
        marginLeft: "0.17664337158203125pt",
        marginRight: "35.33734130859375pt",
        textIndent: "0.7617568969726562pt",
        marginTop: "1.516357421875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.6 We are, at any time (and notwithstanding any other provisions
        contained in the Terms and &nbsp;Conditions), entitled to close Your
        Account and terminate the Terms and Conditions on written notice &nbsp;(or
        attempted notice) to You using Your Contact Details. In the event of any
        such termination by us we &nbsp;shall, subject to paragraph 14.7, as soon
        as reasonably practicable following a request by You, refund &nbsp;the
        balance of Your Account.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3122764110565186",
        marginLeft: "0.17664337158203125pt",
        marginRight: "25.809814453125pt",
        textIndent: "0.7617568969726562pt",
        marginTop: "0.63616943359375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.7 Where we close Your Account and terminate the Terms and Conditions
        pursuant to Collusion, &nbsp;Cheating, Fraud and Criminal Activity or
        Breach of the Terms and Conditions, the balance of Your &nbsp;Account will
        be non-refundable and deemed to be forfeited by You to the extent of any
        claim that we &nbsp;may have against You as at the date of such closure
        (whether under Your Account, a Duplicate Account &nbsp;or otherwise).
        Closure of Your Account and Termination of the Terms and Conditions, other
        than &nbsp;pursuant to paragraphs 12 or 21 of these General Terms, will
        not affect any outstanding bets, provided &nbsp;that such outstanding bets
        are valid and You are not in breach of the Terms and Conditions in any
        way. &nbsp;For the avoidance of doubt, we will not credit any bonuses into
        Your Account, nor will You be entitled to &nbsp;any contingent winnings,
        at any time after the date on which it has been closed (whether by us
        pursuant &nbsp;to the Terms and Conditions, or in response to Your
        request).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3116718769073485",
        marginLeft: "0.07727813720703125pt",
        marginRight: "28.48126220703125pt",
        textIndent: "0.8611221313476562pt",
        marginTop: "0.603363037109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.8 The following paragraphs shall survive any termination of the Terms
        and Conditions: 20, 21, 22, 23, &nbsp;24, 26, 27, 29, 30, 31, 32, 33 and
        35 and any other paragraphs which are required for the purposes of
        &nbsp;interpretation; together with any relevant sections of the Rules,
        the Privacy Policy and the Additional &nbsp;Terms.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.3863983154296875pt",
        marginTop: "0.60894775390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        SUSPENSION BY US&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3125174522399903",
        marginLeft: "0.17664337158203125pt",
        marginRight: "29.94927978515625pt",
        textIndent: "0.7617568969726562pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        14.9 We shall be entitled to suspend Your Account in the circumstances
        expressly set out in the Terms &nbsp;and Conditions. Upon the suspension
        of Your Account: (a) no activity shall be permitted (including
        &nbsp;deposits, withdrawals, betting or gaming) until the date upon which
        it is re-activated by us; (b) no &nbsp;bonuses or contingent winnings will
        be credited to the Account; and (c) we shall address the issue that
        &nbsp;has given rise to the Account suspension with a view to resolving it
        as soon as reasonably practicable so &nbsp;that the Account can, as
        appropriate, either be re-activated or closed.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.641159057617188pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        15. ACCESS TO, AND USE OF, THE SERVICES&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119302272796631",
        marginLeft: "0.17664337158203125pt",
        marginRight: "27.3709716796875pt",
        textIndent: "0.7617568969726562pt",
        marginTop: "9.586334228515625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        15.1 You are solely responsible for the supply and maintenance of all of
        Your Access Devices and related &nbsp;equipment, networks and internet
        access services that You need to use in order to access the Services.
        &nbsp;We will not be liable in any way whatsoever for any losses caused to
        You (whether resulting from loss of &nbsp;service, poor internet
        connectivity, insufficient bandwidth or otherwise) by the internet or any
        &nbsp;telecommunications service provider that You have engaged in order
        to access the Services. For the &nbsp;avoidance of doubt, the Operator
        does not make any representation or give any warranty as to the
        &nbsp;compatibility of the Services with any particular third party
        software or hardware, including (for the &nbsp;avoidance of doubt) third
        party analysis which promise certain results from any of the Services.
        15.2 Under no circumstances should You use the Services for any purpose
        which is or is likely to be&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3125205993652342",
        marginLeft: "0.44159698486328125pt",
        marginRight: "27.91375732421875pt",
        textIndent: "0.04416656494140625pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        considered to be defamatory, abusive, obscene, unlawful, of a racist,
        sexist or other discriminatory &nbsp;nature, or which could cause offence.
        You must not use any abusive or aggressive language or images,
        &nbsp;swear, threaten, harass or abuse any other person, including other
        users, via the Website, or attempt to &nbsp;pass Yourself off as being any
        other person, or behave in such a manner towards any Operator staff
        &nbsp;used to provide the Services, Customer Services, or any helpdesk or
        support function which we make &nbsp;available to You.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092604637145995",
        marginLeft: "0.49680328369140625pt",
        marginRight: "32.90850830078125pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "0.6011962890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        15.3 You shall use the Website for personal entertainment only and shall
        not be allowed to provide &nbsp;access or reproduce the Website or any
        part of it in any form whatsoever without our express consent,
        &nbsp;including creating links to it.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092604637145995",
        marginLeft: "0.5188827514648438pt",
        marginRight: "27.87432861328125pt",
        textIndent: "0.41951751708984375pt",
        marginTop: "0.63116455078125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        15.4 Any material (other than Software under paragraph 17) downloaded by
        You from the Website shall &nbsp;be downloaded entirely at Your own risk
        and the Operator shall not be liable in respect of any loss of &nbsp;data
        or other damage caused by any such download.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.19872283935546875pt",
        marginRight: "37.82122802734375pt",
        textIndent: "0.7396774291992188pt",
        marginTop: "0.6611328125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        15.5 Where we have reason to believe that Your use of the Services is in
        breach of any of paragraphs &nbsp;15.2, 15.3, 15.4 we shall, without
        prejudice to any of our other rights, be entitled forthwith to remove
        &nbsp;from the Website any offending content.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        16. BETTING AND GAMING TERMS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.17664337158203125pt",
        marginRight: "33.16253662109375pt",
        textIndent: "0.7617568969726562pt",
        marginTop: "9.5562744140625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        16.1 Expressions used in the betting and gaming industry are numerous.
        Should You be in any doubt as &nbsp;to the meaning of any expression, You
        should:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128795146942138",
        marginLeft: "0.3863983154296875pt",
        marginRight: "32.32220458984375pt",
        textIndent: "0.552001953125pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        16.1.1 look up its meaning in relating to the event or game You are
        betting or gaming on; 16.1.2 if You are still in any doubt, contact
        <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a> for clarification; and 16.1.3 not place any bet
        or game on any event until its meaning is understood to Your satisfaction,
        &nbsp;because we cannot accept any responsibility if You place a bet or
        game via the products offered via the &nbsp;Services in circumstances
        where You do not understand any of the terms involved in or relating to
        the &nbsp;bet or game.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.517822265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        17. ALTERATION OF THE WEBSITE&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3103447914123534",
        marginLeft: "0.33119964599609375pt",
        marginRight: "29.83929443359375pt",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        We may, in our absolute discretion, alter or amend any product or service
        (including any prices offered) &nbsp;available through the Website at any
        time for the purpose of ensuring the ongoing provision of the
        &nbsp;Website, but without prejudice to any games and/or bets already in
        progress at the time of such &nbsp;amendment. From time to time, we may
        restrict You from accessing some parts of the Website for the
        &nbsp;purpose of maintenance of the Website and/or alteration or amendment
        of any of the games and/or &nbsp;products available through the
        Website.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.7948837280273438pt",
        marginTop: "8.661148071289062pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        18. THIRD PARTY SOFTWARE&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311070489883423",
        marginLeft: "0.7175979614257812pt",
        marginRight: "35.54705810546875pt",
        textIndent: "0.22080230712890625pt",
        marginTop: "9.586334228515625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        18.1 In order to use the products offered through the Services, You may be
        required to download and &nbsp;install software supplied by third parties
        on to Your Access Device (“Software"). Software may include, &nbsp;but is
        not limited to: Access Device applications, our download Casino and Poker
        products and any &nbsp;promotional, marketing and/or facility
        applications, products and software.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3118847370147704",
        marginLeft: "0.1656036376953125pt",
        marginRight: "31.35162353515625pt",
        textIndent: "0.772796630859375pt",
        marginTop: "0.614471435546875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        18.2 In such circumstances, You may be required to enter into a separate
        agreement with the owner or &nbsp;licensor of such Software in respect of
        Your use of the same (a " Third Party Software Agreement"). In &nbsp;case
        of any inconsistency between the Terms and Conditions and any Third Party
        Software Agreement, &nbsp;the Terms and Conditions will prevail in so far
        as the inconsistency relates to the relationship between &nbsp;You and the
        Operator.
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110722064971922",
        marginLeft: "0.4857635498046875pt",
        marginRight: "31.15411376953125pt",
        textIndent: "0.45263671875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        18.3 It is Your responsibility to ensure that any Software is downloaded
        onto Your Access Device in a &nbsp;manner compatible with Your own Access
        Device’s specific set-up. For the avoidance of doubt, we shall &nbsp;not
        be liable to the extent that the incorrect downloading of any Software has
        an adverse effect on the &nbsp;operation of Your Access Device.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3125216007232665",
        marginLeft: "0.07727813720703125pt",
        marginRight: "24.958251953125pt",
        textIndent: "0.8611221313476562pt",
        marginTop: "0.61444091796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        18.4 Notwithstanding that the Services provided via any Access Device
        application shall be subject to the &nbsp;Terms and Conditions, the terms
        under which any application (“App") is downloaded or installed onto
        &nbsp;Your Access Device shall be governed by the agreement entered into
        between You and the supplier of &nbsp;the relevant App but, in case of any
        inconsistency between the Terms and Conditions and any such
        &nbsp;agreement, the Terms and Conditions will prevail in so far as the
        inconsistency relates to the &nbsp;relationship between You and the
        Operator.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "2.0345190525054933",
        marginLeft: "0.7948837280273438pt",
        marginRight: "76.46224975585938pt",
        textIndent: "0.14351654052734375pt",
        marginTop: "0.60113525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        18.5. Under no circumstances we shall be liable for any services provided
        by any third parties.&nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        19. IT FAILURE&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128790855407715",
        marginLeft: "0.28704071044921875pt",
        marginRight: "28.66900634765625pt",
        textIndent: "0.044158935546875pt",
        marginTop: "1.8787841796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Where problems occur in the software or hardware used by us to provide the
        Services we will take all &nbsp;reasonable steps to remedy the problem as
        soon as reasonably practicable. Where such problems cause a game to be
        interrupted in circumstances where it cannot be restarted from exactly the
        same position &nbsp;without any detriment to You or other players, we will
        take all reasonable steps to treat You in a fair &nbsp;manner (which may
        include reinstating the balance on Your Account to the position existing
        following &nbsp;completion of the last bet or game logged on the
        Operator’s server immediately prior to the occurrence &nbsp;of the
        problem).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.517822265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20. ERRORS OR OMISSIONS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.316501998901367",
        marginLeft: "0.6182403564453125pt",
        marginRight: "34.27752685546875pt",
        textIndent: "-0.17664337158203125pt",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.17664337158203125pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1 A number of circumstances may arise where a bet or wager is accepted,
        or a payment is made, by &nbsp;us in error. A non-exhaustive list of such
        circumstances is as follows:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.49680328369140625pt",
        marginRight: "47.92266845703125pt",
        textIndent: "0.12143707275390625pt",
        marginTop: "0.444488525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.1 where we mis-state any odds or terms of a bet or gaming wager to
        You as a result of obvious &nbsp;error or omission in inputting the
        information or setting up a market, or as a result of a computer
        &nbsp;malfunction;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.2 where we have made a ‘palpable error’. A palpable error occurs
        where:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.5188827514648438pt",
        marginRight: "29.21038818359375pt",
        textIndent: "0.09935760498046875pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.2.1 in relation to bets placed prior to an event taking place, the
        prices/terms offered are materially &nbsp;different from those available
        in the general market; or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.6182403564453125pt",
        marginRight: "62.363250732421875pt",
        textIndent: "-0.09935760498046875pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.09935760498046875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.2.2 in relation to any event, the price/terms offered at the time the
        bet is placed are clearly &nbsp;incorrect given the probability of the
        event occurring;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146918296813965",
        marginLeft: "0.49680328369140625pt",
        marginRight: "37.37921142578125pt",
        textIndent: "0.12143707275390625pt",
        marginTop: "0.461212158203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.3 where we have continued to accept bets on a market which should
        have been suspended, &nbsp;including where the relevant event is in
        progress (except where ‘in-running’ bets are accepted) or had
        &nbsp;already finished (sometimes referred to as ‘late bets’);&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "0.6111602783203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.4 where an error has been made as a result of a Prohibited
        Practice;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.6182403564453125pt",
        marginRight: "25.5606689453125pt",
        textIndent: "-0.30912017822265625pt",
        marginTop: "1.516326904296875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.30912017822265625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.5 where we should not have accepted, or have the right to cancel or
        re-settle, a bet pursuant to the &nbsp;Betting Rules (for example due to
        ‘Related Contingencies’);&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.49680328369140625pt",
        marginRight: "25.2275390625pt",
        textIndent: "0.12143707275390625pt",
        marginTop: "0.5811614990234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.6 where an error is made by us as to the amount of winnings/returns
        that are paid to You, including &nbsp;as a result of a manual or computer
        input error; or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146911144256592",
        marginLeft: "0.4857635498046875pt",
        marginRight: "54.72369384765625pt",
        textIndent: "0.132476806640625pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.1.7 where an error has been made by us as to the amount of free bets
        and/or bonuses that are &nbsp;credited to Your Account,&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.44159698486328125pt",
        marginTop: "0.5771713256835938pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        such circumstances being referred to as an “Error"&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "1.6363143920898438pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.2 We reserve the right to:
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110722064971922",
        marginLeft: "0.17664337158203125pt",
        marginRight: "26.47198486328125pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.2.1 correct any Error made on a bet placed and re-settle the same at
        the correct price or terms which &nbsp;were available or should have been
        available through the Operator (absent the publishing error) at the
        &nbsp;time that the bet was placed and the bet will be deemed to have
        taken place on the terms which were &nbsp;usual for that bet; or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.6182403564453125pt",
        marginRight: "31.64898681640625pt",
        textIndent: "-0.2097625732421875pt",
        marginTop: "0.61444091796875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2097625732421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.2.2 where it is not reasonably practicable to correct and re-settle
        under 20.2.1 above, to declare the &nbsp;bet void and return Your stake
        into Your Account; or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038309574127196",
        marginLeft: "0.4857635498046875pt",
        marginRight: "41.47552490234375pt",
        textIndent: "0.132476806640625pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.2.3 in circumstances where the Error has resulted from a Prohibited
        Practice, to take the steps set &nbsp;out in there Terms and
        Conditions.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3130643367767334",
        marginLeft: "0.5188827514648438pt",
        marginRight: "31.69403076171875pt",
        textIndent: "0.09935760498046875pt",
        marginTop: "0.68109130859375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.3 Any monies which are credited to Your Account, or paid to You as a
        result of an Error shall be &nbsp;deemed to be held by You on trust for us
        and shall be immediately repaid to us when a demand for &nbsp;payment is
        made by us to You. Where such circumstances exist, if You have monies in
        Your Account we &nbsp;may reclaim these monies from Your Account pursuant
        Terms and Conditions. We agree that we shall &nbsp;use all reasonable
        endeavours to detect any Errors and inform You of them as soon as
        reasonably &nbsp;practicable.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311585760116577",
        marginLeft: "0.28704071044921875pt",
        marginRight: "27.94061279296875pt",
        textIndent: "0.33119964599609375pt",
        marginTop: "0.59613037109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        20.4 Neither we (including our employees or agents) nor our partners or
        suppliers shall be liable for any &nbsp;loss, including loss of winnings,
        that results from any Error by us or an error by You. 20.5 You shall
        inform us as soon as reasonably practicable should You become aware of any
        Error. 20.6 Where You have used monies which have been credited to Your
        Account or awarded to You as a &nbsp;result of an Error to place
        subsequent bets or play games, we may cancel such bets and/or withhold any
        &nbsp;winnings which You may have won with such monies, and if we have
        paid out on any such bets or &nbsp;gaming activities, such amounts shall
        be deemed to be held by You on trust for us and You shall
        &nbsp;immediately repay to us any such amounts when a demand for repayment
        is made by us to You.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.52972412109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21. EXCLUSION OF OUR LIABILITY&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311585760116577",
        marginLeft: "0.07727813720703125pt",
        marginRight: "29.4530029296875pt",
        textIndent: "0.5409622192382812pt",
        marginTop: "9.6962890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.1 Your access to and use of the Services is at Your sole option,
        discretion and risk. We shall not be &nbsp;liable for any attempts by You
        to use the Services by methods, means or ways not intended by us. 21.2 We
        will provide the Services with reasonable skill and care and substantially
        as described in the &nbsp;Terms and Conditions. We do not make any other
        promises or warranties regarding the Services, or any &nbsp;products or
        services forming a part of the Services, and hereby exclude (to the extent
        permitted by law) &nbsp;all implied warranties in respect of the same
        (including implied warranties as to satisfactory quality &nbsp;and/or
        fitness for Your purpose). In particular, we do not warrant that the
        Website will have &nbsp;uninterrupted availability or that it will be free
        of bugs, viruses or other errors.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092594623565674",
        marginLeft: "0.18768310546875pt",
        marginRight: "29.7733154296875pt",
        textIndent: "0.4305572509765625pt",
        marginTop: "0.6097564697265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3 WE (INCLUDING OPERATOR, PAYMENT PROCESSOR, AFFILIATES, OFFICERS,
        DIRECTORS, AGENTS &nbsp;AND EMPLOYEES) SHALL NOT BE LIABLE TO YOU, WHETHER
        SUCH LIABILITY ARISES IN CONTRACT, TORT &nbsp;(INCLUDING NEGLIGENCE) OR
        OTHERWISE, IN RESPECT OF ANY:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "0.6611480712890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3.1 LOSS OF DATA;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3.2 LOSS OF PROFITS;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3.3 LOSS OF REVENUE;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "1.516326904296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3.4 LOSS OF BUSINESS OPPORTUNITY;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3.5 LOSS OF OR DAMAGE TO GOODWILL OR REPUTATION;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3.6 BUSINESS INTERRUPTION; OR&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.309077501296997",
        marginLeft: "0.5299224853515625pt",
        marginRight: "25.24688720703125pt",
        textIndent: "0.08831787109375pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        21.3.7 ANY INDIRECT, SPECIAL OR CONSEQUENTIAL LOSS OR DAMAGE, EVEN WHERE
        SUCH LOSS OR &nbsp;DAMAGE HAS BEEN NOTIFIED TO US AS BEING POSSIBLE,
        ARISING OUT OF THE TERMS AND CONDITIONS &nbsp;OR ANY USE WHATSOEVER BY YOU
        OF THE SERVICES.
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        22. BREACH OF THE TERMS AND CONDITIONS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146938323974608",
        marginLeft: "0.4857635498046875pt",
        marginRight: "30.23681640625pt",
        textIndent: "0.132476806640625pt",
        marginTop: "9.55633544921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        22.1 You will fully indemnify, defend and hold us and our officers,
        directors, employees, agents, &nbsp;contractors and suppliers harmless
        from and against any and all losses, costs, expenses, claims,
        &nbsp;demands, liabilities and damages (including legal fees), however
        caused, that may arise, whether or not &nbsp;reasonably foreseeable, as a
        result of or in connection with:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.6182403564453125pt",
        marginRight: "69.40673828125pt",
        textIndent: "-0.2097625732421875pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2097625732421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        22.1.1 the access to and use of the Services by You or by anyone else
        using Your username and &nbsp;password; and/or&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3123161792755127",
        marginLeft: "0.44159698486328125pt",
        marginRight: "36.32806396484375pt",
        textIndent: "0.17664337158203125pt",
        marginTop: "0.58123779296875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        22.1.2 any breach by You of any of the terms and provisions of the Terms
        and Conditions. 22.2 Where You are in breach of the Terms and Conditions,
        we may at our sole discretion, prior to any &nbsp;suspension or
        termination of Your Account, notify You (using Your Contact Details) that
        You are in &nbsp;breach, requiring You to stop the relevant act or failure
        to act, and/or requiring You to put right an act &nbsp;or fault on Your
        part and warning You of our intended action if You do not do so, provided
        always that &nbsp;such notification shall not be a pre-condition to any
        suspension or termination of Your Account. 22.3 We have the right to
        disable any user identification code or password, whether chosen by You or
        &nbsp;allocated by us, at any time, if in our reasonable opinion You have
        failed to comply with any of the &nbsp;provisions of the Terms and
        Conditions.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.309256887435913",
        marginLeft: "0.4857635498046875pt",
        marginRight: "35.458740234375pt",
        textIndent: "0.132476806640625pt",
        textAlign: "justify",
        marginTop: "0.602996826171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        22.4 In addition to any other remedy available, if You breach any of the
        Terms and Conditions we shall &nbsp;be entitled to recover from Your
        Account any positive balance to the extent of any amount reasonably
        &nbsp;claimed against You pursuant to paragraph 21.1.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.671173095703125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        23. INTELLECTUAL PROPERTY RIGHTS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.312698698043823",
        marginLeft: "0.07727813720703125pt",
        marginRight: "25.6357421875pt",
        textIndent: "0.5409622192382812pt",
        marginTop: "9.5562744140625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        23.1 All website design, text, graphics, music, sound, photographs, video,
        the selection and arrangement &nbsp;thereof, software compilations,
        underlying source code, software and all other material forming part of
        &nbsp;the Services are subject to copyright and other proprietary rights
        which are either owned by us or used &nbsp;under licence from third party
        rights owners. To the extent that any material comprised within the
        &nbsp;Services may be downloaded or printed then such material may be
        downloaded to a single device only &nbsp;(and hard copies may be printed)
        solely for Your own personal, non-commercial use. 23.2 Under no
        circumstances shall the use of the Services grant to You any interest in
        any intellectual &nbsp;property rights (for example copyright, know-how or
        trade marks) owned by us or by any third party &nbsp;whatsoever, other
        than a personal, non-exclusive, non-sub-licensable licence to use such
        intellectual &nbsp;property rights in connection with Your personal,
        non-commercial use of the Services pursuant to the &nbsp;Terms and
        Conditions.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3127543449401855",
        marginLeft: "0.17664337158203125pt",
        marginRight: "32.5885009765625pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "0.4794921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        23.3 No rights whatsoever are granted to use or reproduce any trade marks
        or logos which appear on &nbsp;the Website except as specifically
        permitted in accordance with the Terms and Conditions. 23.4 You must not,
        nor must You allow any other person to copy, store, publish, rent,
        licence, sell, &nbsp;distribute, alter, add to, delete, remove or tamper
        with the Website or any part of it in any way or &nbsp;directly or
        indirectly disrupt or interfere (or attempt to disrupt or interfere) with
        or alter the Website, &nbsp;other than in the course of viewing or using
        the Website in accordance with the Terms and Conditions. 23.5 All
        intellectual property rights in the name “LoloBet", the logos, designs,
        trade marks and other &nbsp;distinctive brand features of the Operator and
        any content provided by the Operator or any third party &nbsp;for
        inclusion on the Website vest in the Operator or the applicable third
        party. You agree not to display &nbsp;or use such logos, designs, trade
        marks and other distinctive brand features in any manner without our
        &nbsp;prior written consent.
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24. VIRUSES, HACKING AND OTHER OFFENCES&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "9.55633544921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.1 You shall not:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.1.1 corrupt the Website;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.4857635498046875pt",
        marginRight: "34.8404541015625pt",
        textIndent: "0.132476806640625pt",
        marginTop: "1.6363525390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.1.2 attempt to gain unauthorised access to the Website, the servers on
        which the Website is stored &nbsp;or any server, computer or database
        connected to the Website;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.1.3 flood the Website with information, multiple submissions or
        “spam";&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146929740905762",
        marginLeft: "0.28704071044921875pt",
        marginRight: "41.8350830078125pt",
        textIndent: "0.33119964599609375pt",
        textAlign: "justify",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.1.4 knowingly or negligently use any features which may affect the
        function of the Website in any &nbsp;way for example (but not limited to)
        releasing or propagating viruses, worms, trojans, logic bombs or
        &nbsp;similar material that is malicious or harmful;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146956920623778",
        marginLeft: "0.28704071044921875pt",
        marginRight: "50.41796875pt",
        textIndent: "0.33119964599609375pt",
        marginTop: "0.461181640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.1.5 interfere or tamper with, remove or otherwise alter in any way, any
        information in any form &nbsp;which is included on the Website;&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311070489883423",
        marginLeft: "0.17664337158203125pt",
        marginRight: "27.04412841796875pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "0.61114501953125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.1.6 attack the Website via a denial-of-service attack or a distributed
        denial-of-service attack. We will &nbsp;report any suspected breach to the
        relevant law enforcement authorities and we will co-operate with
        &nbsp;those authorities by disclosing Your identity to them. In the event
        of such a breach, Your right to use the &nbsp;Website will cease
        immediately.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119738578796387",
        marginLeft: "0.4857635498046875pt",
        marginRight: "25.6461181640625pt",
        textIndent: "0.132476806640625pt",
        marginTop: "0.614501953125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        24.2 We will not be liable for any loss or damage caused by a distributed
        denial-of-service attack, viruses &nbsp;or other technologically harmful
        material that may infect Your Access Device and related equipment,
        &nbsp;computer programs, data or other proprietary material due to Your
        use of the Website or to Your &nbsp;downloading of any material posted on
        such Website, or on any website linked to the Website. 24.3 Website is
        free from malicious software.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.526153564453125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        25. YOUR PERSONAL INFORMATION&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3101632595062256",
        marginLeft: "0.49680328369140625pt",
        marginRight: "34.70806884765625pt",
        textIndent: "0.12143707275390625pt",
        textAlign: "justify",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        25.1 All information on Your Account held by us is securely data
        warehoused and remains confidential &nbsp;except where otherwise stated in
        the Terms and Conditions (including, for the avoidance of doubt, the
        &nbsp;Privacy Policy).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3128799438476562",
        marginLeft: "0.44159698486328125pt",
        marginRight: "38.82000732421875pt",
        textIndent: "0.17664337158203125pt",
        marginTop: "0.622802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        25.2 We are required by law to comply with data protection requirements in
        the way in which we use &nbsp;any personal information collected from You
        in Your use of the Services. We therefore take very &nbsp;seriously our
        obligations in relation to the way in which we use Your personal
        information. 25.3 Prior to Your use of and when You use the Services it
        will be necessary for us to collect certain &nbsp;information about You,
        including Your name and date of birth, Your Contact Details, and may also
        &nbsp;include information about Your marketing preferences (all of which
        shall be known as “Your Personal &nbsp;Information“).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.6182403564453125pt",
        marginRight: "55.18719482421875pt",
        textIndent: "-0.30912017822265625pt",
        marginTop: "0.4778289794921875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.30912017822265625pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        25.4 By providing us with Your Personal Information, You consent to our
        processing Your Personal &nbsp;Information, including any of the same
        which is particularly sensitive:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092594623565674",
        marginLeft: "0.6182403564453125pt",
        marginRight: "55.8826904296875pt",
        marginTop: "0.6111602783203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        25.4.1 for the purposes set out in the Terms and Conditions (including the
        Privacy Policy); and 25.4.2 for other purposes where we (including Payment
        Processor) need to process Your Personal &nbsp;Information for the
        purposes of operating the Services,&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119757175445557",
        marginLeft: "0.17664337158203125pt",
        marginRight: "25.2127685546875pt",
        textIndent: "0.54095458984375pt",
        marginTop: "0.631134033203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        including by sharing it with our service providers, employees and agents
        for these purposes, for example &nbsp;to our providers of postal services,
        marketing services and Customer Services agents. We may also
        &nbsp;disclose Your Personal Information in order to comply with a legal
        or regulatory obligation. 25.5 We may retain copies of any communications
        that You send to us (including copies of any emails) in &nbsp;order to
        maintain accurate records of the information that we have received from
        You.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.642143249511719pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        26. USE OF ‘COOKIES’ ON THE WEBSITE
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3115899085998535",
        marginLeft: "0.1656036376953125pt",
        marginRight: "25.39031982421875pt",
        textIndent: "0.45263671875pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        26.1 The Website may use ‘cookies’ to track Your use of the internet and
        to assist the functionality of &nbsp;the Website. A cookie is a small file
        of text which is downloaded onto Your Access Device when You &nbsp;access
        the Website and it allows us to recognise when You come back to the
        Website. We use or shall &nbsp;use cookies for the operation of the
        Website, including (for example) to allow You to remain logged in as
        &nbsp;You browse between, and use Your Account to bet on or play games on,
        different parts of the Website. &nbsp;We also use cookies for our own
        analytical purposes so that we can identify where customers have
        &nbsp;encountered technical problems on the Website, and therefore help us
        improve our customers’ &nbsp;experience.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3130643367767334",
        marginLeft: "0.19872283935546875pt",
        marginRight: "25.13629150390625pt",
        textIndent: "0.41951751708984375pt",
        marginTop: "0.60968017578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        26.2 If You object to cookies or want to delete any cookies that are
        already stored on Your Access &nbsp;Device, we recommend that You follow
        the instructions for deleting existing cookies and disabling future
        &nbsp;cookies on Your file management and internet browsing software.
        Further information on deleting or &nbsp;controlling cookies is available
        within our&nbsp;
      </span>
        <a
          
          target="_blank"
          href="https://lolo.bet/en/contacts/privacy-policy"
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Privacy Policy&nbsp;
      </a>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        or at&nbsp;
      </span>
        <a
          href="https://www.aboutcookies.org/"
          target="_blank"
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        www.aboutcookies.org
      </a>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        . Please note that &nbsp;by deleting our cookies or disabling future
        cookies You may not be able to access certain areas or &nbsp;features of
        the Website.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.51611328125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27. COMPLAINTS AND NOTICES&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.6182403564453125pt",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27.1 No claim or dispute with regard to:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.6182403564453125pt",
        marginRight: "27.785888671875pt",
        textIndent: "-0.2097625732421875pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2097625732421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27.1.1 the acceptance or settlement of a bet which You have made using the
        Services will be considered &nbsp;more than thirty days after the date of
        the original transaction; and&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.49680328369140625pt",
        marginRight: "46.87408447265625pt",
        textIndent: "0.12143707275390625pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27.1.2 a game which You have played using the Services will be considered
        more than twelve weeks &nbsp;after the date on which the relevant
        transaction or game play took place.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3155956268310547",
        marginLeft: "0.49680328369140625pt",
        marginRight: "34.4334716796875pt",
        textIndent: "0.12143707275390625pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27.2 Should You wish to make a complaint regarding the Services, as a
        first step You should, as soon as &nbsp;reasonably practicable, contact
        <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a> about Your complaint, which will be
        &nbsp;escalated as necessary within our Support team until
        resolution.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110686302185057",
        marginLeft: "0.17664337158203125pt",
        marginRight: "38.6824951171875pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "0.57281494140625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27.3 You acknowledge that our random number generator will determine the
        outcome of the games &nbsp;played through the Services and You accept the
        outcomes of all such games. You further agree that in &nbsp;the unlikely
        event of a disagreement between the result that appears on Your screen and
        the game &nbsp;server used by the Operator, the result that appears on the
        game server will prevail, and You &nbsp;acknowledge and agree that our
        records will be the final authority in determining the terms and
        &nbsp;circumstances of Your participation in the relevant online gaming
        activity and the results of this &nbsp;participation.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3133342742919922",
        marginLeft: "0.17664337158203125pt",
        marginRight: "27.90740966796875pt",
        textIndent: "0.44159698486328125pt",
        marginTop: "0.614471435546875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27.4 When we wish to contact You, we may do so using any of Your Contact
        Details. Notices will be &nbsp;deemed to have been properly served and
        received by You immediately after an email is sent or after &nbsp;we have
        communicated with You directly by telephone (including where we leave You
        a voicemail), or &nbsp;three days after the date of posting of any letter.
        In proving the service of any notice, it will be sufficient &nbsp;to
        prove, in the case of a letter, that such letter was properly addressed,
        stamped and placed in the &nbsp;post; in the case of an email, that such
        email was sent to the specified email address (if any) in Your
        &nbsp;Contact Details at the time that any such email was sent.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146918296813965",
        marginLeft: "0.4857635498046875pt",
        marginRight: "42.9515380859375pt",
        textIndent: "0.132476806640625pt",
        marginTop: "0.4736480712890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        27.5 If there is no reaction on your complaint or notice from us or in
        your opinion the issue is not yet &nbsp;resolved, you can file a complaint
        or notice to the licensing and supervision organization contacting
        &nbsp;complaints@gaminglicences.com.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        28. TRANSFER OF RIGHTS AND OBLIGATIONS
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311976718902588",
        marginLeft: "0.4857635498046875pt",
        marginRight: "32.40582275390625pt",
        textIndent: "0.132476806640625pt",
        marginTop: "0pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        28.1 We reserve the right to transfer, assign, sublicense or pledge the
        Terms and Conditions (an &nbsp;“assignment"), in whole or in part, to any
        person without notice to You, provided that any such &nbsp;assignment will
        be on the same terms or terms that are no less advantageous to You. 28.2
        You may not assign, sublicense or otherwise transfer in any manner
        whatsoever any of Your rights &nbsp;or obligations under the Terms and
        Conditions.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.47472381591796875pt",
        marginTop: "8.5262451171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        29. EVENTS OUTSIDE OUR CONTROL&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3130643367767334",
        marginLeft: "0.4857635498046875pt",
        marginRight: "26.74139404296875pt",
        textIndent: "0.132476806640625pt",
        marginTop: "9.67626953125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        29.1 We will not be liable or responsible for any failure to perform, or
        delay in performance of, any of &nbsp;our obligations under the Terms and
        Conditions that is caused by events outside our reasonable control,
        &nbsp;including (without limitation) any telecommunications network
        failures, power failures, failures in third &nbsp;party computer (or
        other) equipment, fire, lightning, explosion, flood, severe weather,
        industrial &nbsp;disputes or lock-outs, terrorist activity and acts of
        government or other competent authorities (a “Force &nbsp;Majeure
        Event“).&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146896839141846",
        marginLeft: "0.28704071044921875pt",
        marginRight: "35.1385498046875pt",
        textIndent: "0.33119964599609375pt",
        marginTop: "0.4761962890625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        29.2 Our performance is deemed to be suspended for the period that the
        Force Majeure Event &nbsp;continues, and we will have an extension of time
        for performance for the duration of that period. We &nbsp;will use our
        reasonable endeavours to bring the Force Majeure Event to a close or to
        find a solution by &nbsp;which our obligations may be performed despite
        the Force Majeure Event.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.50115966796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        30. WAIVER&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092583179473876",
        marginLeft: "0.17664337158203125pt",
        marginRight: "33.35003662109375pt",
        textIndent: "0.37535858154296875pt",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        30.1 If we fail to insist upon strict performance of any of Your
        obligations or if we fail to exercise any of &nbsp;the rights or remedies
        to which we are entitled, this shall not constitute a waiver of such
        rights or &nbsp;remedies and shall not relieve You from compliance with
        such obligations.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3116718769073485",
        marginLeft: "0.49680328369140625pt",
        marginRight: "31.474609375pt",
        textIndent: "0.05519866943359375pt",
        marginTop: "0.631134033203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        30.2 A waiver by us of any default shall not constitute a waiver of any
        subsequent default. 30.3 No waiver by us of any of the provisions of the
        Terms and Conditions shall be effective unless it is &nbsp;expressly
        stated to be a waiver and is communicated to You in writing in accordance
        with paragraph 27 &nbsp;(Complaints and Notices) above.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.5289306640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        31. SEVERABILITY&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311069631576538",
        marginLeft: "0.19872283935546875pt",
        marginRight: "25.03155517578125pt",
        textIndent: "0.35327911376953125pt",
        marginTop: "9.676300048828125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        31.1 If any of the Terms and Conditions are determined by any competent
        authority to be invalid, &nbsp;unlawful or unenforceable to any extent,
        such term, condition or provision will to that extent be severed
        &nbsp;from the remaining terms, conditions and provisions which will
        continue to be valid to the fullest extent &nbsp;permitted by law.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.28704071044921875pt",
        marginRight: "34.696533203125pt",
        textIndent: "0.26496124267578125pt",
        marginTop: "0.6144866943359375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        31.2 In such cases, the part deemed invalid or unenforceable shall be
        amended in a manner consistent &nbsp;with the applicable law to reflect,
        as closely as possible, the Operator’s original intent.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.531158447265625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        32. ENTIRE AGREEMENT&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092594623565674",
        marginLeft: "0.49680328369140625pt",
        marginRight: "42.86651611328125pt",
        textIndent: "0.05519866943359375pt",
        textAlign: "justify",
        marginTop: "9.67633056640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        32.1 The Terms and Conditions and any document expressly referred to in
        them represent the entire &nbsp;agreement between You and us and supersede
        any prior agreement, understanding or arrangement &nbsp;between You and
        us, whether oral or in writing.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3092581748962402",
        marginLeft: "0.28704071044921875pt",
        marginRight: "32.15777587890625pt",
        textIndent: "0.26496124267578125pt",
        marginTop: "0.6311492919921875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        32.2 We each acknowledge that neither of us has relied on any
        representation, undertaking or promise &nbsp;given by the other or implied
        from anything said or written in negotiations between us except as
        &nbsp;expressly stated in the Terms and Conditions.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146909713745116",
        marginLeft: "0.28704071044921875pt",
        marginRight: "36.303955078125pt",
        textIndent: "0.26496124267578125pt",
        marginTop: "0.6271514892578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        32.3 Neither party shall have any remedy in respect of any untrue
        statement made by the other, &nbsp;whether orally or in writing, prior to
        the date of the contract (unless such untrue statement was made&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.19872283935546875pt",
        marginRight: "29.430908203125pt",
        textIndent: "-0.2980804443359375pt",
        marginTop: "0pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2980804443359375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        fraudulently) and the other party’s only remedy shall be for breach of
        contract as provided in the Terms &nbsp;and Conditions.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.501220703125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        33. THIRD PARTY RIGHTS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3115899085998535",
        marginLeft: "0.07727813720703125pt",
        marginRight: "27.3443603515625pt",
        textIndent: "0.47472381591796875pt",
        marginTop: "9.67633056640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        33.1 Unless these Terms and Conditions expressly state otherwise a person
        who is not a party to these &nbsp;Terms and Conditions has no right to
        enforce any of the terms. It is expressly forbidden for End Users to
        &nbsp;transfer any assets of the value of any kind to a third party,
        including but not limited, it is forbidden to &nbsp;transfer ownership of
        account(s), winnings, deposits, bets, rights and/or claims in connection
        with these &nbsp;assets, legal, commercial or of any other character. Such
        prohibition includes, but is not limited to &nbsp;encumbrance, pledging,
        assigning, usufruct, trading, brokering, hypothecation and/or gifting
        either &nbsp;separately or in cooperation with a fiduciary or any other
        third party, company, natural or legal &nbsp;individual, foundation and/or
        association in any way or shape of form.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.55975341796875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        34. LAW AND JURISDICTION&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038245201110839",
        marginLeft: "0.49680328369140625pt",
        marginRight: "41.86199951171875pt",
        textIndent: "0.05519866943359375pt",
        marginTop: "9.67626953125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        34.1 These Terms and Conditions shall (subject to paragraph 25.2) be
        governed by and interpreted in &nbsp;accordance with the laws of Scotland,
        UK.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.72113037109375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35. RESPONSIBLE GAMING/GAMBLING&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146906852722167",
        marginLeft: "0.33119964599609375pt",
        marginRight: "46.05621337890625pt",
        textIndent: "0.22080230712890625pt",
        textAlign: "justify",
        marginTop: "9.556304931640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.1 For those customers who wish to restrict their gambling, we provide a
        voluntary self-exclusion &nbsp;policy, which enables You to close Your
        Account or restrict Your ability to place bets or game on the
        &nbsp;Website for a minimum period of six months. Once Your Account has
        been self-excluded You will be &nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3101632595062256",
        marginLeft: "0.17664337158203125pt",
        marginRight: "29.01910400390625pt",
        textIndent: "0.6182403564453125pt",
        marginTop: "0.581146240234375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        unable to reactivate the Account under any circumstances until the expiry
        of the period chosen under &nbsp;this paragraph. At the expiry of the
        self-exclusion period You will be entitled to re-commence use of the
        &nbsp;Services by contacting <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a>.&nbsp;
        </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.19872283935546875pt",
        marginRight: "26.69329833984375pt",
        textIndent: "0.35327911376953125pt",
        marginTop: "0.622802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.2 In case you are concerned about your gambling practices there are a
        number of guidelines you may &nbsp;find useful:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.552001953125pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.2.1 try and establish limits for the amounts you want to wager or
        deposit.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.49680328369140625pt",
        marginRight: "27.749267578125pt",
        textIndent: "0.05519866943359375pt",
        marginTop: "1.63629150390625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.2.2 consider how long you wish any wagering session to last before
        commencing and be sure to keep &nbsp;an eye on the clock.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.552001953125pt",
        marginTop: "0.5811767578125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.2.3 try not to let gambling interfere with your daily
        responsibilities.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.311975860595703",
        marginLeft: "0.28704071044921875pt",
        marginRight: "32.06951904296875pt",
        textIndent: "0.26496124267578125pt",
        marginTop: "1.51629638671875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.3 Mind that gambling is not advised if you are recovering for any form
        of dependency or if you are &nbsp;under the influence of alcohol or any
        other substance including some prescription medication. 35.4 You should
        always be aware, that gambling is a form of recreation and should not be
        viewed as an &nbsp;alternative source of income or a way to recoup debt.
        Recognising that you may be developing a &nbsp;gambling problem is the
        first step to regaining control.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.552001953125pt",
        marginTop: "0.6361541748046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.5 Please bear in mind, that:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146925449371338",
        marginLeft: "0.552001953125pt",
        marginRight: "137.34695434570312pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.5.1 Gambling should be entertaining and not seen as a way of making
        money 35.5.2 you should avoid chasing losses&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.552001953125pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.5.3 Only gamble what you can afford to lose&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.552001953125pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.5.4 Keep track of time and the amount you spend gambling&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146918296813965",
        marginLeft: "0.18768310546875pt",
        marginRight: "28.09503173828125pt",
        textIndent: "0.36431884765625pt",
        marginTop: "1.6363372802734375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.6 If You require any information relating to this contact our Support 
        <a href="mailto://support@lolobetsupport.com"> support@lolobetsupport.com</a> or &nbsp;view our&nbsp;
      </span>
        <a
          href="https://lolo.bet/en/contacts/responsible-game"
          target="_blank"
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Responsible Gaming&nbsp;
      </a>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        section.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.552001953125pt",
        marginTop: "0.4571533203125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        35.7 The Operator is committed to supporting Responsible Gambling
        initiatives. We suggest to&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146902561187743",
        marginLeft: "0.18768310546875pt",
        marginRight: "64.0316162109375pt",
        textIndent: "-0.64031982421875pt",
        marginTop: "0pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.64031982421875pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        visit&nbsp;
      </span>
        <a
          href="www.responsiblegambling.org"
          target="_blank"
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        www.responsiblegambling.org
      </a>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        &nbsp;
      </span>
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        for further help, if you feel that gambling starts to become a
        &nbsp;problem for you.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.501220703125pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        36. LINKS&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3110722064971922",
        marginLeft: "0.19872283935546875pt",
        marginRight: "39.3563232421875pt",
        textIndent: "0.132476806640625pt",
        textAlign: "justify",
        marginTop: "9.67633056640625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        Where we provide hyperlinks to other websites, we do so for information
        purposes only. You use any &nbsp;such links at your own risk and we accept
        no responsibility for the content or use of such websites, or &nbsp;for
        the information contained on them. You may not link to this site, nor
        frame it without our express &nbsp;written permission.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "0.46367645263671875pt",
        marginTop: "8.5345458984375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 700,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        37. CONTACTING US&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3119785785675049",
        marginLeft: "0.07727813720703125pt",
        marginRight: "53.16693115234375pt",
        textIndent: "-0.2980804443359375pt",
        marginTop: "9.67626953125pt",
        marginBottom: "0pt",
        padding: "0pt 0pt 0pt 0.2980804443359375pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        The Operator can be contacted by e-mail <a href="mailto://support@lolobetsupport.com">support@lolobetsupport.com</a> and/or
        telephone &nbsp;+38349659893. Please note that all calls may be recorded
        for training and security purposes. If You are concerned about your
        gambling practices there are a number of guidelines you may find
        &nbsp;useful:&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3038245201110839",
        marginLeft: "0.49680328369140625pt",
        marginRight: "35.5361328125pt",
        textIndent: "0.54095458984375pt",
        marginTop: "0.6060791015625pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Consider how long you wish any wagering session to last before
        commencing and be sure to keep an &nbsp;eye on the clock.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.2",
        marginLeft: "1.0377578735351562pt",
        marginTop: "0.681121826171875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Try not to let gambling interfere with your daily
        responsibilities.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.314689254760742",
        marginLeft: "0.17664337158203125pt",
        marginRight: "43.0653076171875pt",
        textIndent: "0.861114501953125pt",
        marginTop: "1.636322021484375pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Gambling is not advised if you are recovering for any form of dependency
        or if you are under the &nbsp;influence of alcohol or any other substance
        including some prescription medication. We recommend &nbsp;that you
        consult your GP for more information.&nbsp;
      </span>
    </p>
    <p
      dir="ltr"
      style={{
        lineHeight: "1.3146921157836913",
        marginLeft: "0.17664337158203125pt",
        marginRight: "25.8319091796875pt",
        textIndent: "0.861114501953125pt",
        marginTop: "0.461151123046875pt",
        marginBottom: "0pt"
      }}
    >
      <span
        style={{
          fontSize: "11.039999961853027pt",
          fontFamily: "Calibri,sans-serif",
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        • Gambling is a form of recreation and should not be viewed as an
        alternative source of income or a way &nbsp;to recoup debt.
      </span>
    </p>
  </>
  
}

export default Indtroduction