import { Logo, LogoContainer } from "../../page/signUp/components/Style"

const HBTLogo = () => {
    return     <LogoContainer>
    <Logo>
      <svg
        style={{ width: "100%", height: "100%" }}
        viewBox="0 0 156 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M47.904 13.1339H37.7138V7.87573H34.0869V21.5778H37.7138V16.3386H47.904V21.5778H51.5312V7.87573H47.904V13.1339Z"
          fill="white"
        />
        <path
          d="M63.2806 7.87573H60.3062C57.8305 7.87573 53.0713 8.43224 53.0713 14.7267C53.0713 21.0405 57.8305 21.5778 60.3062 21.5778H63.2806C65.7564 21.5778 70.5156 21.0405 70.5156 14.7267C70.5156 8.43224 65.7564 7.87573 63.2806 7.87573ZM64.2785 18.1426H59.3084C57.37 18.1426 56.8902 16.473 56.8902 14.7267C56.8902 12.9996 57.37 11.33 59.3084 11.33H64.2785C66.2169 11.33 66.6966 12.9996 66.6966 14.7267C66.6966 16.473 66.2169 18.1426 64.2785 18.1426Z"
          fill="white"
        />
        <path
          d="M75.7023 18.181V7.87573H72.0559V21.5778H86.9671V18.181H75.7023Z"
          fill="white"
        />
        <path
          d="M97.9515 7.87573H94.9769C92.5014 7.87573 87.7422 8.43224 87.7422 14.7267C87.7422 21.0405 92.5014 21.5778 94.9769 21.5778H97.9515C100.427 21.5778 105.186 21.0405 105.186 14.7267C105.186 8.43224 100.427 7.87573 97.9515 7.87573ZM98.9494 18.1426H93.979C92.0409 18.1426 91.5611 16.473 91.5611 14.7267C91.5611 12.9996 92.0409 11.33 93.979 11.33H98.9494C100.888 11.33 101.367 12.9996 101.367 14.7267C101.367 16.473 100.888 18.1426 98.9494 18.1426Z"
          fill="white"
        />
        <path
          d="M124.17 11.7714C124.17 8.912 122.078 7.87573 119.66 7.87573H106.726V21.5778H119.66C122.078 21.5778 124.17 20.5415 124.17 17.6821C124.17 16.6458 123.748 15.5519 122.788 14.7267C123.748 13.9207 124.17 12.8269 124.17 11.7714ZM110.353 13.4793V11.2724H119.353C120.044 11.2724 120.543 11.6946 120.543 12.3663C120.543 13.1147 120.063 13.4793 119.277 13.4793H110.353ZM119.277 15.9933C120.063 15.9933 120.543 16.3386 120.543 17.0871C120.543 17.7781 120.044 18.181 119.353 18.181H110.353V15.9933H119.277Z"
          fill="white"
        />
        <path
          d="M125.71 7.87573V21.5778H140.621V18.181H129.337V16.377H140.621V13.1723H129.337V11.2724H140.621V7.87573H125.71Z"
          fill="white"
        />
        <path
          d="M142.165 11.33H147.193V21.5778H150.82V11.33H155.963V7.87573H142.165V11.33Z"
          fill="white"
        />
        <path
          d="M17.5504 0H11.4093L0 19.6275L3.01037 25.1664L6.32179 19.6275H13.9681L6.89371 32H13.0649L23.4205 14.2089L20.4704 8.63971L17.0688 14.2089H9.48264L17.5504 0Z"
          fill="white"
        />
      </svg>
    </Logo>
  </LogoContainer>
}

export default HBTLogo