import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion';
import Spinner from '../Spinner';

const StyledButton = styled(motion.button)`
    color: ${ (props) => props.color ? props.color : "#FFFFFF" };
    font-size:${ (props) => props.nav ? '18px' : props.fontSize ? props.fontSize : "20px" } ;
    font-weight: ${ (props) => props.fw ? props.fw : "700" } ;
    line-height: ${ (props) => props.noLineHeight ? "1" : "24.3px"} ;
    letter-spacing: ${(props) => props.ls ? props.ls : "0em" } ;
    padding: ${ (props) => props.nav ? "2px" : props.padding ? props.padding : "0.85rem 2rem"} ;
    border-radius: ${ (props) => props.br ? props.br : props.nav ? "1.45rem" : "12px" };
    border: ${ (props) => props.lightBorder ? "1.2px solid #FFFFFF" : "none"};
    cursor: pointer;
    min-width: ${ (props) => props.width ? props.width : props.navWidth ? props.navWidth : "15rem"};
    background: ${ (props) => props.bg ? props.bg : "linear-gradient(99.36deg, #2CB6C7 15.61%, #7B2DEA 111.02%)"};
    margin: ${ (props) => props.m ? props.m : '' };
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    height: ${ (props) => props.height ? props.height : ''};

    @media(max-width: 768px){
        max-width: ${ (props) => props.navSmall ? props.navSmall : props.navWidth ? props.navWidth : "15rem" };
        font-size:${ (props) => props.nav ? '9px' : props.fontSize ? props.fontSize : "12px" } ;
    }

    @media(max-width: 450px){
        margin: ${ (props) => props.sm ? props.sm : '' };
    }

    `
    const ButtonInside = styled.div`
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: ${ (props) => props.navBr ? props.navBr : "1.45rem"};
        padding: ${ (props) => props.navP ? props.navP : '0 2rem'};
        height: ${ (props) => props.Inheight ? props.Inheight : '100%'};

        @media(max-width: 768px){
            width: ${ (props) => props.sm ? props.sm : '' };
            padding: ${ (props) => props.navPs ? props.navPs : props.navP ? props.navP : '0 2rem'};
        }
    `

const Button = (props) => {

    const renderContent = () => {

        if (props.isLoading) {
            return <Spinner/>
        }

        return props.Text
    }

    return (
        <StyledButton
            onClick={() => {
                props.onClick()
            }}
            {...props}
            type="button" 
            style={{
               opacity:props.disabled?0.3:1
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.75 }}
        >
            { props.nav ? 
            <ButtonInside>
                {renderContent()}
            </ButtonInside>
            :
            renderContent()

            }
        </StyledButton>
    )
    }
export default Button;