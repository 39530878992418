import React, { useEffect, useState } from "react"
import { isMobile } from 'mobile-device-detect';

import { StyledCard } from "./Style"
import LeftComponent from "./LeftComponent"
import RightComponent from "./RightComponent"

const Card = () => {
  const [isFormVisible, setFormVisible] = useState(isMobile?false:true);


  useEffect(() => {
    if (isFormVisible) {
      window.location.href = "/#signup"

    }
  },[isFormVisible])
  return (
    <StyledCard>
      <LeftComponent onNextClick={() => {
        setFormVisible(true)

      }} />
      {isFormVisible && <RightComponent /> }
    </StyledCard>
  )
}

export default Card
