import React, { useEffect, useState } from "react"
import "./Style.css"
import {
  StyledRightComponent,
  CustomText,
  MobileContent,
  Logo,
  LogoContainer,
  Wrapper,
  CaptchaContainer,
} from "./Style"
import Heading from "../../../components/Heading/Heading"
import Button from "../../../components/Button/Button"
import Text from "../../../components/Text/Text"
import {
  MailOutlined,
  UnlockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  DownOutlined,
} from "@ant-design/icons"
import { Input, Select, Checkbox } from "antd"
import Flex from "../../../components/Styling/Flex"
import ModalContent from "./Modal"
import config from "../../../config"
import toast, { Toaster } from "react-hot-toast"
import ReCAPTCHA from "react-google-recaptcha"
import HBTLogo from "../../../components/HBTLogo"

const RightComponent = () => {
  const [info, setInfo] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    referral: "",
    socialHandle: "",
    socialPlatfrom: "twitter",
  })

  const [isCaptchaPassed, setCaptchaPassed] = useState(false)
  const [isTermAndConditionAccepted, setTermAndConditionAccepted] =
    useState(false)
  const [isSendPromotionalEmailChecked, setSendPromotionalEmailChecked] =
    useState(false)
  console.log(isTermAndConditionAccepted)
  const [isLoading, setLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setTermAndConditionAccepted(true)
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
  const validatePassword = (password) => {
    const isValid = String(password).match(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    )

    return isValid
  }

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  const handleSubmitClicked = async () => {
    setLoading(true)

    try {
      const payload = {
        email: info.email,
        password: info.password,
        passwordRepeat: info.password,
        currency: "USD",
        affiliateId: info.referral,
      }
      if (info.socialHandle) {
        payload.affiliateClickId = info.socialHandle
        payload.affiliateSystem = info.socialPlatfrom
      }

      var raw = JSON.stringify(payload)

      var myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/json")

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      }

      await fetch("https://holobet.com/api/v1/profiles", requestOptions)

      toast.success("Thank you for your submission")
      await sleep(2000)

      window.location.href = "https://holobet.com"
    } catch (err) {
      console.error("errrrr", err)
      toast.error("ERORR")
    }
    setLoading(false)
  }

  const isReadyToSubmit = () => {
    const isReady =
      isTermAndConditionAccepted &&
      validateEmail(info.email) &&
      validatePassword(info.password) &&
      isCaptchaPassed

    return isReady
  }
  const modalProps = {
    isModalOpen,
    showModal,
    handleOk,
    handleCancel,
  }

  return (
    <Wrapper>
      <MobileContent>
       <HBTLogo/>
      </MobileContent>

      <StyledRightComponent id="signup">
        <Heading
          Text="SIGN UP"
          color="#7232E1"
          fw="700"
          mcenter
          fs="30px"
          lhXm="28px"
          lhMs="26px"
          lh="28.6px"
          ls="0.16px"
        />

        <Input
          placeholder="Email Address*"
          onChange={(e) => {
            setInfo({
              ...info,
              email: e.target.value,
            })
          }}
          type="email"
          value={info.email}
          size="large"
          prefix={
            <MailOutlined
              style={{ color: "#7B43F5", margin: "0 0.75rem 0 0.5rem" }}
            />
          }
          style={{ height: "3rem", margin: "1.5rem 0 1rem 0" }}
        />

        <Input.Password
          placeholder="Enter Password*"
          prefix={
            <UnlockOutlined
              style={{ color: "#7B43F5", margin: "-0.25rem 0.75rem 0 0.5rem" }}
            />
          }
          onChange={(e) => {
            setInfo({
              ...info,
              password: e.target.value,
            })
          }}
          size="large"

          value={info.password}
          iconRender={(visible) =>
            visible ? (
              <EyeTwoTone
                style={{ color: "#7B43F5", margin: "0 0.75rem 0 0.5rem" }}
              />
            ) : (
              <EyeInvisibleOutlined
                style={{ color: "#7B43F5", margin: "0 0.75rem 0 0.5rem" }}
              />
            )
          }
          style={{ height: "3rem" }}
        />

        {info.password && !validatePassword(info.password) && (
          <Text
            m="0"
            Text="Please Enter 8 letter password containing 1 symbol 1 upper case 1 lowercase and 1 numeric character"
            color="red"
            fw="400"
            fs="15px"
            xmsize="13px"
            msize="11px"
            lh="19.5px"
            ls="-0.11px"
          />
        )}

        <Input.Password
          placeholder="Confirm Password*"
          size="large"

          prefix={
            <UnlockOutlined
              style={{ color: "#7B43F5", margin: "0 0.75rem 0 0.5rem" }}
            />
          }
          iconRender={(visible) =>
            visible ? (
              <EyeTwoTone
                style={{ color: "#7B43F5", margin: "0 0.75rem 0 0.5rem" }}
              />
            ) : (
              <EyeInvisibleOutlined
                style={{ color: "#7B43F5", margin: "0 0.75rem 0 0.5rem" }}
              />
            )
          }
          style={{ height: "3rem", marginTop: 10 }}
          onChange={(e) => {
            setInfo({
              ...info,
              confirmPassword: e.target.value,
            })
          }}
          value={info.confirmPassword}
          //iconRender={(visible) => (visible ? <EyeTwoTone style={{ color: '#7B43F5', margin: "0 0.75rem 0 0.5rem" }} /> : <EyeInvisibleOutlined style={{ color: '#7B43F5', margin: "0 0.75rem 0 0.5rem" }} />)}
          // style={{ height: "3rem",marginTop:10  }}
        />

        {info.password &&
          info.confirmPassword &&
          info.password != info.confirmPassword && (
            <Text
              m="0"
              Text="Password does not match"
              color="red"
              fw="400"
              fs="15px"
              xmsize="13px"
              msize="11px"
              lh="19.5px"
              ls="-0.11px"
            />
          )}

        <Text
          m="2rem 0 1rem 0"
          Text="Stand a chance to win $100 of complimentary gaming credits by sharing with us where you heard about HoloBet"
          color="#4542FF"
          fw="400"
          fs="15px"
          xmsize="13px"
          msize="11px"
          lh="19.5px"
          ls="-0.11px"
        />


        <Select
          defaultValue={null}
          onChange={(item) => {
            setInfo({
              ...info,
              socialPlatfrom: item,
            })
          }}
          placeholder="Where did you hear about us? (Optional)"
          allowClear
          size="large"
          suffixIcon={
            <DownOutlined
              style={{ color: "#7B43F5", margin: "0.25rem 1.1rem 0.5rem 0" }}
            />
          }
          style={{
            width: "100%",
            margin: "1rem 0 0 0",
            height: "3rem",
          }}
          options={[
            {
              value: "twitter",
              label: "Twitter",
            },
            {
              value: "discord",
              label: "Discord",
            },
            {
              value: "telegram",
              label: "Telegram",
            },
            {
              value: "bitcoinTalk",
              label: "BitcoinTalk",
            },
            {
              value: "reddit",
              label: "Reddit",
            },
            {
              value: "facebook",
              label: "Facebook",
            },
            {
              value: "instagram",
              label: "Instagram",
            },
          ]}
        />

        <Input
          placeholder="Social Handle (Optional)"
          onChange={(e) => {
            setInfo({
              ...info,
              socialHandle: e.target.value,
            })
          }}
          value={info.socialHandle}
          size="large"
          prefix={
            <svg
              style={{ color: "#7B43F5", margin: "0 0.75rem 0 0.5rem" }}
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.72327 0.315796C3.64211 0.315796 0.302979 3.65493 0.302979 7.73609C0.302979 11.8173 3.64211 15.1564 7.72327 15.1564C9.05893 15.1564 10.3204 14.7854 11.4334 14.1917C11.8044 13.9691 11.8786 13.5239 11.7302 13.1529C11.5076 12.7819 11.0624 12.7077 10.6914 12.8561C7.87168 14.4886 4.23574 13.5239 2.60327 10.7042C0.970805 7.8845 1.93544 4.24855 4.75516 2.61609C7.57487 0.983622 11.2108 1.94826 12.8433 4.76797C13.3627 5.65841 13.6595 6.69725 13.6595 7.73609V8.32971C13.6595 9.07174 13.0659 9.66537 12.3239 9.66537C11.5818 9.66537 10.9882 9.07174 10.9882 8.32971V5.13899C10.9882 4.69377 10.6914 4.39696 10.2462 4.39696C9.87516 4.39696 9.57835 4.61957 9.50414 4.99058C8.02009 3.95174 5.8682 4.32276 4.82936 5.80681C3.79052 7.29087 4.16153 9.44276 5.64559 10.4816C7.05545 11.4462 8.91052 11.2236 10.0236 9.96218C10.9882 11.1494 12.6949 11.372 13.8821 10.4816C14.5499 9.96218 14.9952 9.14595 14.9952 8.25551V7.73609C15.1436 3.65493 11.8044 0.315796 7.72327 0.315796ZM7.72327 9.59117C6.68443 9.59117 5.8682 8.77493 5.8682 7.73609C5.8682 6.69725 6.68443 5.88102 7.72327 5.88102C8.76212 5.88102 9.57835 6.69725 9.57835 7.73609C9.57835 8.77493 8.76212 9.59117 7.72327 9.59117Z"
                fill="#7232E1"
              />
            </svg>
          }
          style={{ height: "3rem", margin: "0.6rem 0 0 0" }}
        />

        <div style={{ margin: "1rem 0" }}>
          <Flex>
            <Checkbox
              value={isTermAndConditionAccepted}
              checked={isTermAndConditionAccepted}
              onChange={(e) => {
                setTermAndConditionAccepted(e.target.checked)
              }}
              style={{ border: "1px solid #7B43F5", border: "none" }}
            />

            <CustomText>
            I agree to the terms and conditions 
              <span
                onClick={showModal}
                style={{ color: "#7B43F5", cursor: "pointer" }}
              >
                {" "}
                terms and conditions
              </span>{" "}
              and I have NOT self-excluded from any gambling website in the past 12 months (Required).
             
              <ModalContent {...modalProps} />
            </CustomText>
          </Flex>
          <Flex m="0.5rem 0 0 0">
            <Checkbox
              value={isSendPromotionalEmailChecked}
              checked={isSendPromotionalEmailChecked}
              onChange={(e) => {
                setSendPromotionalEmailChecked(e.target.checked)
              }}
            />
            <CustomText>
              Send me promotional and marketing content (Optional).
            </CustomText>
          </Flex>
        </div>

        <CaptchaContainer>
          <ReCAPTCHA
            sitekey="6Le6ADklAAAAADqw61rcbPFFf4JHgETqxIwvYr61"
            onChange={(oii) => {
              console.log("saasasasasas", oii)
              setCaptchaPassed(true)
            }}
          />
        </CaptchaContainer>

        <Button
          Text="Submit"
          isLoading={isLoading}
          disabled={!isReadyToSubmit()}
          onClick={() => {
            handleSubmitClicked()
          }}
          m="1rem 0 0 0"
          width="100%"
          height="66px"
        />

        <div>
          <Toaster />
        </div>
      </StyledRightComponent>
    </Wrapper>
  )
}

export default RightComponent
